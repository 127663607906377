<script lang="ts">
import { Vue, Component, Model, Prop } from "vue-facing-decorator";
import ModalUtility from "../../utilities/ModalUtility.vue";
import Button from "primevue/button";
import RadioButton from "primevue/radiobutton";
import FileUploader from "@/components/utilities/FileUploader.vue";
import StudentInformation from "@/components/Student/StudentInformation.vue";
import AssignmentInformationCard from "./AssignmentInformationCard.vue";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import FormField from "@/components/Layout/Forms/FormField.vue";
import { useToast } from "primevue/usetoast";
import FormRadioButton from "@/components/Layout/Forms/FormRadioButton.vue";

@Component({
  components: {
    ModalUtility,
    Button,
    RadioButton,
    FileUploader,
    StudentInformation,
    AssignmentInformationCard,
    AppTypographyText,
    AppButton,
    FormField,
    FormRadioButton,
  },
})
export default class AssignmentSubmission extends Vue {
  toast = useToast();

  @Model({
    type: String,
    required: true,
  })
  selectedReflection!: string;
}
</script>
<template>
  <div class="px-2 flex gap-10">
    <div class="flex gap-2 items-center">
      <FormRadioButton
        v-model="selectedReflection"
        name="met"
        inputId="met"
        :value="true"
      />
      <label for="met">I have met this goal</label>
    </div>
    <div class="flex gap-2 items-center">
      <FormRadioButton
        v-model="selectedReflection"
        name="not-met"
        inputId="not-met"
        :value="false"
      />
      <label for="met">I have not met this goal</label>
    </div>
  </div>
</template>
