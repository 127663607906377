<script lang="ts">
import { Vue, Component, Model, Prop } from "vue-facing-decorator";
import FormInputDropdown from "../Layout/Forms/FormInputDropdown.vue";
import { AxiosResponse } from "axios";
import CommentService from "@/services/CommentService";
import { CommentBankList } from "@/store/comments/types";

@Component({ components: { FormInputDropdown } })
export default class SelectCommentABank extends Vue {
  @Prop({
    type: Number,
    default: 1,
  })
  selectionLimit!: number;

  @Prop({
    type: Number,
    default: 3,
  })
  maxSelectedLabels!: number;

  @Prop({
    type: String,
    default: "name",
  })
  optionLabel!: string;

  @Prop({
    type: String,
  })
  optionValue!: string;

  @Model({
    type: [String, Number, Object],
  })
  value!: string | number | CommentBankList;

  @Prop({
    type: Array,
    default: [],
  })
  commentBankList!: CommentBankList[];

  mounted() {
    //
  }
}
</script>
<template>
  <FormInputDropdown
    v-model="value"
    :options="commentBankList"
    :filter="true"
    :optionLabel="optionLabel"
    :option-value="optionValue"
    placeholder="Select a comment bank"
    class="w-full"
  />
</template>

<style scoped></style>
