import { ActionContext } from "vuex";
import { AuthProfileState, AuthState } from "../AuthStateTypes";
import * as mutationType from "../mutation-types";

export const SetAccessOnBoardingAction = (
  context: ActionContext<AuthState, unknown>,
  isAccess: boolean
) => {
  context.commit(mutationType.SET_ACCESS_ON_BOARDING, isAccess);
};
