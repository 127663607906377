<script lang="ts">
import { Vue, Model, Prop, Emit, Component } from "vue-facing-decorator";
import { TaskInstructions, Assignment } from "./assignmentType";
import AssignmentTaskInstructionForm from "./AssignmentTaskInstructionForm/AssignmentTaskInstructionForm.vue";
import AssignmentInformation from "./AssignmentInformation.vue";
import FormField from "@/components/Layout/Forms/FormField.vue";

@Component({
  components: {
    AssignmentTaskInstructionForm,
    AssignmentInformation,
    FormField,
  },
})
export default class AssignmentTaskInstructions extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  assignment!: Assignment;

  @Prop({
    type: Boolean,
    default: false,
  })
  isEdit!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  createToEdit!: boolean;

  @Emit("onClickSkipOrGoBack")
  hanldeClickGoBackOrSkip(index = 0) {
    return index;
  }

  @Emit("onClickSaveInstructions")
  hanldeClickSaveTaskInstruction() {
    return;
  }

  @Model({
    type: Object,
    required: true,
    name: "taskInstructions",
  })
  taskInstructionsModel!: TaskInstructions;
}
</script>
<template>
  <div
    class="w-full flex flex-col justify-start items-start px-2 bg-white pb-4"
  >
    <FormField class="mb-6" label="Assignment Information" :required="false">
      <AssignmentInformation class="mt-5" :assignment="assignment" />
    </FormField>
    <AssignmentTaskInstructionForm
      v-model:typeAssignment="taskInstructionsModel.typeAssignmentInstructions"
      v-model:selectedFile="taskInstructionsModel.files"
      :assignment="assignment"
      @onClickSkipOrGoBack="hanldeClickGoBackOrSkip"
      @onClickSaveTaskInstruction="hanldeClickSaveTaskInstruction"
      :isEdit="isEdit"
      :createToEdit="createToEdit"
    />
  </div>
</template>
