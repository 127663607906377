<script lang="ts">
import Toast from "primevue/toast";
import { Vue, Component, Model, Prop, Ref, Emit } from "vue-facing-decorator";
import { useToast } from "primevue/usetoast";
import ProgressBar from "primevue/progressbar";
import CloudStorageService from "@/services/CloudStorageService";
import { AxiosResponse } from "axios";
import { environment } from "@/environments/environment";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons } from "@/utils/icons";
import { dataURLtoFile } from "@/utils/helper";

@Component({ components: { Toast, ProgressBar, AppButton } })
export default class FileUploader extends Vue {
  private cloudStorageService = new CloudStorageService();
  icon = icons;

  @Prop({
    type: Boolean,
    default: false,
  })
  multiple!: boolean;

  @Prop({
    type: String,
    default: ".pdf,.docx,.doc,.jpg,.jpeg,.png",
  })
  acceptedFiles!: string;

  @Prop({
    type: String,
    default:
      "image/png,image/jpeg,image/jpg,application/pdf,application/vnd.google-apps.document",
  })
  gDriveAcceptedFiles!: string;

  @Prop({
    type: String,
    default: "Drag and Drop files here",
  })
  placeholder!: string;

  @Prop({
    type: String,
    default: "Support file types: PDF, JPG, Microsoft Word & Google Doc",
  })
  supportedFileTypes!: string;

  @Prop({
    type: Boolean,
    default: true,
  })
  showFileList!: boolean;

  @Prop({
    type: String,
    default: "Release to drop files here.",
  })
  draggingPlaceholder!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: Number,
    required: false,
    default: 0,
  })
  progress!: number;

  @Prop({
    type: Boolean,
    default: false,
  })
  startUploading!: boolean;

  @Model({
    type: Array,
    required: true,
    name: "selectedFiles",
  })
  selectedFilesModel!: File[];

  @Prop({
    type: Number,
    required: false,
    default: 0,
  })
  fileSizeLimit!: number; //mb

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  hideGoogleDrive!: boolean;

  @Ref
  fileInput!: HTMLInputElement;

  @Emit("onRemoveFile")
  handleOnRemoveFile(index: number) {
    return index;
  }

  toast = useToast();
  isDragging = false;
  refreshKey = 0;
  intervalId!: any;
  googleAuthenticated = false;
  fileUploading = false;
  gDriveTokenExpiration = 0;

  handleGetAuth() {
    return localStorage.getItem("cloudStorageData");
  }

  handleGetFileInfo() {
    return sessionStorage.getItem("cloudFileData");
  }

  getCurrentTimeStamp() {
    return new Date().getTime();
  }

  handleAuthenticatedCheck() {
    let isAuthenticated: boolean;
    const cloudStorageData = this.handleGetAuth();
    const currentTimeStamp = this.getCurrentTimeStamp();
    if (cloudStorageData) {
      const parsedData = JSON.parse(cloudStorageData);
      const expiration = parseInt(parsedData.tokenExpiresOn);
      if (currentTimeStamp >= expiration) {
        localStorage.removeItem("cloudFileData");
        isAuthenticated = false;
      } else {
        isAuthenticated = true;
      }
    } else {
      isAuthenticated = false;
    }
    if (isAuthenticated) {
      const cloudFileInfo = this.handleGetFileInfo();
      sessionStorage.removeItem("cloudFileData");
      if (cloudFileInfo) {
        this.handleGetDataFromGDrive(cloudFileInfo);
        // clearInterval(this.intervalId);
      }
    }
    return isAuthenticated;
  }

  startInterval() {
    this.intervalId = setInterval(() => {
      // let isAuthenticated: boolean;
      // const cloudStorageData = this.handleGetAuth();
      // const currentTimeStamp = this.getCurrentTimeStamp();
      // if (cloudStorageData) {
      //   if (currentTimeStamp >= this.gDriveTokenExpiration) {
      //     localStorage.removeItem("cloudFileData");
      //     isAuthenticated = false;
      //   } else {
      //     isAuthenticated = true;
      //   }
      // } else {
      //   isAuthenticated = false;
      // }

      // if (isAuthenticated) {
      //   const cloudFileInfo = this.handleGetFileInfo();
      //   sessionStorage.removeItem("cloudFileData");
      //   if (cloudFileInfo) {
      //     this.handleGetDataFromGDrive(cloudFileInfo);
      //     clearInterval(this.intervalId);
      //   }
      // }
      this.googleAuthenticated = this.handleAuthenticatedCheck();
    }, 1000);
  }

  stopInterval() {
    clearInterval(this.intervalId);
  }

  onChange(): void {
    if (this.disabled) {
      return;
    }

    if (this.fileInput && this.fileInput.files) {
      const files: File[] = [];
      const limit = this.fileSizeLimit * 1000000;
      Array.from(this.fileInput.files).forEach((file) => {
        if (this.fileSizeLimit && file.size > limit) {
          this.toast.add({
            severity: "error",
            summary: "File max size limit exceeded",
            detail: `${file.name} exceeded the file size limit`,
            life: 6000,
          });
        } else {
          files.push(file);
        }
      });

      this.selectedFilesModel = Array.from(files);
    }
  }

  handleClickBrowse() {
    this.fileInput.value = "";
    this.fileInput.click();
  }

  mounted() {
    const cloudStorageData = this.handleGetAuth();
    if (cloudStorageData) {
      const cloudStorageLocal = JSON.parse(cloudStorageData);
      this.gDriveTokenExpiration = parseInt(cloudStorageLocal.tokenExpiresOn);
    }
    this.googleAuthenticated = this.handleAuthenticatedCheck();
    this.startInterval();
  }

  unmounted() {
    this.stopInterval();
  }

  async cloudStorageAuthenticate() {
    try {
      const appUrl = window.location.origin;
      const redirectUrl = `${appUrl}/cloud-storage/authentication`;
      const response: AxiosResponse =
        await this.cloudStorageService.getIdentificationToken();
      if (response.data.ok) {
        const responseData = response.data.data;
        const authUrl = `${environment.apiEndpoint}/cloud-storage/authenticate/google?token=${responseData.token}&successRedirectUrl=${redirectUrl}&failedRedirectUrl=${redirectUrl}`;
        Object.assign(document.createElement("a"), {
          target: "_blank",
          rel: "noopener noreferrer",
          href: authUrl,
        }).click();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async handleGetDataFromGDrive(document: string) {
    this.fileUploading = true;
    try {
      const fileData = JSON.parse(document);
      const { id: fileId, mimeType } = fileData;
      const provider = "google";

      const params = { fileId, mimeType, provider };
      const res: AxiosResponse =
        await this.cloudStorageService.getGoogleDriveFile(params);

      const files: File[] = [];
      if (res.data.ok) {
        const file = dataURLtoFile(
          `data:${res.data.data.generatedMimeType};base64,${res.data.data.file}`,
          `${fileData.name.split(".")[0]}.pdf`
        );

        files.push(file);
        this.selectedFilesModel = Array.from(files);
      }
    } catch (err) {
      console.error(err);
    }
    this.fileUploading = false;
  }

  async handleClickBrowseCloudStorage() {
    try {
      this.cloudStorageAuthenticate();
    } catch (error) {
      console.error(error);
    }
  }

  dragover(e: DragEvent): void {
    e.preventDefault();
    this.isDragging = true;
  }

  dragleave() {
    this.isDragging = false;
  }

  drop(e: DragEvent): void {
    e.preventDefault();
    if (this.disabled) {
      return;
    }
    if (this.fileInput && e.dataTransfer?.files) {
      if (e.dataTransfer.files.length > 1 && !this.multiple) {
        this.toast.add({
          severity: "error",
          summary: "File upload",
          detail: "Maximum number of upload is 1",
          life: 6000,
        });
      } else {
        this.selectedFilesModel = Array.from(e.dataTransfer.files);
      }
    }
    this.isDragging = false;
  }

  handleClickRemoveFile(index: number) {
    this.handleOnRemoveFile(index);
    this.selectedFilesModel.splice(index, 1);
    this.fileInput.value = "";
  }
}
</script>
<template>
  <div
    class="w-full rounded-[8px] overflow-hidden border-solid border-2 border-flohh-neutral-85 flex flex-col justify-center items-center p-2 pb-5 relative gap-3"
  >
    <div
      class="flex flex-col rounded-[8px] w-full justify-center items-center h-full bg-flohh-neutral-95 border-dashed border-2 border-flohh-neutral-85 py-3 px-5"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <img src="@/assets/upload_file_icon.svg" />
      <div v-if="isDragging">
        <p
          class="text-flohh-text-body font-flohh-font-medium text-flohh-neutral-20 mt-2 text-center"
        >
          {{ draggingPlaceholder }}
        </p>
      </div>
      <div v-else>
        <p
          class="text-flohh-text-body font-flohh-font-medium text-flohh-neutral-20 mt-2 text-center"
        >
          <strong>{{ placeholder }}</strong>
        </p>
        <p
          class="text-flohh-text-body font-flohh-font-medium text-flohh-neutral-20 mt-2"
        >
          {{ supportedFileTypes }}
        </p>
        <div
          class="w-full flex flex-col px-4 py-2 justify-center items-center gap-x-4 border-solid border-t-2 mt-3 border-slate-200"
          v-if="showFileList && selectedFilesModel && selectedFilesModel.length"
        >
          <div
            v-for="(file, index) in selectedFilesModel"
            :key="index"
            class="flex w-4/5 flex-row gap-x-4 justify-start items-start hover:bg-flohh-neutral-85 p-1 rounded-[8px]"
          >
            <div
              class="text-xs text-gray-600 truncate w-full flex justify-start items-start"
            >
              {{ file.name }}
            </div>
            <div class="flex justify-center items-center">
              <i
                class="pi pi-times-circle cursor-pointer"
                style="color: rgb(153 27 27)"
                @click="handleClickRemoveFile(index)"
              />
            </div>
          </div>
        </div>
        <div
          class="w-full flex justify-center items-center"
          v-if="startUploading"
        >
          <div
            class="w-full bg-flohh-neutral-85 rounded-full dark:bg-flohh-neutral-85"
          >
            <div
              class="bg-flohh-secondary-blue text-xs font-medium text-white text-center p-0.5 leading-none rounded-full"
              :style="{ width: `${progress}%` }"
            >
              {{ progress }}%
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex gap-3 justify-center items-center">
      <input
        type="file"
        name="file"
        id="fileInput"
        class="hidden"
        @change="onChange"
        ref="fileInput"
        :multiple="multiple"
        :key="refreshKey"
        :disabled="disabled"
        :accept="acceptedFiles"
      />
      <AppButton
        class="font-flohh-font-bold text-flohh-text-body text-flohh-neutral-20 mt-2 cursor-pointer"
        :class="disabled ? 'cursor-not-allowed' : ''"
        @click="handleClickBrowse"
        type="submit"
      >
        <template #icon_left>
          <span v-html="icon.uploadBlack"></span>
        </template>
        Browse computer
      </AppButton>
      <p v-if="!hideGoogleDrive">Or</p>
      <div v-if="!hideGoogleDrive">
        <AppButton
          v-if="!googleAuthenticated"
          class="font-flohh-font-bold text-flohh-text-body text-flohh-neutral-20 mt-2 cursor-pointer"
          :class="disabled ? 'cursor-not-allowed' : ''"
          @click="handleClickBrowseCloudStorage"
          type="submit"
        >
          <template #icon_left>
            <span v-html="icon.googleDrive"></span>
          </template>
          Browse Google Drive
        </AppButton>
        <AppButton
          v-else
          class="font-flohh-font-bold text-flohh-text-body text-flohh-neutral-20 mt-2 cursor-pointer"
          :class="`${disabled ? 'cursor-not-allowed' : ''} ${
            fileUploading ? 'pointer-events-none' : ' '
          }`"
          onclick="createPicker(event)"
          type="submit"
          :data-multiple="multiple"
          :data-filetype="gDriveAcceptedFiles"
        >
          <template #icon_left>
            <span v-html="icon.googleDrive"></span>
          </template>
          {{ fileUploading ? "Uploading..." : "Browse Google Drive" }}
        </AppButton>
      </div>
    </div>
    <div
      class="absolute bg-gray-500 bg-opacity-40 w-full h-full animate-pulse top-0"
      v-if="disabled"
    ></div>
  </div>
</template>

<style lang="scss">
.picker-dialog {
  &-bg {
    z-index: 100000 !important;
  }
  z-index: 100001 !important;
}
</style>
