<template>
  <div class="changelog-container">
    <div class="head">
      <h2>
        <strong>{{ title }} ({{ date }})</strong>
      </h2>
    </div>
    <div class="body">
      <h4 v-if="added.length > 0">Added</h4>
      <ul class="ml-10">
        <li v-for="(item, index) in added" :key="index">{{ item }}</li>
      </ul>
      <h4 v-if="changed.length > 0" class="mt-5">Changed</h4>
      <ul class="ml-10">
        <li v-for="(item, index) in changed" :key="index">{{ item }}</li>
      </ul>
      <h4 v-if="fixed.length > 0" class="mt-5">Fixed</h4>
      <ul class="ml-10">
        <li v-for="(item, index) in fixed" :key="index">{{ item }}</li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-facing-decorator";
@Component({
  components: {},
})
export default class WelcomeComponent extends Vue {
  currentDate = new Date().toLocaleDateString("en-AU");

  @Prop({
    type: String,
    required: true,
  })
  title!: string;

  @Prop({
    type: String,
    required: true,
  })
  date!: string;

  @Prop({
    type: Array,
    required: false,
  })
  added!: string[];

  @Prop({
    type: Array,
    required: false,
  })
  fixed!: string[];

  @Prop({
    type: Array,
    required: false,
  })
  changed!: string[];

  mounted() {
    //
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.changelog-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.head {
  padding-bottom: 1em;
  border-bottom: 1px solid #ccc;
}
li {
  list-style: disc;
}
</style>
