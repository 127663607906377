<template>
  <PageTitle title="Bulk Upload" :routes="routes" />
  <PageViewContainer :hasSidebar="true">
    <BulkUploadComponent
      v-if="type === 'paper' || type === 'digital'"
      :bulkUploadType="type"
    />
  </PageViewContainer>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import BulkUploadComponent from "@/components/BulkUploadNew/BulkUploadComponent.vue";
import PageTitle from "@/components/Layout/Partials/PageTitle.vue";
import { TRoute } from "@/components/Layout/Partials/PageTitle.vue";
import emitter from "@/config/emitter";
import PageViewContainer from "@/components/Layout/Partials/PageViewContainer.vue";

@Component({
  components: {
    BulkUploadComponent,
    PageTitle,
    PageViewContainer,
  },
})
export default class BulkUploadView extends Vue {
  eventBus = emitter;
  type = "";
  routes: TRoute[] = [];

  created() {
    this.type = this.$route.params.type as string;

    this.routes = [
      {
        title: "Classes",
        routeTo: "/classes/all",
        current: false,
      },
      {
        title: `${
          this.type === "digital"
            ? "Bulk Upload - Digital"
            : "Bulk Upload - Paper"
        }`,
        routeName: "BulkUpload",
        current: true,
      },
    ];

    this.eventBus.on("UPDATE_BULK_UPLOAD_BREADCRUMBS", (data: any) => {
      if (this.routes.length === 3) {
        this.routes.splice(1, 1);
      }

      if (data.classUuid !== "create" && this.routes.length === 2) {
        const additionalRoute = {
          title: data.className,
          routeTo: `/classes/${data.classUuid}`,
          current: false,
        };
        this.routes.splice(1, 0, additionalRoute);
      }
    });
  }
}
</script>

<style scoped lang="scss">
body {
  margin: 0;
  overflow: hidden !important;
}
</style>
