import { ActionContext } from "vuex";
import { BulkAssignee, BulkUploadState } from "../bulkUploadStateTypes";
import { RootState } from "@/store";
import * as mutationType from "../mutation-types";

export const SetAssigneeAction = (
  context: ActionContext<BulkUploadState, RootState>,
  params: BulkAssignee
) => {
  context.commit(mutationType.SET_ASSIGNEE, params);
};
