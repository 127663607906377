<template>
  <div
    class="loader fixed w-full h-full top-0 left-0 flex items-center justify-center"
  >
    <div
      class="block w-[561px] min-h-[382px] bg-white rounded-[4px] shadow flex items-center justify-center flex-col"
    >
      <h4 class="font-bold color-gray-500 text-base mb-[50px]">
        Mark Student Assignments 40% Faster
      </h4>
      <div class="w-[268px] h-[70px] mb-3">
        <img
          src="@/assets/flohh-logo.svg"
          class="w-full h-full object-cover object-left"
        />
      </div>
      <div class="w-[268px] bg-gray-200 rounded-full h-[8px] mb-[36px]">
        <div
          class="bg-flohh-pink h-[8px] rounded-full"
          :style="{ width: `${progress}%` }"
        ></div>
      </div>
      <p class="text-sm color-gray-500">
        We’re creating your Flohh account. Hang Tight
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";

@Component
export default class LoaderComponent extends Vue {
  //
  @Prop({
    type: String,
    required: false,
  })
  route!: string;

  progress = 0;

  mounted() {
    this.startProgress();
  }

  startProgress() {
    const interval = setInterval(() => {
      if (this.progress < 100) {
        this.progress += 1;
      } else {
        clearInterval(interval);
        if (this.route) {
          if (this.route === "/register/onboarding") {
            localStorage.setItem("onboarding", "visible");
          }
          // this.$router.push(this.route);
          window.location.href = this.route;
        }
      }
    }, 1);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.loader {
  background-image: url("../../assets/pattern.png");
  background-repeat: repeat;
  background-color: #c7ebda;
  background-size: 100% auto;
}
</style>
