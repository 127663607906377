<template>
  <div
    class="login-container h-screen flex py-20 items-center justify-center w-full px-5 mx-auto max-w-[max-content]"
  >
    <div
      class="rounded-md flex flex-col justify-center items-center pb-14 px-6 md:px-14 pt-5 bg-white w-full"
    >
      <div
        class="flex flex-col w-full items-center justify-center p-4 relative z-[1]"
      >
        <img
          src="@/assets/flohh-logo.svg"
          class="object-contain object-[14px] max-w-[210px] w-full mx-auto my-0"
        />
      </div>
      <Divider />
      <div v-if="isSuccess" class="pt-2 text-center flex flex-col">
        <p class="text-flohh-text-title color-flohh-neutral-20 font-bold mb-1">
          You've successfully authenticated your Google Drive account.
        </p>
        <label
          class="ml-2 text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20"
        >
          Redirecting you to the dashboard...
        </label>
      </div>
      <div v-else class="pt-2 text-center flex flex-col items-center gap-5">
        <p class="text-flohh-text-title color-flohh-neutral-20 font-bold mb-1">
          Failed to authenicate your Google Drive account.
        </p>
        <label
          class="ml-2 text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20"
        >
          <AppButton @click="handleRedirect()">
            <span v-html="icon.arrowBackBlack" class="mr-2"></span>
            Go Back To The Dashboard
          </AppButton>
        </label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import axios, { AxiosResponse } from "axios";
import Divider from "primevue/divider";
import { icons as AppIcons } from "@/utils/icons";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { setAuthDispatch } from "@/store/auth/auth-dispatch";

@Component({
  components: {
    AppButton,
    Divider,
  },
})
export default class CloudStorageAuthView extends Vue {
  authenticatedAs!: string;
  isSuccess = true;
  icon = AppIcons;

  handleRedirect() {
    this.$router.push({
      name:
        this.authenticatedAs === "teacher" ? "Dashboard" : "StudentDashboard",
    });
  }

  mounted() {
    const localAuthenticatedAs = localStorage.getItem("authenticatedAs");
    this.authenticatedAs = localAuthenticatedAs ? localAuthenticatedAs : "";
    const urlParams = new URLSearchParams(window.location.search);
    const responseData = urlParams.get("data");
    const parsedData = responseData ? JSON.parse(responseData) : null;

    if (parsedData) {
      const currentTimeStamp = new Date().getTime();
      const futureTimeStamp = currentTimeStamp + 3570 * 1000; // 55mins and 30secs
      const data = {
        developerKey: parsedData.developerKey,
        oAuthToken: parsedData.oAuthToken,
        tokenExpiresOn: futureTimeStamp,
      };
      this.isSuccess = true;
      localStorage.setItem("cloudStorageData", JSON.stringify(data));
      window.close();
    } else {
      localStorage.setItem("cloudStorageData", "");
      this.isSuccess = false;
    }
  }
}
</script>

<style scoped lang="scss"></style>
