<template>
  <div class="flex flex-col text-left gap-[24px]">
    <p
      class="font-bold text-[18px]"
      :style="{
        color: 'rgba(51, 51, 51, 1)',
      }"
    >
      Upload your class list
    </p>
    <div class="px-[24px]">
      <p class="text-[16px] mb-[10px]">
        Your class list should have the following columns: First name, Last
        name, student ID, and email. You can download a template
        <a
          class="underline font-bold"
          href="/template/student-template.xlsx"
          download="Flohh Class List"
        >
          here
        </a>
      </p>

      <DragAndDrop />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";

import DragAndDrop from "./DragAndDrop.vue";

import { TStudent } from "../types";

@Component({ components: { DragAndDrop } })
export default class UploadAddComponent extends Vue {
  @Prop({
    type: Function,
  })
  handleStudents!: (studentsList: TStudent[]) => void;

  @Prop({
    type: Array,
  })
  students!: TStudent[];
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
