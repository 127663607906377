import axios from "axios";
import { TELEMETRY_HEAD } from "@/utils/telemetry/data";

const TEACHER_BASE_URL = "/teachers";
const SUPER_ADMIN_BASE_URL = "/super-admin/teachers";
const GET_TEACHER_DETAILS = `${TEACHER_BASE_URL}/me`;

export default class TeacherService {
  getTeacher() {
    return axios.get(GET_TEACHER_DETAILS);
  }
  getTeachers() {
    return axios.get(SUPER_ADMIN_BASE_URL);
  }
  putTeacher(payload: any) {
    return axios.put(`${TEACHER_BASE_URL}/${payload.teacherUuid}`, payload, {
      headers: TELEMETRY_HEAD.putTeacher,
    });
  }

  approveTeacher(uuid: string) {
    return axios.put(`/super-admin/users/${uuid}/activate`, {
      headers: TELEMETRY_HEAD.approveTeacher,
    });
  }

  resendActivationAll() {
    return axios.post(`/super-admin/teachers/resend-activation/all`, {
      headers: TELEMETRY_HEAD.resendAllTeacherActivation,
    });
  }

  resendActivation(uuid: string) {
    return axios.post(`/super-admin/teachers/resend-activation/${uuid}`, {
      headers: TELEMETRY_HEAD.resendTeacherActivation,
    });
  }
}
