import {
  AssigmentSubmissionSummaryData,
  AssignmentState,
} from "../assignmentTypes";

export const SetAssignmentSubmissionSummary = (
  state: AssignmentState,
  assignment: AssigmentSubmissionSummaryData
) => {
  state.assignmentSubmissionSummary = assignment;
};
