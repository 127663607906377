<template>
  <LoginComponent :isSuperAdmin="true" />
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import LoginComponent from "@/components/Authentication/Login/LoginComponent.vue";

@Component({
  components: {
    LoginComponent,
  },
})
export default class SuperAdminLoginView extends Vue {
  title = "Super Admin Sign In";
}
</script>

<style scoped lang="scss"></style>
