import { ActionContext } from "vuex";
import { AuthState, Teacher } from "../AuthStateTypes";
import * as mutationType from "../mutation-types";

export const SetTeacherAction = (
  context: ActionContext<AuthState, unknown>,
  params: Teacher
) => {
  context.commit(mutationType.SET_TEACHER, params);
};
