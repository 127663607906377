import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioButton = _resolveComponent("RadioButton")!

  return (_openBlock(), _createBlock(_component_RadioButton, _mergeProps(_ctx.$attrs, {
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
    inputId: _ctx.inputId,
    name: _ctx.name,
    value: _ctx.value
  }), null, 16, ["modelValue", "inputId", "name", "value"]))
}