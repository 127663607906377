import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25bf2049"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "cursor-pointer h-[180px] w-[185px] rounded-[4px] flex justify-center items-center relative p-[20px] text-center font-[700] text-[14px]",
    style: _normalizeStyle({
      backgroundColor: _ctx.active
        ? 'rgba(251, 209, 186, 1)'
        : 'rgba(254, 243, 226, 1)',
    }),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleBlockClick && _ctx.handleBlockClick(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _createElementVNode("img", {
      src: _ctx.getImagePath(),
      class: "absolute",
      style: _normalizeStyle(
        _ctx.imagePosition === 'top'
          ? { top: '0', left: '0' }
          : { bottom: '0', right: '0' }
      )
    }, null, 12, _hoisted_1)
  ], 4))
}