import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex w-full" }
const _hoisted_2 = { class: "bg-white rounded-l-lg my-3 pl-[40px] pr-[33px] py-[47px] relative w-[75%]" }
const _hoisted_3 = { class: "sticky bg-white w-[25%] h-auto min-h-[calc(100vh_-_80px)] top-[80px] border border-solid border-flohh-neutral-85" }
const _hoisted_4 = { class: "px-[28px] py-[30px]" }
const _hoisted_5 = { class: "flex gap-1 mb-1" }
const _hoisted_6 = { class: "flex gap-1 mb-1" }
const _hoisted_7 = { class: "flex gap-1 mb-1" }
const _hoisted_8 = { class: "flex gap-1 mb-5" }
const _hoisted_9 = { class: "flex pt-5 border-t border-solid border-flohh-neutral-85" }
const _hoisted_10 = { class: "flex-1 flex justify-start items-center" }
const _hoisted_11 = { class: "flex-1 flex justify-end items-center gap-x-4" }
const _hoisted_12 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainPanelComponent = _resolveComponent("MainPanelComponent")!
  const _component_SidePanelComponent = _resolveComponent("SidePanelComponent")!
  const _component_AppTypographyText = _resolveComponent("AppTypographyText")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_ModalUtility = _resolveComponent("ModalUtility")!
  const _component_UnsavedWarningModal = _resolveComponent("UnsavedWarningModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_MainPanelComponent, {
          isEdit: _ctx.isEdit,
          progress: _ctx.uploadProgress,
          error: _ctx.error,
          isSaving: _ctx.savingAssignment,
          onOnTabChange: _ctx.handleTabChange,
          onOnDetailsUpdate: _ctx.handleTitleAndInstruction,
          onOnClickSave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleClickSaveAssignment(true))),
          onOnClickAssign: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleToggleAssignModal(true))),
          onOnClickUnassign: _cache[2] || (_cache[2] = ($event: any) => (_ctx.saveAssignment(false)))
        }, null, 8, ["isEdit", "progress", "error", "isSaving", "onOnTabChange", "onOnDetailsUpdate"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SidePanelComponent, {
          isEdit: _ctx.isEdit,
          onOnDetailsUpdate: _ctx.handleMoreDetails,
          selectedCriteriaSheet: _ctx.selectedCriteriaSheet,
          selectedCommentBank: _ctx.selectedCommentBank,
          handleSelectedCriteriaSheet: _ctx.handleSelectedCriteriaSheet,
          handleSelectedCommentBank: _ctx.handleSelectedCommentBank,
          error: _ctx.error,
          assignmentUuid: _ctx.assignmentUuid,
          handleDetachCommentBank: _ctx.handleSelectedCommentBank,
          handleDetachCriteriaSheet: _ctx.handleSelectedCriteriaSheet
        }, null, 8, ["isEdit", "onOnDetailsUpdate", "selectedCriteriaSheet", "selectedCommentBank", "handleSelectedCriteriaSheet", "handleSelectedCommentBank", "error", "assignmentUuid", "handleDetachCommentBank", "handleDetachCriteriaSheet"])
      ])
    ]),
    _createVNode(_component_ModalUtility, {
      modelValue: _ctx.isAssignModalOpen,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isAssignModalOpen) = $event)),
      width: "35vw",
      title: "Assign to class?",
      onOnClose: _cache[6] || (_cache[6] = ($event: any) => (
      _ctx.savingAssignment
        ? _ctx.handleToggleAssignModal(true)
        : _ctx.handleToggleAssignModal(false)
    ))
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_AppTypographyText, {
              variant: "bd",
              type: "body",
              label: "Class name:"
            }),
            _createVNode(_component_AppTypographyText, {
              variant: "rg",
              type: "body",
              label: 
              _ctx.selectedClassInfo
                ? _ctx.selectedClassInfo.code
                : _ctx.sidePanelData.className
            
            }, null, 8, ["label"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_AppTypographyText, {
              variant: "bd",
              type: "body",
              label: "Subject:"
            }),
            _createVNode(_component_AppTypographyText, {
              variant: "rg",
              type: "body",
              label: _ctx.selectedClassInfo ? _ctx.selectedClassInfo.subject : '-'
            }, null, 8, ["label"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_AppTypographyText, {
              variant: "bd",
              type: "body",
              label: "Number of Students:"
            }),
            _createVNode(_component_AppTypographyText, {
              variant: "rg",
              type: "body",
              label: 
              _ctx.selectedClassInfo && _ctx.selectedClassInfo.students
                ? _ctx.selectedClassInfo.students.length
                : 0
            
            }, null, 8, ["label"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_AppTypographyText, {
              variant: "bd",
              type: "body",
              label: "Year Level:"
            }),
            _createVNode(_component_AppTypographyText, {
              variant: "rg",
              type: "body",
              label: _ctx.selectedClassInfo ? _ctx.selectedClassInfo.yearLevel : '-'
            }, null, 8, ["label"])
          ]),
          _createVNode(_component_AppTypographyText, {
            variant: "rg",
            type: "body",
            label: "Students will see the assignment in their dashboard immediately."
          })
        ])
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_AppButton, {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleToggleAssignModal(false))),
              text: "",
              blackLabel: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Keep Editing ")
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_AppButton, {
              type: "submit",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleClickAssignFromModal())),
              disabled: _ctx.savingAssignment,
              loading: _ctx.savingAssignment
            }, {
              icon_left: _withCtx(() => [
                _createElementVNode("span", {
                  innerHTML: _ctx.icons.checkBlack
                }, null, 8, _hoisted_12)
              ]),
              default: _withCtx(() => [
                _createTextVNode(" " + _toDisplayString(_ctx.isEdit ? "Save" : "Assign"), 1)
              ]),
              _: 1
            }, 8, ["disabled", "loading"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    (_ctx.$store.state.assignment.warningModal.isOpen)
      ? (_openBlock(), _createBlock(_component_UnsavedWarningModal, {
          key: 0,
          redirectTo: _ctx.$store.state.assignment.warningModal.redirectTo,
          modalType: _ctx.$store.state.assignment.warningModal.modalType,
          onOnCloseSaveModal: _ctx.handleWarningModalState,
          onOnLeave: _ctx.handleLeave,
          onOnStay: _ctx.handleStay,
          onOnSaveAndClose: _ctx.handleSaveAndClose
        }, null, 8, ["redirectTo", "modalType", "onOnCloseSaveModal", "onOnLeave", "onOnStay", "onOnSaveAndClose"]))
      : _createCommentVNode("", true)
  ], 64))
}