// SetUser.ts
// Action that will clear the user

import { ActionContext } from "vuex";
import { UserState, UserInfo } from "../types";

import * as mutations from "../mutation-types";

interface ActionParams {
  user: UserInfo;
}

export async function ClearUser(
  context: ActionContext<UserState, unknown>,
  params: ActionParams
) {
  console.debug(`[action] ClearUser :`, params);
  context.commit(mutations.CLEAR_USER);
}
