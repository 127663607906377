<template>
  <div class="assignment">
    <div class="assignment-wrap">
      <ButtonComponent
        v-bind="$attrs"
        :title="'Click Here'"
        @click="handleCriteriaBank"
        style="width: 120px"
      />
    </div>
    <CriteriaBankComponent v-if="showCriteriaBank" :close="closeCriteriaBank" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import ButtonComponent from "@/components/Button/ButtonComponent.vue";
import CriteriaBankComponent from "@/components/CriteriaBank/CriteriaBankComponent.vue";
@Component({
  components: {
    ButtonComponent,
    CriteriaBankComponent,
  },
})
export default class AssignmentView extends Vue {
  //

  showCriteriaBank = false;

  handleCriteriaBank() {
    //
    this.showCriteriaBank = true;
  }

  closeCriteriaBank() {
    this.showCriteriaBank = false;
  }
}
</script>

<style>
body {
  margin: 0;
}
.assignment-wrap {
  min-height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
