import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    value: _ctx.students,
    removableSort: "",
    tableStyle: "min-width: 50rem"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "code",
        header: "Code",
        sortable: "",
        style: {"width":"25%"}
      }),
      _createVNode(_component_Column, {
        field: "name",
        header: "Name",
        sortable: "",
        style: {"width":"25%"}
      }),
      _createVNode(_component_Column, {
        field: "category",
        header: "Category",
        sortable: "",
        style: {"width":"25%"}
      }),
      _createVNode(_component_Column, {
        field: "quantity",
        header: "Quantity",
        sortable: "",
        style: {"width":"25%"}
      })
    ]),
    _: 1
  }, 8, ["value"]))
}