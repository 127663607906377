import { MutationTree } from "vuex";
import { CommentState, Comments } from "../types";

const mutations: MutationTree<CommentState> = {
  SET_COMMENTS(state, comments: Comments[]) {
    state.comments = comments;
  },
  SET_SELECTED_COMMENT(state, comment: Comments) {
    state.selectedComment = comment;
  },
  SET_COPIED_COMMENT(state, comment: Comments) {
    state.copiedComment = comment;
  },
  ADD_COMMENTS(state, comment: Comments) {
    state.comments.push(comment);
    localStorage.setItem("CommentsList", JSON.stringify(state.comments));
  },
  SET_ASSIGNMENT_UUID(state, assignmentUuid: string) {
    state.assignmentUuid = assignmentUuid;
  },
};

export default mutations;
