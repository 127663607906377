<script lang="ts">
import FormField from "@/components/Layout/Forms/FormField.vue";
import { Vue, Model, Component } from "vue-facing-decorator";

@Component({ components: { FormField } })
export default class AssignmentAdvanceSettingVisibility extends Vue {
  @Model({
    type: String,
    required: true,
  })
  value!: string;

  options = [
    {
      value: "notify_my_students_by_email",
      label: "Notify my students by email (default)",
    },
    {
      value: "do_not_notify_my_students",
      label: "Do not notify my students",
    },
  ];
}
</script>
<template>
  <FormField
    v-model="value"
    :options="options"
    option-value="value"
    option-label="label"
    label="Notify my students when assignment becomes visible:"
    type="select"
  />
</template>
