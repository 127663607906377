import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_AssignmentFormComponent = _resolveComponent("AssignmentFormComponent")!
  const _component_PageViewContainer = _resolveComponent("PageViewContainer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      title: "Create Assignment",
      routes: _ctx.routes
    }, null, 8, ["routes"]),
    _createVNode(_component_PageViewContainer, { hasSidebar: true }, {
      default: _withCtx(() => [
        _createVNode(_component_AssignmentFormComponent, { isEdit: false })
      ]),
      _: 1
    })
  ], 64))
}