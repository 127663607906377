import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f198e612"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-end gap-2" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppButton, {
      type: "transparent",
      onClick: _cache[0] || (_cache[0] = 
        (e) => {
          e.preventDefault();
          _ctx.$router.back();
        }
      )
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", {
          innerHTML: _ctx.icons.arrowBackBlack,
          class: "mr-2"
        }, null, 8, _hoisted_2),
        _createTextVNode(" Go Back ")
      ]),
      _: 1
    }),
    _createVNode(_component_AppButton, {
      type: "submit",
      loading: _ctx.isLoading,
      disabled: !_ctx.isVerified
    }, {
      icon_left: _withCtx(() => [
        _createElementVNode("span", {
          innerHTML: _ctx.icons.checkBlack,
          class: "pr-2"
        }, null, 8, _hoisted_3)
      ]),
      default: _withCtx(() => [
        _createTextVNode(" " + _toDisplayString(_ctx.isLoading ? "Loading" : "Submit"), 1)
      ]),
      _: 1
    }, 8, ["loading", "disabled"])
  ]))
}