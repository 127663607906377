// data.ts

import { generateGuid } from "@/utils/helper";
import { TCommentTags, TCommentBank } from "./types";

export const COMMENT_TAGS: TCommentTags = [
  {
    color: { r: 163, g: 219, b: 190 },
    localization: {
      defaultMessage: "Went well",
    },
    title: "Went well",
  },
  {
    color: { r: 250, g: 178, b: 138 },
    localization: {
      defaultMessage: "Targets",
    },
    title: "Targets",
  },
  {
    color: { r: 255, g: 222, b: 127 },
    localization: {
      defaultMessage: "Needs improvement",
    },
    title: "Needs improvement",
  },
  {
    color: { r: 217, g: 217, b: 217 },
    localization: {
      defaultMessage: "Other",
    },
    title: "Other",
  },
];

export const COMMENT_BANK: TCommentBank = [
  {
    id: 0,
    comment: "You have shown a strong understanding of the text.",
    title: "Went well",
    tag: "Went well",
  },
  {
    id: 1,
    comment: "You have used literary devices well.",
    title: "Went well",
    tag: "Went well",
  },
  {
    id: 2,
    comment:
      "You have used textual evidence with clarity to support your ideas.",
    title: "Went well",
    tag: "Went well",
  },
  {
    id: 3,
    comment: "You have developed a strong thesis statement.",
    title: "Went well",
    tag: "Went well",
  },
  {
    id: 4,
    comment:
      "You need to make sure you are using evidence to support your ideas.",
    title: "Needs improvement",
    tag: "Needs improvement",
  },
  {
    id: 5,
    comment: "You need to develop clear topic sentences",
    title: "Needs improvement",
    tag: "Needs improvement",
  },
  {
    id: 6,
    comment: "You need to unpack evidence.",
    title: "Needs improvement",
    tag: "Needs improvement",
  },
  {
    id: 7,
    comment: "Your paragraphs often lacked direction.",
    title: "Needs improvement",
    tag: "Needs improvement",
  },
  {
    id: 8,
    comment: "It wasn’t clear what you were trying to say in your essay.",
    title: "Needs improvement",
    tag: "Needs improvement",
  },
  {
    id: 9,
    comment: "Your points did not contain enough supporting evidence.",
    title: "Needs improvement",
    tag: "Needs improvement",
  },
  {
    id: 10,
    comment:
      "You should aim to unpack all evidence used to show how it supports your point.",
    title: "Targets",
    tag: "Targets",
  },
  {
    id: 11,
    comment:
      "You should try to elaborate towards the bottom of your paragraph to really synthesize your argument.",
    title: "Targets",
    tag: "Targets",
  },
  {
    id: 12,
    comment:
      "You should work on creating strong topic sentences that support your thesis to bring clarity to your overall essay.",
    title: "Targets",
    tag: "Targets",
  },
];

export const ANNOTATION_BANK = [
  {
    id: generateGuid(),
    name: "",
    pdfObjectId: null,
    pageIndex: 0,
    boundingBox: {
      left: 379.8178192138672,
      top: 146.37584228515624,
      width: 24,
      height: 24,
    },
    opacity: 1,
    action: null,
    note: null,
    creatorName: null,
    createdAt: "2023-05-04T05:57:53.823Z",
    updatedAt: "2023-05-04T05:57:53.823Z",
    customData: {
      filterTag: "tag5",
      annotationId: "",
      libraryId: generateGuid(),
      annotationType: "NoteAnnotation",
    },
    noPrint: false,
    noZoom: false,
    noRotate: false,
    noView: false,
    hidden: false,
    additionalActions: null,
    text: {
      format: "plain",
      value: "This doesn’t make sense",
    },
    icon: "COMMENT",
    color: {
      r: 250,
      g: 178,
      b: 138,
    },
  },
  {
    id: generateGuid(),
    name: "",
    pdfObjectId: null,
    pageIndex: 0,
    boundingBox: {
      left: 379.8178192138672,
      top: 146.37584228515624,
      width: 24,
      height: 24,
    },
    opacity: 1,
    action: null,
    note: null,
    creatorName: null,
    createdAt: "2023-05-04T05:57:53.823Z",
    updatedAt: "2023-05-04T05:57:53.823Z",
    customData: {
      filterTag: "tag5",
      annotationId: "",
      libraryId: generateGuid(),
      annotationType: "NoteAnnotation",
    },
    noPrint: false,
    noZoom: false,
    noRotate: false,
    noView: false,
    hidden: false,
    additionalActions: null,
    text: {
      format: "plain",
      value: "Can’t read your handwriting",
    },
    icon: "COMMENT",
    color: {
      r: 250,
      g: 178,
      b: 138,
    },
  },
  {
    id: generateGuid(),
    name: "",
    pdfObjectId: null,
    pageIndex: 0,
    boundingBox: {
      left: 379.8178192138672,
      top: 146.37584228515624,
      width: 24,
      height: 24,
    },
    opacity: 1,
    action: null,
    note: null,
    creatorName: null,
    createdAt: "2023-05-04T05:57:53.823Z",
    updatedAt: "2023-05-04T05:57:53.823Z",
    customData: {
      filterTag: "tag5",
      annotationId: "",
      libraryId: generateGuid(),
      annotationType: "NoteAnnotation",
    },
    noPrint: false,
    noZoom: false,
    noRotate: false,
    noView: false,
    hidden: false,
    additionalActions: null,
    text: {
      format: "plain",
      value: "It isn’t clear what you are trying to say here",
    },
    icon: "COMMENT",
    color: {
      r: 250,
      g: 178,
      b: 138,
    },
  },
  {
    id: generateGuid(),
    name: "",
    pdfObjectId: null,
    pageIndex: 0,
    boundingBox: {
      left: 379.8178192138672,
      top: 146.37584228515624,
      width: 24,
      height: 24,
    },
    opacity: 1,
    action: null,
    note: null,
    creatorName: null,
    createdAt: "2023-05-04T05:57:35.569Z",
    updatedAt: "2023-05-04T05:57:35.569Z",
    customData: {
      filterTag: "tag4",
      annotationId: "",
      libraryId: generateGuid(),
      annotationType: "NoteAnnotation",
    },
    noPrint: false,
    noZoom: false,
    noRotate: false,
    noView: false,
    hidden: false,
    additionalActions: null,
    text: {
      format: "plain",
      value: "Could you have used stronger evidence here?",
    },
    icon: "COMMENT",
    color: {
      r: 249,
      g: 178,
      b: 206,
    },
  },
  {
    id: generateGuid(),
    name: "",
    pdfObjectId: null,
    pageIndex: 0,
    boundingBox: {
      left: 379.8178192138672,
      top: 146.37584228515624,
      width: 24,
      height: 24,
    },
    opacity: 1,
    action: null,
    note: null,
    creatorName: null,
    createdAt: "2023-05-04T05:57:35.569Z",
    updatedAt: "2023-05-04T05:57:35.569Z",
    customData: {
      filterTag: "tag4",
      annotationId: "",
      libraryId: generateGuid(),
      annotationType: "NoteAnnotation",
    },
    noPrint: false,
    noZoom: false,
    noRotate: false,
    noView: false,
    hidden: false,
    additionalActions: null,
    text: {
      format: "plain",
      value: "Use evidence from the text to support this",
    },
    icon: "COMMENT",
    color: {
      r: 249,
      g: 178,
      b: 206,
    },
  },
  {
    id: generateGuid(),
    name: "",
    pdfObjectId: null,
    pageIndex: 0,
    boundingBox: {
      left: 379.8178192138672,
      top: 146.37584228515624,
      width: 24,
      height: 24,
    },
    opacity: 1,
    action: null,
    note: null,
    creatorName: null,
    createdAt: "2023-05-04T05:57:35.569Z",
    updatedAt: "2023-05-04T05:57:35.569Z",
    customData: {
      filterTag: "tag4",
      annotationId: "",
      libraryId: generateGuid(),
      annotationType: "NoteAnnotation",
    },
    noPrint: false,
    noZoom: false,
    noRotate: false,
    noView: false,
    hidden: false,
    additionalActions: null,
    text: {
      format: "plain",
      value: "You need to support this",
    },
    icon: "COMMENT",
    color: {
      r: 249,
      g: 178,
      b: 206,
    },
  },
  {
    id: generateGuid(),
    name: "",
    pdfObjectId: null,
    pageIndex: 0,
    boundingBox: {
      left: 379.8178192138672,
      top: 146.37584228515624,
      width: 24,
      height: 24,
    },
    opacity: 1,
    action: null,
    note: null,
    creatorName: null,
    createdAt: "2023-05-04T05:57:35.569Z",
    updatedAt: "2023-05-04T05:57:35.569Z",
    customData: {
      filterTag: "tag4",
      annotationId: "",
      libraryId: generateGuid(),
      annotationType: "NoteAnnotation",
    },
    noPrint: false,
    noZoom: false,
    noRotate: false,
    noView: false,
    hidden: false,
    additionalActions: null,
    text: {
      format: "plain",
      value: "You need to unpack this",
    },
    icon: "COMMENT",
    color: {
      r: 249,
      g: 178,
      b: 206,
    },
  },
  {
    id: generateGuid(),
    name: "",
    pdfObjectId: null,
    pageIndex: 0,
    boundingBox: {
      left: 379.8178192138672,
      top: 146.37584228515624,
      width: 24,
      height: 24,
    },
    opacity: 1,
    action: null,
    note: null,
    creatorName: null,
    createdAt: "2023-05-04T05:56:45.183Z",
    updatedAt: "2023-05-04T05:56:45.183Z",
    customData: {
      filterTag: "tag3",
      annotationId: "",
      libraryId: generateGuid(),
      annotationType: "NoteAnnotation",
    },
    noPrint: false,
    noZoom: false,
    noRotate: false,
    noView: false,
    hidden: false,
    additionalActions: null,
    text: {
      format: "plain",
      value: "Read out loud to ‘hear’ errors",
    },
    icon: "COMMENT",
    color: {
      r: 255,
      g: 222,
      b: 127,
    },
  },
  {
    id: generateGuid(),
    name: "",
    pdfObjectId: null,
    pageIndex: 0,
    boundingBox: {
      left: 379.8178192138672,
      top: 146.37584228515624,
      width: 24,
      height: 24,
    },
    opacity: 1,
    action: null,
    note: null,
    creatorName: null,
    createdAt: "2023-05-04T05:56:45.183Z",
    updatedAt: "2023-05-04T05:56:45.183Z",
    customData: {
      filterTag: "tag3",
      annotationId: "",
      libraryId: generateGuid(),
      annotationType: "NoteAnnotation",
    },
    noPrint: false,
    noZoom: false,
    noRotate: false,
    noView: false,
    hidden: false,
    additionalActions: null,
    text: {
      format: "plain",
      value: "Need to look at punctuation here ",
    },
    icon: "COMMENT",
    color: {
      r: 255,
      g: 222,
      b: 127,
    },
  },
  {
    id: generateGuid(),
    name: "",
    pdfObjectId: null,
    pageIndex: 0,
    boundingBox: {
      left: 379.8178192138672,
      top: 146.37584228515624,
      width: 24,
      height: 24,
    },
    opacity: 1,
    action: null,
    note: null,
    creatorName: null,
    createdAt: "2023-05-04T05:56:45.183Z",
    updatedAt: "2023-05-04T05:56:45.183Z",
    customData: {
      filterTag: "tag3",
      annotationId: "",
      libraryId: generateGuid(),
      annotationType: "NoteAnnotation",
    },
    noPrint: false,
    noZoom: false,
    noRotate: false,
    noView: false,
    hidden: false,
    additionalActions: null,
    text: {
      format: "plain",
      value: "Comma",
    },
    icon: "COMMENT",
    color: {
      r: 255,
      g: 222,
      b: 127,
    },
  },
  {
    id: generateGuid(),
    name: "",
    pdfObjectId: null,
    pageIndex: 0,
    boundingBox: {
      left: 379.8178192138672,
      top: 146.37584228515624,
      width: 24,
      height: 24,
    },
    opacity: 1,
    action: null,
    note: null,
    creatorName: null,
    createdAt: "2023-05-04T05:56:45.183Z",
    updatedAt: "2023-05-04T05:56:45.183Z",
    customData: {
      filterTag: "tag3",
      annotationId: "",
      libraryId: generateGuid(),
      annotationType: "NoteAnnotation",
    },
    noPrint: false,
    noZoom: false,
    noRotate: false,
    noView: false,
    hidden: false,
    additionalActions: null,
    text: {
      format: "plain",
      value: "This is clunky",
    },
    icon: "COMMENT",
    color: {
      r: 255,
      g: 222,
      b: 127,
    },
  },
  {
    id: generateGuid(),
    name: "",
    pdfObjectId: null,
    pageIndex: 0,
    boundingBox: {
      left: 379.8178192138672,
      top: 146.37584228515624,
      width: 24,
      height: 24,
    },
    opacity: 1,
    action: null,
    note: null,
    creatorName: null,
    createdAt: "2023-05-04T05:54:43.402Z",
    updatedAt: "2023-05-04T05:54:43.402Z",
    customData: {
      filterTag: "tag2",
      annotationId: "",
      libraryId: generateGuid(),
      annotationType: "NoteAnnotation",
    },
    noPrint: false,
    noZoom: false,
    noRotate: false,
    noView: false,
    hidden: false,
    additionalActions: null,
    text: {
      format: "plain",
      value: "This seems off topic",
    },
    icon: "COMMENT",
    color: {
      r: 245,
      g: 146,
      b: 137,
    },
  },
  {
    id: generateGuid(),
    name: "",
    pdfObjectId: null,
    pageIndex: 0,
    boundingBox: {
      left: 379.8178192138672,
      top: 146.37584228515624,
      width: 24,
      height: 24,
    },
    opacity: 1,
    action: null,
    note: null,
    creatorName: null,
    createdAt: "2023-05-04T05:54:43.402Z",
    updatedAt: "2023-05-04T05:54:43.402Z",
    customData: {
      filterTag: "tag2",
      annotationId: "",
      libraryId: generateGuid(),
      annotationType: "NoteAnnotation",
    },
    noPrint: false,
    noZoom: false,
    noRotate: false,
    noView: false,
    hidden: false,
    additionalActions: null,
    text: {
      format: "plain",
      value: "What are you trying to say here?",
    },
    icon: "COMMENT",
    color: {
      r: 245,
      g: 146,
      b: 137,
    },
  },
  {
    id: generateGuid(),
    name: "",
    pdfObjectId: null,
    pageIndex: 0,
    boundingBox: {
      left: 379.8178192138672,
      top: 146.37584228515624,
      width: 24,
      height: 24,
    },
    opacity: 1,
    action: null,
    note: null,
    creatorName: null,
    createdAt: "2023-05-04T05:54:43.402Z",
    updatedAt: "2023-05-04T05:54:43.402Z",
    customData: {
      filterTag: "tag2",
      annotationId: "",
      libraryId: generateGuid(),
      annotationType: "NoteAnnotation",
    },
    noPrint: false,
    noZoom: false,
    noRotate: false,
    noView: false,
    hidden: false,
    additionalActions: null,
    text: {
      format: "plain",
      value: "This isn’t clear",
    },
    icon: "COMMENT",
    color: {
      r: 245,
      g: 146,
      b: 137,
    },
  },
  {
    id: generateGuid(),
    name: "",
    pdfObjectId: null,
    pageIndex: 0,
    boundingBox: {
      left: 379.8178192138672,
      top: 146.37584228515624,
      width: 24,
      height: 24,
    },
    opacity: 1,
    action: null,
    note: null,
    creatorName: null,
    createdAt: "2023-05-04T05:54:43.402Z",
    updatedAt: "2023-05-04T05:54:43.402Z",
    customData: {
      filterTag: "tag2",
      annotationId: "",
      libraryId: generateGuid(),
      annotationType: "NoteAnnotation",
    },
    noPrint: false,
    noZoom: false,
    noRotate: false,
    noView: false,
    hidden: false,
    additionalActions: null,
    text: {
      format: "plain",
      value: "You need to unpack this more",
    },
    icon: "COMMENT",
    color: {
      r: 245,
      g: 146,
      b: 137,
    },
  },
  {
    id: generateGuid(),
    name: "",
    pdfObjectId: null,
    pageIndex: 0,
    boundingBox: {
      left: 379.8178192138672,
      top: 146.37584228515624,
      width: 24,
      height: 24,
    },
    opacity: 1,
    action: null,
    note: null,
    creatorName: null,
    createdAt: "2023-05-04T05:46:28.753Z",
    updatedAt: "2023-05-04T05:46:28.753Z",
    customData: {
      filterTag: "tag1",
      annotationId: "",
      libraryId: generateGuid(),
      annotationType: "NoteAnnotation",
    },
    noPrint: false,
    noZoom: false,
    noRotate: false,
    noView: false,
    hidden: false,
    additionalActions: null,
    text: {
      format: "plain",
      value: "Great idea",
    },
    icon: "COMMENT",
    color: {
      r: 163,
      g: 219,
      b: 190,
    },
  },
  {
    id: generateGuid(),
    name: "",
    pdfObjectId: null,
    pageIndex: 0,
    boundingBox: {
      left: 379.8178192138672,
      top: 146.37584228515624,
      width: 24,
      height: 24,
    },
    opacity: 1,
    action: null,
    note: null,
    creatorName: null,
    createdAt: "2023-05-04T05:46:28.753Z",
    updatedAt: "2023-05-04T05:46:28.753Z",
    customData: {
      filterTag: "tag1",
      annotationId: "",
      libraryId: generateGuid(),
      annotationType: "NoteAnnotation",
    },
    noPrint: false,
    noZoom: false,
    noRotate: false,
    noView: false,
    hidden: false,
    additionalActions: null,
    text: {
      format: "plain",
      value: "Good thinking here",
    },
    icon: "COMMENT",
    color: {
      r: 163,
      g: 219,
      b: 190,
    },
  },
  {
    id: generateGuid(),
    name: "",
    pdfObjectId: null,
    pageIndex: 0,
    boundingBox: {
      left: 379.8178192138672,
      top: 146.37584228515624,
      width: 24,
      height: 24,
    },
    opacity: 1,
    action: null,
    note: null,
    creatorName: null,
    createdAt: "2023-05-04T05:46:28.753Z",
    updatedAt: "2023-05-04T05:46:28.753Z",
    customData: {
      filterTag: "tag1",
      annotationId: "",
      libraryId: generateGuid(),
      annotationType: "NoteAnnotation",
    },
    noPrint: false,
    noZoom: false,
    noRotate: false,
    noView: false,
    hidden: false,
    additionalActions: null,
    text: {
      format: "plain",
      value: "Nice work!",
    },
    icon: "COMMENT",
    color: {
      r: 163,
      g: 219,
      b: 190,
    },
  },
  {
    id: generateGuid(),
    name: "",
    pdfObjectId: null,
    pageIndex: 0,
    boundingBox: {
      left: 379.8178192138672,
      top: 146.37584228515624,
      width: 24,
      height: 24,
    },
    opacity: 1,
    action: null,
    note: null,
    creatorName: null,
    createdAt: "2023-05-04T05:46:28.753Z",
    updatedAt: "2023-05-04T05:46:28.753Z",
    customData: {
      filterTag: "tag1",
      annotationId: "",
      libraryId: generateGuid(),
      annotationType: "NoteAnnotation",
    },
    noPrint: false,
    noZoom: false,
    noRotate: false,
    noView: false,
    hidden: false,
    additionalActions: null,
    text: {
      format: "plain",
      value: "Great!",
    },
    icon: "COMMENT",
    color: {
      r: 163,
      g: 219,
      b: 190,
    },
  },
  {
    id: generateGuid(),
    name: "",
    pdfObjectId: null,
    pageIndex: 0,
    boundingBox: {
      left: 379.8178192138672,
      top: 146.37584228515624,
      width: 24,
      height: 24,
    },
    opacity: 1,
    action: null,
    note: null,
    creatorName: null,
    createdAt: "2023-05-04T05:46:28.753Z",
    updatedAt: "2023-05-04T05:46:28.753Z",
    customData: {
      filterTag: "tag1",
      annotationId: "",
      libraryId: generateGuid(),
      annotationType: "NoteAnnotation",
    },
    noPrint: false,
    noZoom: false,
    noRotate: false,
    noView: false,
    hidden: false,
    additionalActions: null,
    text: {
      format: "plain",
      value: "Drag Me!",
    },
    icon: "COMMENT",
    color: {
      r: 163,
      g: 219,
      b: 190,
    },
  },
];

export const SHAPE_ICONS: Record<string, string> = {
  COMMENT: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" focusable="false"><path d="M21.5 2h-19A1.5 1.5 0 0 0 1 3.5v12A1.5 1.5 0 0 0 2.5 17H8l4 5 4-5h5.5a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 21.5 2z" fill="none" stroke-miterlimit="10" stroke-width="2" stroke="currentColor"></path><path d="M4 12h11v1H4zm0-2h16v1H4zm0-2h16v1H4zm0-2h16v1H4z" opacity=".5"></path></svg>`,
  RIGHT_POINTER: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" focusable="false"><path d="M3 3l18 9-18 9 5.21-9L3 3m0-2a2 2 0 0 0-1.73 3l4.63 8-4.63 8a2 2 0 0 0 2.63 2.79l18-9a2 2 0 0 0 0-3.58l-18-9A2 2 0 0 0 3 1z"></path></svg>`,
  RIGHT_ARROW: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" focusable="false"><path d="M22.35 10.94l-7.29-7.29a.5.5 0 0 0-.71 0l-.71.71a.5.5 0 0 0 0 .71L19.59 11H2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h17.09l-5.94 5.94a.5.5 0 0 0 0 .71l.71.71a.5.5 0 0 0 .71 0l7.29-7.29a1.5 1.5 0 0 0-.01-2.13z"></path></svg>`,
  CHECK: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" focusable="false"><path d="M9.32 19.69l-6.94-4.87a.5.5 0 0 1-.12-.7l.72-1a.5.5 0 0 1 .7-.12l5.91 4.15a.24.24 0 0 0 .36-.15 62.33 62.33 0 0 1 9.88-14.39.51.51 0 0 1 .72 0l.92.85a.5.5 0 0 1 0 .7 64.5 64.5 0 0 0-10 15 1.5 1.5 0 0 1-2.15.53z"></path></svg>`,
  CIRCLE: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" focusable="false"><path d="M12 3a9 9 0 1 1-9 9 9 9 0 0 1 9-9m0-2a11 11 0 1 0 11 11A11 11 0 0 0 12 1z"></path></svg>`,
  CROSS: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" focusable="false"><path d="M13.41 12l6.94-6.94a.5.5 0 0 0 0-.71l-.71-.71a.5.5 0 0 0-.71 0L12 10.59 5.06 3.65a.5.5 0 0 0-.71 0l-.71.71a.5.5 0 0 0 0 .71L10.59 12l-6.94 6.94a.5.5 0 0 0 0 .71l.71.71a.5.5 0 0 0 .71 0L12 13.41l6.94 6.94a.5.5 0 0 0 .71 0l.71-.71a.5.5 0 0 0 0-.71z"></path></svg>`,
  INSERT: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" focusable="false"><path d="M22.5 16H18a5 5 0 0 1-5-5V5l3.94 3.9a.5.5 0 0 0 .71 0l.71-.71a.5.5 0 0 0 0-.71l-5.3-5.29a1.5 1.5 0 0 0-2.12 0L5.65 7.48a.5.5 0 0 0 0 .71l.71.71a.5.5 0 0 0 .71 0L11 5v6a5 5 0 0 1-5 5H1.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5H6a7 7 0 0 0 6-3.41A7 7 0 0 0 18 18h4.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM12 4z"></path></svg>`,
  NEW_PARAGRAPH: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" focusable="false"><path d="M14.14 18.73L11.52 15H10v7h1.86v-3.73L14.49 22H16v-7h-1.86v3.73zM19.86 15H17v7h2v-1.68h.87c1.84 0 2.92-1 2.92-2.68S21.7 15 19.86 15zm-.43 3.88H19v-2.44h.43c.88 0 1.29.33 1.29 1.2s-.43 1.24-1.3 1.24z"></path><path d="M4.41 12H14a7 7 0 0 0 7-7V1.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V5a5 5 0 0 1-5 5H4.41l3.94-3.94a.5.5 0 0 0 0-.71l-.71-.71a.5.5 0 0 0-.71 0l-5.64 5.65a1 1 0 0 0 0 1.41l5.65 5.65a.5.5 0 0 0 .71 0l.71-.71a.5.5 0 0 0 0-.71z"></path></svg>`,
  NOTE: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" focusable="false"><path d="M21.5 0h-19A2.5 2.5 0 0 0 0 2.5v19A2.5 2.5 0 0 0 2.5 24H14a2 2 0 0 0 1.41-.59l8-8A2 2 0 0 0 24 14V2.5A2.5 2.5 0 0 0 21.5 0zm.5 14h-6.5a1.5 1.5 0 0 0-1.5 1.5V22H2.5a.5.5 0 0 1-.5-.5v-19a.5.5 0 0 1 .5-.5h19a.5.5 0 0 1 .5.5z"></path></svg>`,
  PARAGRAPH: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" focusable="false"><path d="M19.5 2H9.21a6.14 6.14 0 0 0-6.16 5.21A6 6 0 0 0 9 14h1v7.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V4h2v17.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V4h3.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"></path></svg>`,
  HELP: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" focusable="false"><path d="M12 0a12 12 0 1 0 12 12A12 12 0 0 0 12 0zm0 22a10 10 0 1 1 10-10 10 10 0 0 1-10 10z"></path><path d="M12.31 5.18A12.67 12.67 0 0 0 8 6l.47 1.53a10.17 10.17 0 0 1 3.42-.63c1.95 0 3.12.79 3.12 2s-1.13 2-3.4 2c-.49 0-.94 0-1.51-.05l.13 3.42H12l.09-1.91h.05c3.19 0 5-1.42 5-3.62s-1.86-3.56-4.83-3.56zm-1.19 11.03a1.31 1.31 0 1 0 0 2.61 1.31 1.31 0 1 0 0-2.61z"></path></svg>`,
  STAR: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" focusable="false"><path d="M12 3a.25.25 0 0 1 .24.17L14.47 10h7.21a.25.25 0 0 1 .15.45L16 14.69l2.23 6.86a.25.25 0 0 1-.24.33.25.25 0 0 1-.15 0L12 17.59l-5.84 4.24a.25.25 0 0 1-.15 0 .25.25 0 0 1-.24-.33L8 14.69l-5.83-4.24a.25.25 0 0 1 .15-.45h7.21l2.23-6.86A.25.25 0 0 1 12 3m0-2a2.24 2.24 0 0 0-2.14 1.52L8.08 8H2.32A2.25 2.25 0 0 0 1 12.07l4.66 3.39-1.78 5.47a2.25 2.25 0 0 0 3.46 2.52L12 20.06l4.66 3.39a2.24 2.24 0 0 0 1.32.43 2.25 2.25 0 0 0 2.14-2.95l-1.78-5.48L23 12.07A2.25 2.25 0 0 0 21.68 8h-5.76l-1.78-5.48A2.24 2.24 0 0 0 12 1z"></path></svg>`,
  KEY: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" focusable="false"><path d="M17.41 5.91a1.68 1.68 0 1 0 1.19.49 1.67 1.67 0 0 0-1.19-.49z"></path><path d="M21.45 3.55A7.38 7.38 0 0 0 8.92 9.79l-7.78 7.78-.95.95.52 1.24 1 2.33.42 1 1.07.19 1.49.27.84.15.69-.5.85-.62.46-.34.21-.53.2-.5.12-.06.52-.25.86-.42.22-.93.94.17.74-.89L12 18l.45-.54v-.1h.21l1 .19.74-.74.8-.8a7.46 7.46 0 0 0 1 .07 7.38 7.38 0 0 0 5.21-12.6zm-1.41 9a5.34 5.34 0 0 1-3.8 1.57A5.45 5.45 0 0 1 15 14l-.49-.11-.35.35L13 15.46l-1-.18-1.53-.28v1.78l-.63.76-.84-.18-1.23-.52.17 1.16-.24 1-.52.25L6 19l.16 1.17-.29.83-.87.57-1.49-.27-.96-2.3 8.19-8.19.35-.35L11 10a5.38 5.38 0 1 1 9 2.58z"></path></svg>`,
};
