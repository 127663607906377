import { Module } from "vuex";

import * as names from "./action-names";
import * as types from "./mutation-types";

import * as actions from "./actions";
import * as mutations from "./mutations";
import { MarkingState } from "./types";
import { RootState } from "..";

const MarkingModule: Module<MarkingState, RootState> = {
  namespaced: true,
  state() {
    return {
      marking: null,
    };
  },
  getters: {},
  actions: {
    [names.SAVE_MARKING]: actions.SaveMarking,
  },
  mutations: {
    [types.SAVE_MARKING]: mutations.SaveMarking,
  },
};

export default MarkingModule;
