<template>
  <div v-if="hasLearningGoalsTask" class="task-container">
    <h4 class="mb-2"><strong>&#8227; Learning goals to review</strong></h4>
    <div class="pl-5 mb-2">
      <p>&#8226; Hey {{ userProfile?.profile.firstName }}!</p>
      <p class="pl-3">
        It looks like some of your students have submitted learning goals for
        you to review! Here is a list of the assignments where learning goals
        have been submitted:
      </p>
    </div>
    <div v-for="(task, index) in learningGoalsTasks" :key="index" class="pl-5">
      <div v-if="task.type === 'learningGoals'">
        <ul>
          <li class="pl-3">- {{ task.data.assignmentTitle }}</li>
        </ul>
      </div>
    </div>
  </div>
  <div v-else class="p-10">No updates are available</div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Model } from "vue-facing-decorator";
import { PendingActions } from "@/store/dashboard/dashboardTypes";
import { ProfileDetails, getProfileDetails } from "@/config/auth";
import { SaveTaskInstructionsKey } from "@/components/Assignment/CreateAssignment/assignmentType";

@Component({
  components: {},
})
export default class TeacherUpdates extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  updates!: PendingActions[];

  userProfile!: ProfileDetails;
  hasLearningGoalsTask = false;
  learningGoalsTasks: PendingActions[] = [];

  mounted() {
    this.userProfile = getProfileDetails("teacher");
    this.hasLearningGoalsTask = this.updates.some(
      (item) => item.type === "learningGoals"
    );
    this.handleGetlearningGoalsTasks();
  }

  handleGetlearningGoalsTasks() {
    const learningGoalsTasks: PendingActions[] = [];
    this.updates.forEach((item: PendingActions) => {
      const doesExist = learningGoalsTasks.find(
        (task: PendingActions) =>
          task.data.assignmentUuid === item.data.assignmentUuid
      );
      if (item.type === "learningGoals" && !doesExist) {
        learningGoalsTasks.push(item);
      }
    });
    this.learningGoalsTasks = learningGoalsTasks;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
