import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fe43e05c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "w-full flex flex-col h-full justify-center items-center"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 2,
  class: "absolute top-[-2rem] left-[-0.75rem] bg-[rgba(0,0,0,0.5)] z-[5] w-[calc(100%_+_1.5rem)] h-[calc(100%_+_2rem)] flex justify-center items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressLoader = _resolveComponent("ProgressLoader")!
  const _component_ClassesHeaderComponent = _resolveComponent("ClassesHeaderComponent")!
  const _component_ClassesTabsComponent = _resolveComponent("ClassesTabsComponent")!
  const _component_LoadingState = _resolveComponent("LoadingState")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["bg-white rounded-lg p-8 relative z-10", _ctx.loadingClass ? 'h-full' : ''])
  }, [
    (_ctx.loadingClass)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ProgressLoader, { label: "Loading..." })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_ClassesHeaderComponent, {
            classUuid: _ctx.classUuid,
            classInfo: _ctx.classInfo
          }, null, 8, ["classUuid", "classInfo"]),
          _createVNode(_component_ClassesTabsComponent, {
            classUuid: _ctx.classUuid,
            classInfo: _ctx.classInfo
          }, null, 8, ["classUuid", "classInfo"])
        ])),
    (_ctx.loadingData.showLoader)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_LoadingState, {
            state: _ctx.loadingData.message,
            backdrop: "0"
          }, null, 8, ["state"])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}