import {
  AssignmentState,
  AssignmentSubmissionSummaryStudents,
} from "../assignmentTypes";

export const SetSelectedStudentSummary = (
  state: AssignmentState,
  submission: AssignmentSubmissionSummaryStudents
) => {
  state.selectedStudentSummary = submission;
};
