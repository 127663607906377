<template>
  <div class="bg-white rounded-lg p-8 relative z-10">
    <div class="flex justify-between items-center">
      <h6 class="text-flohh-h6 font-flohh-font-bold">Assignments</h6>
      <div class="flex gap-2">
        <AppButton type="submit" @click="handleCreateAssignment">
          <span v-html="icons.plusBlack" class="mr-2"></span>
          Create
        </AppButton>
      </div>
    </div>
    <div class="py-[25px]">
      <hr />
    </div>
    <AssignmentsTabsComponent />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import LoadingState from "../utilities/LoadingState.vue";
import { icons } from "@/utils/icons";
import AssignmentsTabsComponent from "./AssignmentsTabsComponent.vue";

@Component({
  components: { AppButton, LoadingState, AssignmentsTabsComponent },
})
export default class AssignmentsListComponent extends Vue {
  icons = icons;

  handleCreateAssignment() {
    this.$router.push({ name: "CreateAssignment" });
  }
}
</script>

<style scoped lang="scss"></style>
