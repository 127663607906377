<template>
  <div class="cell-form">
    <div class="container">
      <div class="header">
        <h1>Content Editor</h1>

        <button class="btn-default" @click="close">
          <img src="@/assets/close.svg" />
        </button>
      </div>

      <div class="form-wrap">
        <div class="right-wrap">
          <button
            class="btn-delete btn-default"
            @click="handleDeleteCell"
            v-if="item.o.isCriteria || item.o.isLevel"
          >
            <img src="@/assets/icon-trash.svg" />
          </button>
        </div>
        <TextInputComponent
          type="text"
          name="content"
          :label="handleLabel(item)"
          :value="
            item
              ? item.o.criterionType
                ? item.o[item.o.criterionType]
                : ''
              : ''
          "
          :required="isRequired"
          @input="onInput($event, 'text')"
          :textType="'textarea'"
        />
        <SelectComponent
          name="color"
          :label="'Color'"
          :value="item ? item.o.color : ''"
          @change="onChange($event)"
          :options="colors.data"
          :customStyle="{
            backgroundColor: inputValue ? inputValue.color : '#BBDEEC',
          }"
        />
        <TextInputComponent
          type="text"
          name="criterionResourceLink"
          :label="'Resource Link'"
          :value="item ? item.o.criterionResourceLink : ''"
          :required="isRequired"
          @input="onInput($event, 'criterionResourceLink')"
        />
      </div>
      <div class="footer">
        <ButtonComponent v-bind="$attrs" :title="'Save'" @click="save" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { reactive } from "vue";
import { Component, Prop, Vue } from "vue-facing-decorator";
import TextInputComponent from "@/components/Input/TextInput/TextInputComponent.vue";
import SelectComponent from "@/components/Input/Select/SelectComponent.vue";
import ButtonComponent from "@/components/Button/ButtonComponent.vue";
import { ILayoutItemRequired, ILayoutItemObject, ILabel } from "./types";

const COLOR = [
  "#BBDEEC",
  "#C6EBD8",
  "#F9BDB8",
  "#FFDD7B",
  "#F1CCDE",
  "#FCCBDF",
  "#D0B29A",
  "#A7D4E7",
  "#F0F0F0",
  "#FFFFFF",
];

@Component({
  components: {
    TextInputComponent,
    SelectComponent,
    ButtonComponent,
  },
})
export default class CellFormComponent extends Vue {
  @Prop({
    type: Function as () => void,
    required: false,
  })
  close!: () => void;

  @Prop({
    type: Object,
    required: true,
  })
  item!: ILayoutItemRequired;
  colors = reactive({ data: COLOR });

  isRequired = true;

  selectedColor!: string;
  text!: string;
  criterionResourceLink!: string;

  inputValue: ILayoutItemObject = {
    criterionResourceLink: "",
    color: "",
  };

  isOptionVisible = false;

  mounted() {
    //
    if (this.item && this.item.o.color) {
      this.selectedColor = this.item.o.color;
    }
    this.inputValue.text = this.item.o.text;
    this.inputValue.criterionResourceLink = this.item.o.criterionResourceLink;
    this.inputValue.color = this.item.o.color;
  }

  onInput(event: Event, type: string) {
    const evt = event.target as HTMLInputElement;
    this.inputValue[type] = evt.value;
  }

  onChange(event: Event) {
    const evt = event.target as HTMLInputElement;
    // this.text = evt.value;
    this.inputValue.color = evt.value;
  }

  save() {
    if (this.item.o.criterionType) {
      this.item.o[this.item.o.criterionType] = this.inputValue.text;
    }
    this.item.o.criterionResourceLink = this.inputValue.criterionResourceLink;
    this.item.o.color = this.inputValue.color;
    this.$emit("update", this.item);
  }

  handleDeleteCell(e: Event) {
    e.preventDefault();
    this.$emit("delete", this.item);
    this.toggleOption();
  }

  toggleOption() {
    this.isOptionVisible = !this.isOptionVisible;
  }

  handleLabel(item: ILayoutItemRequired): string {
    const label: ILabel = {
      levelLabel: "Level Label",
      criteriaLabel: "Criteria Label",
      criterionLabel: "Criterion Label",
      unscoredCriterionLabel: "Unscored Criterion Label",
    };
    return label[item.o.criterionType as keyof ILabel];
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.cell-form {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 21;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    max-width: 600px;
    max-height: 90%;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .header {
    height: 80px;
    border-bottom: 1px solid #000;
    background-color: #ccc;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    position: relative;
  }

  .right-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .btn-ellipsis {
      width: 25px;
      height: 25px;
      margin-right: 0;
    }
  }

  .btn-default {
    padding: 0;
    margin: 0;
    border: 0;
    width: 15px;
    height: 15px;
    background-color: transparent;

    cursor: pointer;
    transition: 0.3s ease;

    &.btn-delete {
      width: 16px;
      height: 19px;
    }

    &:hover {
      opacity: 0.5;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .option-menu {
    position: absolute;
    top: calc(100% - 20px);
    right: 10px;
    width: 100px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.48);
    a {
      padding: 10px;
      border-bottom: 1px solid #555;
      display: block;
      transition: background 0.3s ease;
      &:hover {
        background-color: #ccc;
      }
      &:last-child {
        border: 0;
      }
    }
  }
  .form-wrap {
    background-color: #fff;
    width: 100%;
    padding: 20px 15px 0;
  }

  .footer {
    width: 100%;
    background-color: #fff;
    padding: 20px 15px;
    border-top: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      max-width: 100px;
    }
  }
}
</style>
