import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-686d32d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "absolute top-50 right-50 px-12 flex flex-col items-center justify-center" }
const _hoisted_2 = { class: "text-flohh-h5 font-flohh-font-bold" }
const _hoisted_3 = {
  key: 0,
  class: "font-flohh-font-medium subtitle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["w-full h-28 bg-flohh-pink rounded-t-[4px] flex flex-col justify-center items-center bg-[url('@/assets/flohh-header-pattern.png')] bg-repeat bg-[length:auto_100%]", _ctx.headerClass])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.title), 1),
      (_ctx.subtitle)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.subtitle), 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}