<template>
  <HeaderComponent :header="'Create a class'" />
  <div class="flex flex-col w-full px-[20px] py-[24px]">
    <TextInputComponent
      placeholder="Class name*"
      :value="subjectClassName"
      @input="handleInputChange($event, 'update:subjectClassName')"
      :required="true"
    />

    <TextInputComponent
      placeholder="Subject*"
      :value="subject"
      @input="handleInputChange($event, 'update:subject')"
      :required="true"
    />
    <TextInputComponent
      placeholder="Year Level"
      :value="yearLevel"
      @input="handleInputChange($event, 'update:yearLevel')"
      :required="true"
    />
  </div>

  <FooterComponent
    :buttonType="'single'"
    :isNextDisabled="subject && subjectClassName && yearLevel ? false : true"
    :handleFormStep="handleFormStep"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";

import TextInputComponent from "../Input/TextInput/TextInputComponent.vue";
import HeaderComponent from "./HeaderComponent.vue";
import FooterComponent from "./FooterComponent.vue";

@Component({
  components: { HeaderComponent, FooterComponent, TextInputComponent },
})
export default class Step1Component extends Vue {
  @Prop({
    type: Function,
  })
  handleFormStep!: (action: string) => void;
  @Prop({
    type: String,
  })
  subjectClassName!: string;
  @Prop({
    type: String,
  })
  subject!: string;
  @Prop({
    type: String,
  })
  yearLevel!: string;

  handleInputChange(event: Event, emitType: string) {
    const evt = event.target as HTMLInputElement;
    this.$emit(emitType, evt.value);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.textInput {
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  border-radius: 4px;
  border-width: 1px;
  border-color: rgba(204, 204, 204, 1);
}
</style>
