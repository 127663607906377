<template>
  <PageTitle title="App Information" :routes="routes" />
  <div class="main-container">
    <div class="card">
      <div v-if="!apiInfoLoading" class="wrapper">
        <AppTypographyText variant="bd" type="title" label="Client Version" />
        <hr class="border" />
        <span><strong>Environment: </strong>{{ environment }}</span>
        <span><strong>Release Date: </strong>{{ releaseDate }}</span>
        <span><strong>Version: </strong>{{ version }}</span>
        <span><strong>Commit: </strong>{{ commit }}</span>
      </div>
      <br />
      <br />
      <div v-if="!apiInfoLoading" class="wrapper">
        <AppTypographyText variant="bd" type="title" label="API Version" />
        <hr class="border" />
        <span><strong>Environment: </strong>{{ apiEnvironment }}</span>
        <span><strong>Release Date: </strong>{{ apiReleaseDate }}</span>
        <span><strong>Version: </strong>{{ apiVersion }}</span>
        <span><strong>Commit: </strong>{{ apiCommit }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import { version, getAPIVersion } from "@/utils/version";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import PageTitle, { TRoute } from "@/components/Layout/Partials/PageTitle.vue";

@Component({
  components: {
    AppTypographyText,
    PageTitle,
  },
})
export default class VersioningView extends Vue {
  routes: TRoute[] = [
    {
      title: "",
      routeTo: "/app/version",
      current: false,
    },
  ];

  environment = version.environment;
  version = version.number;
  releaseDate = version.releaseDate;
  commit = version.commit;

  apiEnvironment = "";
  apiVersion = "";
  apiReleaseDate = "";
  apiCommit = "";

  apiInfoLoading = true;

  async created() {
    this.apiInfoLoading = true;
    const apiInfo = await getAPIVersion();
    // TODO: Make dynamic once BE is done
    if (apiInfo) {
      this.apiEnvironment = apiInfo.environment ? apiInfo.environment : "";
      this.apiVersion = apiInfo.version ? apiInfo.version : "";
      this.apiReleaseDate = apiInfo.releaseDate ? apiInfo.releaseDate : "";
      this.apiCommit = apiInfo.versionHash ? apiInfo.versionHash : "";
    }
    this.apiInfoLoading = false;
  }
}
</script>

<style scoped lang="scss">
.main-container {
  width: 100%;
  height: 80vh;
  display: grid;
  place-items: center;
}
.card {
  background-color: #ffffffff;
  padding: 5em;
  display: flex;
  flex-direction: column;
}
.border {
  margin-bottom: 1em;
}
.wrapper {
  display: flex;
  flex-direction: column;
}
</style>
