<template>
  <div class="help-center-container">
    <img src="@/assets/help-center-header.png" class="w-full" />
    <div class="section">
      <div class="container">
        <h4 class="mb-1">
          👋 Welcome to Flohh’s Help Page! We're here to answer your questions.
        </h4>
        <p>
          Flohh is a product designed FOR TEACHERS to reduce workload and to
          help their students improve their outcomes. It is essential to us that
          we build a product that you LOVE! Our goal is to ensure that your
          experience with our platform is smooth, enjoyable and productive.
          We’re here to help you however we can, and to make your life easy.
        </p>
        <br />
        <h5 class="mb-1"><strong>Chat to us</strong></h5>
        <p>
          If you have a question or problem you are encountering, please don’t
          hesitate to message us directly using the
          <strong>on-screen chat</strong> (located on the bottom left hand side
          of your screen).
        </p>
        <br />
        <p>
          Our support team are real people based in Melbourne, Australia. We’re
          usually available online 8am-8pm AEST and have flexible weekend hours.
          If you contact outside of those times, we’ll get back to you ASAP via
          email when we are back online.
        </p>
        <br />
        <h4 class="mb-1">FAQ</h4>
        <p>
          We have a range of support FAQs, how-to videos, and articles available
          to familiarise yourself with the platform and all of the different
          features. You can check these out with the links below:
        </p>
        <div class="flex gap-5">
          <ul>
            <li v-for="(faq, i) in faq1" :key="i">
              ▶
              <a :href="faq.link" target="_blank">{{ faq.title }}</a>
            </li>
          </ul>
          <ul>
            <li v-for="(faq, i) in faq2" :key="i">
              ▶
              <a :href="faq.link" target="_blank">{{ faq.title }}</a>
            </li>
          </ul>
        </div>
        <br />
        <p>
          Can’t find what you’re looking for? Send our support team a note at
          <a href="mailto: support@flohh.com.au" target="_blank"
            >support@flohh.com.au</a
          >! We are available 7 days a week, AUS hours.
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import { FAQS_COL_1, FAQS_COL_2 } from "./data";

@Component({
  components: {},
})
export default class HelpCenterComponent extends Vue {
  faq1 = FAQS_COL_1;
  faq2 = FAQS_COL_2;

  mounted() {
    //
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.help-center-container {
  width: 100%;
  margin: 0 auto;
  padding: 0 0 0;
  background-image: url("../../assets/pattern.png");
  background-repeat: repeat;
  background-color: #f5f1f4;
  background-size: 100% auto;

  .container {
    max-width: 900px;
    margin: 0 auto;

    @media screen and (max-width: 1024px) {
      width: calc(100% - 60px);
    }
  }

  h1 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  h3 {
    font-weight: bold;
    font-size: 21px;
    margin-bottom: 20px;
    text-align: left;
  }

  a {
    text-decoration: underline;
    color: #1457cd;

    &:hover {
      filter: brightness(100%);
    }
  }

  .content {
    text-align: left;
    background-color: #fbd1ba;
    padding: 30px 0;
    &.bg-pink {
      background-color: #f1c9d5;
    }
  }

  .section {
    padding: 2.5em;
    text-align: left;
    .container {
      background-color: #fff;
      padding: 30px;
      border-radius: 8px;
      h4 {
        font-size: 18px;
        font-weight: bold;
      }
    }
    p {
      margin-bottom: 7px;
    }
    ul {
      list-style-type: none;
      padding: 0;
      li {
        margin-bottom: 7px;
      }
    }
  }
}
</style>
