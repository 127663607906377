<template>
  <div
    class="w-full h-28 bg-flohh-pink rounded-t-[4px] flex flex-col justify-center items-center bg-[url('@/assets/flohh-header-pattern.png')] bg-repeat bg-[length:auto_100%]"
    :class="headerClass"
  >
    <div
      class="absolute top-50 right-50 px-12 flex flex-col items-center justify-center"
    >
      <h5 class="text-flohh-h5 font-flohh-font-bold">{{ title }}</h5>
      <p v-if="subtitle" class="font-flohh-font-medium subtitle">
        {{ subtitle }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";

@Component({ components: {} })
export default class ModalHeaderComponent extends Vue {
  @Prop({
    type: String,
    default: "",
    required: false,
  })
  title!: string;

  @Prop({
    type: String,
    default: "",
    required: false,
  })
  subtitle!: string;

  @Prop({
    type: String,
    default: "",
    required: false,
  })
  headerClass!: string;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
//
</style>
