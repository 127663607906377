import { EventDetail, EventName } from "../type";

const markingScreenEvents = (eventName: EventName): EventDetail[] => [
  {
    id: "CS001",
    name: eventName.button,
    description: "Filter annotations",
  },
  {
    id: "CS002",
    name: eventName.button,
    description: "Delete annotation",
  },
  {
    id: "CS003",
    name: eventName.button,
    description: "Edit annotation bank",
  },
  {
    id: "CS004",
    name: eventName.button,
    description: "Add annotation",
  },
  {
    id: "CS005",
    name: eventName.button,
    description: "Save annotation bank",
  },
  {
    id: "CS006",
    name: eventName.button,
    description: "Save added annotation to the library",
  },
  {
    id: "CS007",
    name: eventName.drag,
    description: "Drag annotation to the assignment",
  },
  {
    id: "CS008",
    name: eventName.button,
    description: "Show comment library",
  },
  {
    id: "CS009",
    name: eventName.button,
    description: "Filter comment library",
  },
  {
    id: "CS010",
    name: eventName.button,
    description: "Comment allocate tag",
  },
  {
    id: "CS011",
    name: eventName.button,
    description: "Save flattened document",
  },
  {
    id: "CS012",
    name: eventName.button,
    description: "Save and close flattened document",
  },
  {
    id: "CS013",
    name: eventName.drag,
    description: "Reposition annotation",
  },
  {
    id: "CS014",
    name: eventName.button,
    description: "Open marking screen settings",
  },
  {
    id: "CS015",
    name: eventName.menu,
    description: "Select tools in marking screen settings",
  },
  {
    id: "CS016",
    name: eventName.button,
    description: "Save marking screen settings",
  },
  {
    id: "CS016",
    name: eventName.button,
    description: "Close marking screen settings",
  },
  {
    id: "CS016",
    name: eventName.button,
    description: "Update annotation",
  },
];

export default markingScreenEvents;
