<template>
  <div class="login-form">
    <TextInputComponent
      type="text"
      name="email"
      label="Email"
      :value="email"
      :required="isRequired"
      @input="onInput($event, 'update:email')"
      placeholder="Enter Email"
    />
    <TextInputComponent
      type="password"
      name="password"
      :label="'Password'"
      :value="password"
      :required="isRequired"
      @input="onInput($event, 'update:password')"
      placeholder="Enter Password"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import TextInputComponent from "@/components/Input/TextInput/TextInputComponent.vue";

@Component({
  components: {
    TextInputComponent,
  },
  emits: ["update:email", "update:password"],
})
export default class LoginFormComponent extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  email!: string;

  @Prop({
    type: String,
    required: true,
  })
  password!: string;

  isRequired = true;
  /**
   * onInput
   */
  public onInput(event: Event, emitType: string) {
    const evt = event.target as HTMLInputElement;
    this.$emit(emitType, evt.value);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.login-form {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}
</style>
