import { ActionContext } from "vuex";
import { BulkUploadState } from "../bulkUploadStateTypes";
import { RootState } from "@/store";
import * as mutationType from "../mutation-types";

export const SetUnassignedAction = (
  context: ActionContext<BulkUploadState, RootState>,
  params: string[]
) => {
  context.commit(mutationType.SET_UNASSIGNED, params);
};
