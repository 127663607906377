<template>
  <div class="pdf-preview-container"></div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import PSPDFKit, { Instance } from "pspdfkit";
import { getPspdfkitLicenseKey } from "@/utils/EnvironmentUtils";

@Component({
  components: {},
})
export default class PdfViewComponent extends Vue {
  //
  @Prop({ type: [String], required: false })
  pdfFile!: string;

  @Prop({ type: [String], required: false })
  pdfBase64!: string;

  instance!: Instance;

  PSPDFKIT_LICENSE_KEY = getPspdfkitLicenseKey();

  @Watch("pdfFile")
  onPdfFileChanged(val: string) {
    if (val) {
      this.loadPSPDFKit();
    }
  }

  @Watch("pdfBase64")
  onPdfBase64Changed(val: string) {
    if (val) {
      this.loadPSPDFKit();
    }
  }

  mounted() {
    this.loadPSPDFKit().then((instance: Instance) => {
      this.instance = instance;
      instance.setViewState((viewState) =>
        viewState.set("showToolbar", !viewState.showToolbar)
      );
    });
  }

  beforeUnmount() {
    PSPDFKit.unload(".pdf-preview-container");
  }

  async loadPSPDFKit() {
    PSPDFKit.unload(".pdf-preview-container");
    const pdfFile: string = this.pdfFile;
    const pdfBase64: string = this.pdfBase64;
    return PSPDFKit.load({
      licenseKey: this.PSPDFKIT_LICENSE_KEY,
      document: pdfFile ? pdfFile : `data:application/pdf;base64,${pdfBase64}`,
      container: ".pdf-preview-container",
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.pdf-preview-container {
  width: 100%;
  height: 100%;
}
</style>
