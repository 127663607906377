<script lang="ts">
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import FormField from "@/components/Layout/Forms/FormField.vue";
import SelectCommentABank from "@/components/utilities/SelectCommentABank.vue";
import { Comments } from "@/store/comments/types";
import { Vue, Component, Emit, Watch, Prop } from "vue-facing-decorator";
import { CommentBankList } from "@/store/comments/types";

@Component({ components: { SelectCommentABank, AppButton, FormField } })
export default class SelectOrCreateCommentABank extends Vue {
  @Prop({
    type: Array,
    default: [],
  })
  commentBankList!: CommentBankList[];

  selectCommentABank: Comments = {
    assignment: "",
    comments: [],
    name: "",
  };
  isSelectedManual = false;

  @Emit("onClickContinue")
  handleClickContinue(): void | Comments | null {
    if (this.isSelectedManual) {
      return;
    }
    return this.selectCommentABank;
  }

  handleClickSelectManual() {
    this.isSelectedManual = !this.isSelectedManual;
    this.selectCommentABank = {
      assignment: "",
      comments: [],
      name: "",
    };
  }

  @Watch("selectCommentABank")
  selectCommentWatcher(value: Comments): void {
    if (value && value.name) {
      this.isSelectedManual = false;
      return;
    }
  }
}
</script>
<template>
  <div class="w-full px-4 py-2">
    <div class="w-full text-center mt-10">
      <p class="text-sm">
        These are the comments which you will be able to use while giving final
        feedback to your students.
      </p>
    </div>
    <div class="w-full justify-center flex items-center mt-10">
      <div
        @click="handleClickSelectManual"
        :class="[
          {
            'bg-flohh-light-yellow': !isSelectedManual,
            'bg-flohh-secondary-yellow': isSelectedManual,
          },
          'bg-flohh-light-yellow h-[180px] w-[185px] rounded-lg relative flex justify-center items-center cursor-pointer',
        ]"
      >
        <img class="absolute top-0 left-0" src="@/assets/rectangle-blue.svg" />
        <div
          class="w-full h-20 font-semibold flex justify-center items-center px-4 text-center"
        >
          <p class="mx-auto">Add comment manually</p>
        </div>
      </div>
    </div>
    <div class="flex justify-center items-start py-10 font-semibold text-sm">
      or
    </div>
    <FormField label="Make a Copy of an Existing Comment Bank">
      <SelectCommentABank
        v-model="selectCommentABank"
        optionLabel="name"
        :commentBankList="commentBankList"
      />
    </FormField>
    <div class="w-full flex">
      <div class="grow" />
      <div class="flex justify-center items-center mt-5">
        <AppButton
          @click="handleClickContinue"
          :disabled="
            !isSelectedManual &&
            (selectCommentABank && selectCommentABank.name ? false : true)
          "
          label="Continue"
          iconLeft="checkBlack"
          type="submit"
        />
      </div>
    </div>
  </div>
</template>
<style scoped></style>
