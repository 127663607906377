import "froala-editor/js/plugins.pkgd.min.js";
//Import third party plugins
import "froala-editor/js/third_party/embedly.min";
import "froala-editor/js/third_party/font_awesome.min";
import "froala-editor/js/third_party/spell_checker.min";
import "froala-editor/js/third_party/image_tui.min";
// Import Froala Editor css files.
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.min.css";

export const API_KEY =
  "vYA6mA5C5C4H4A3A11B6eMRPYf1h1REb1BGQOQIc2CDBREJImA11C8D6E6B1G4E4F2F3C7==";
