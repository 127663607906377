import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c04f969"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "select-input" }
const _hoisted_2 = ["model"]
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["value", "selected"]
const _hoisted_5 = {
  key: 0,
  class: "error-msg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      _createElementVNode("select", _mergeProps(_ctx.$attrs, {
        model: _ctx.value,
        style: _ctx.customStyle
      }), [
        (_ctx.showDefaultSelected)
          ? (_openBlock(), _createElementBlock("option", {
              key: 0,
              value: "",
              class: "text-gray-light",
              disabled: _ctx.value ? true : false
            }, _toDisplayString(_ctx.selectPlaceholder), 9, _hoisted_3))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
          return (_openBlock(), _createElementBlock("option", {
            key: option,
            value: option,
            selected: option === _ctx.value
          }, _toDisplayString(option), 9, _hoisted_4))
        }), 128))
      ], 16, _hoisted_2)
    ]),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true)
  ]))
}