import { ActionContext } from "vuex";
import { BulkUploadState, CutAssignment } from "../bulkUploadStateTypes";
import { RootState } from "@/store";
import * as mutationType from "../mutation-types";

export const SetCutAssignmentAction = (
  context: ActionContext<BulkUploadState, RootState>,
  params: CutAssignment
) => {
  context.commit(mutationType.SET_CUT_ASSIGNMENT, params);
};
