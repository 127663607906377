import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createBlock(_component_Checkbox, _mergeProps({
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
    "input-id": _ctx.inputId,
    name: _ctx.name,
    binary: _ctx.binary,
    value: _ctx.value
  }, _ctx.$attrs), null, 16, ["modelValue", "input-id", "name", "binary", "value"]))
}