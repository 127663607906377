<template>
  <div class="max-w-[1200px] overflow-hidden h-[650px] flex text-[14px]">
    <PdfPreviewComponent />
    <SidePanelComponent :isTeacherApproved="isTeacherApproved" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";

import LearningGoalsHeader from "./LearningGoalsHeader.vue";
import SidePanelComponent from "./SidePanelComponent.vue";
import PdfPreviewComponent from "../utilities/PdfPreviewComponent.vue";

@Component({
  components: { PdfPreviewComponent, LearningGoalsHeader, SidePanelComponent },
})
export default class LearningGoalsModal extends Vue {
  @Prop({
    type: Boolean,
    required: true,
  })
  isTeacherApproved!: boolean;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
