<script lang="ts">
import FormField from "@/components/Layout/Forms/FormField.vue";
import { Vue, Model, Component } from "vue-facing-decorator";

@Component({ components: { FormField } })
export default class AssignmentAdvanceSettingVisibility extends Vue {
  @Model({
    type: String,
    required: true,
  })
  value!: string;

  options = [
    {
      value: "students_can_see_task_instructions",
      label: "Students can see task instructions (default)",
    },
    {
      value: "students_cannot_see_task_instructions",
      label: "Students cannot see task instructions",
    },
  ];
}
</script>
<template>
  <FormField
    v-model="value"
    :options="options"
    option-value="value"
    option-label="label"
    label="Task instructions:"
    type="select"
  />
</template>
