import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b8e6eca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col w-full px-[20px] py-[24px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_TextInputComponent = _resolveComponent("TextInputComponent")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeaderComponent, { header: 'Create a class' }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TextInputComponent, {
        placeholder: "Class name*",
        value: _ctx.subjectClassName,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleInputChange($event, 'update:subjectClassName'))),
        required: true
      }, null, 8, ["value"]),
      _createVNode(_component_TextInputComponent, {
        placeholder: "Subject*",
        value: _ctx.subject,
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleInputChange($event, 'update:subject'))),
        required: true
      }, null, 8, ["value"]),
      _createVNode(_component_TextInputComponent, {
        placeholder: "Year Level",
        value: _ctx.yearLevel,
        onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleInputChange($event, 'update:yearLevel'))),
        required: true
      }, null, 8, ["value"])
    ]),
    _createVNode(_component_FooterComponent, {
      buttonType: 'single',
      isNextDisabled: _ctx.subject && _ctx.subjectClassName && _ctx.yearLevel ? false : true,
      handleFormStep: _ctx.handleFormStep
    }, null, 8, ["isNextDisabled", "handleFormStep"])
  ], 64))
}