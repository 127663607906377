<template>
  <AuthSelectionComponent
    :title="title"
    :view="'register'"
    :updateView="updateView"
  />
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import AuthSelectionComponent from "@/components/Authentication/AuthSelectionComponent.vue";

@Component({
  components: {
    AuthSelectionComponent,
  },
})
export default class RegistrationSelectionView extends Vue {
  title = "Sign Up";

  updateView() {
    // this.isSelectionView = !this.isSelectionView;
  }
}
</script>

<style scoped lang="scss"></style>
