<script lang="ts">
import Checkbox from "primevue/checkbox";
import { Vue, Component, Prop, Model } from "vue-facing-decorator";

@Component({ components: { Checkbox } })
export default class FormCheckBox extends Vue {
  @Prop({
    type: String,
    default: "",
  })
  inputId!: string;

  @Prop({
    type: String,
    default: "",
  })
  name!: string;

  @Model({
    type: [Boolean, String],
    required: true,
  })
  model!: boolean | string;

  @Prop({
    type: [Boolean, String],
    required: true,
  })
  value!: boolean | string;

  @Prop({
    type: Boolean,
    default: true,
  })
  binary!: boolean;
}
</script>
<template>
  <Checkbox
    v-model="model"
    :input-id="inputId"
    :name="name"
    :binary="binary"
    :value="value"
    v-bind="$attrs"
  />
</template>
<style lang="scss" scoped></style>
