import { Module } from "vuex";
import { AuthState } from "./AuthStateTypes";
import { RootState } from "..";
import * as mutationType from "./mutation-types";
import * as mutation from "./mutations";
import * as actionName from "./action-names";
import * as action from "./actions";
import state from "./AuthState";

const auth: Module<AuthState, RootState> = {
  namespaced: true,
  state,
  actions: {
    [actionName.SET_AUTH]: action.SetAuthAction,
    [actionName.SET_TEACHER]: action.SetTeacherAction,
    [actionName.GET_AUTH_ME]: action.GetAuthRefreshTokenAction,
    [actionName.SET_ACCESS_ON_BOARDING]: action.SetAccessOnBoardingAction,
  },
  mutations: {
    [mutationType.SET_AUTH]: mutation.SetAuth,
    [mutationType.SET_TEACHER]: mutation.SetTeacher,
    [mutationType.SET_LOADING_AUTH]: mutation.SetLoadingAuth,
    [mutationType.SET_ACCESS_ON_BOARDING]: mutation.SetAccessOnBoarding,
  },
};

export default auth;
