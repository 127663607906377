// SetUser.ts
// Action that will set the user

import { ActionContext } from "vuex";
import { UserState, UserInfo } from "../types";
import * as mutations from "../mutation-types";

interface ActionParams {
  user: UserInfo;
}

export async function SetUser(
  context: ActionContext<UserState, unknown>,
  params: ActionParams
) {
  console.debug(`[action] SetUser :`, params);
  context.commit(mutations.SET_USER, params);
}
