<template>
  <div class="select-input">
    <label>
      {{ label }}
      <select v-bind="$attrs" :model="value" :style="customStyle">
        <option
          v-if="showDefaultSelected"
          value=""
          class="text-gray-light"
          :disabled="value ? true : false"
        >
          {{ selectPlaceholder }}
        </option>
        <option
          v-for="option in options"
          :key="option"
          :value="option"
          :selected="option === value"
        >
          {{ option }}
        </option>
      </select>
    </label>
    <p class="error-msg" v-if="error">{{ error }}</p>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";

@Component
export default class SelectComponent extends Vue {
  error!: string;

  @Prop({
    type: String,
    required: false,
  })
  label!: string;

  @Prop({
    type: String,
    required: false,
  })
  value!: string;

  @Prop({
    type: String,
    required: false,
  })
  placeholder!: string;

  @Prop({
    type: Boolean,
    required: false,
  })
  required!: boolean;

  @Prop({
    type: Object,
    required: false,
  })
  customStyle!: object;

  @Prop({
    type: Array as () => string[],
    required: false,
  })
  options!: string[];

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  showDefaultSelected!: boolean;

  @Prop({
    type: String,
    required: false,
    default: "Select",
  })
  selectPlaceholder!: string;

  get name() {
    return this.label.toLowerCase();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$browser-context: 16; // Default
@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

.select-input {
  display: block;
  text-align: left;
  margin-bottom: 20px;
  width: 100%;
  label {
    display: block;
    text-align: left;
    select {
      width: 100%;
      height: 40px;
      background: #f9f9f9;
      border: 1px solid #dedede;
      padding: 0 15px;
      margin-top: 5px;
      border-radius: 8px;
      display: block;
    }
  }
  .error-msg {
    font-size: 12px;
    color: #f00;
    margin-top: 5px;
  }
}
</style>
