import { EventDetail, EventName } from "../type";

const classModalEvents = (eventName: EventName): EventDetail[] => [
  {
    id: "CM001",
    name: eventName.button,
    description: "Save class basic details",
  },
  {
    id: "CM002",
    name: eventName.view,
    description: "Invite with a class link",
  },
  {
    id: "CM003",
    name: eventName.view,
    description: "Invite student with CSV file",
  },
  {
    id: "CM004",
    name: eventName.button,
    description: "Submit CSV file",
  },
  {
    id: "CM005",
    name: eventName.view,
    description: "Invite student with manual information",
  },
  {
    id: "CM006",
    name: eventName.button,
    description: "Submit student information",
  },
];

export default classModalEvents;
