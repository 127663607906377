import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_SuperAdminStudentComponent = _resolveComponent("SuperAdminStudentComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      title: "Super Admin",
      routes: _ctx.routes
    }, null, 8, ["routes"]),
    _createVNode(_component_SuperAdminStudentComponent)
  ], 64))
}