import {
  BulkUploadState,
  BulkUpload,
  CutAssignment,
} from "../bulkUploadStateTypes";

export const SetCutAssignment = (
  state: BulkUploadState,
  params: CutAssignment
) => {
  const assignments: BulkUpload[] = structuredClone(state.bulkUploads);
  const originalAssignment: BulkUpload = {
    student: assignments[params.bulkIndex].student,
    assignments: assignments[params.bulkIndex].assignments.slice(
      0,
      params.assignmentIndex
    ),
  };

  const cuttedAssignment: BulkUpload = {
    student: "",
    assignments: assignments[params.bulkIndex].assignments.slice(
      params.assignmentIndex
    ),
  };

  state.bulkUploads.splice(params.bulkIndex, 1);
  state.bulkUploads.splice(params.bulkIndex, 0, originalAssignment);
  state.bulkUploads.splice(params.bulkIndex + 1, 0, cuttedAssignment);
};
