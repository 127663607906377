<script lang="ts">
import { Vue, Component, Model, Prop, Watch } from "vue-facing-decorator";

interface Step {
  stepper?: string | number;
  name: string | number;
  descriptions: string;
}

@Component({ components: {} })
export default class StepsUtility extends Vue {
  @Model({
    type: Number,
    default: 0,
    name: "activeTab",
  })
  model!: number;

  @Model({
    type: [Number, String],
    default: 0,
    name: "tabName",
  })
  tabNameModel!: string | number;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: Array,
    required: true,
  })
  steps!: Step[];

  @Prop({
    type: Boolean,
    default: false,
  })
  allowHistory!: boolean;

  tabHistory: number[] = [];

  @Watch("model")
  modelWatcher(value: number) {
    this.tabHistory.push(value);
  }

  handleClickTab(index: number) {
    if (this.disabled) return;
    this.model = index;
    this.tabNameModel = this.steps[index].name;
  }

  mounted() {
    this.tabHistory.push(this.model);
  }
}
</script>
<template>
  <div class="w-full flex gap-x-2 p-1" v-bind="$attrs">
    <div
      :class="[
        'flex-1 flex',
        {
          'cursor-pointer': !disabled,
        },
      ]"
      v-for="(step, index) in steps"
      :key="index"
      @click="handleClickTab(index)"
    >
      <div class="w-full">
        <div
          class="w-full flex flex-col justify-start items-start h-[34px] gap-y-2"
        >
          <div
            :class="[
              'w-[24px] h-[24px] rounded-full flex justify-center items-center text-flohh-text-caption font-flohh-font-medium ',
              index === model || (allowHistory && tabHistory.includes(index))
                ? 'text-flohh-neutral-20 bg-flohh-primary-pink'
                : 'text-flohh-neutral-70 border border-solid border-flohh-neutral-70',
            ]"
          >
            {{ step.stepper ?? index + 1 }}
          </div>
          <div
            :class="[
              'border-b border-solid w-full ',
              index === model || (allowHistory && tabHistory.includes(index))
                ? 'border-flohh-primary-pink'
                : 'border-flohh-neutral-70',
            ]"
          />
        </div>
        <div
          :class="[
            'w-full text-flohh-text-body font-flohh-font-bold px-1 py-[8px]',
            index === model || (allowHistory && tabHistory.includes(index))
              ? 'text-flohh-neutral-5'
              : 'text-flohh-neutral-70',
          ]"
        >
          {{ step.name }}
        </div>
        <div
          :class="[
            'font-flohh-font-regular text-flohh-text-small px-1',
            index === model || (allowHistory && tabHistory.includes(index))
              ? 'text-flohh-neutral-5'
              : 'text-flohh-neutral-70',
          ]"
        >
          {{ step.descriptions }}
        </div>
      </div>
    </div>
  </div>
</template>
