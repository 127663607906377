<template>
  <div
    class="max-w-[290px] w-full flex flex-col gap-[16px] p-[16px] text-sm"
    :style="{
      backgroundColor: 'rgba(252, 234, 232, 1)',
      color: 'rgba(0, 0, 0, 1)',
    }"
  >
    <!-- TODO: Create TeacherSidePanelComponent.vue -->
    <!-- <div v-if="viewType === 'teacher'">
      <p class="font-bold">Comment</p>
      <textarea
        class="input-textarea h-[200px]"
        :value="comment"
        disabled
      ></textarea>
    </div> -->

    <StudentSidePanelComponent
      :viewType="viewType"
      :assignmentStatus="assignmentStatus"
      :comment="comment"
      :assignment="assignment"
      :pastLearningGoal="pastLearningGoal"
      :pdfBase64="pdfBase64"
      :studentLearningGoal="learningGoal"
      :submissionId="submissionId"
      :submission="submission"
      :studentId="studentId"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-facing-decorator";
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";
import TextInputComponent from "../Input/TextInput/TextInputComponent.vue";
import StudentSidePanelComponent from "./Student/StudentSidePanelComponent.vue";
import Button from "primevue/button";

@Component({
  components: {
    TextInputComponent,
    Button,
    Toast,
    StudentSidePanelComponent,
  },
})
export default class SidePanelComponent extends Vue {
  @Prop({
    type: String,
    required: false,
  })
  comment!: string;

  @Prop({
    type: String,
    required: false,
  })
  viewType!: string;

  @Prop({
    type: String,
    required: false,
  })
  assignmentStatus!: string;

  @Prop({
    required: false,
  })
  assignment!: any;

  @Prop({
    type: String,
    required: false,
  })
  pastLearningGoal!: string;

  @Prop({
    type: String,
    required: false,
  })
  pdfBase64!: string;

  @Prop({
    type: String,
    required: false,
  })
  learningGoal!: string;

  @Prop({
    type: String,
    required: false,
  })
  submissionId!: string;

  @Prop({
    type: String,
    required: false,
  })
  studentId!: string;

  @Prop({
    required: false,
  })
  submission!: unknown;

  toast = useToast();
  // teacherComment = this.comment;

  assignmentSubmitted = false;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.modal-default-btn {
  background-color: rgba(37, 192, 113, 1);
  font-size: 15px;
  transition: 0.3s ease;

  &:hover {
    background-color: rgba(37, 192, 113, 1) !important;
    opacity: 0.6;
  }
}

.input-textarea {
  width: 100%;
  background: #f9f9f9;
  border: 1px solid #dedede;
  padding: 10px 15px;
  margin-top: 5px;
  border-radius: 8px;

  resize: none;
}
</style>
