<script lang="ts">
import { Vue, Component, Watch } from "vue-facing-decorator";
import {
  Navigation,
  navigation as navigationList,
  superAdminNavigation,
} from "@/utils/navigations";
import { icons as AppIcons } from "@/utils/icons";
import { RouteRecord } from "vue-router";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import SidePanelNavigation from "./Partials/SidePanelNavigation.vue";
import TopNavigation from "./Partials/TopNavigation.vue";
import TeacherService from "@/services/TeacherService";
import StudentService from "@/services/StudentService";
import {
  getAuthMe,
  setTeacherDispatch,
  setStudentDispatch,
} from "@/store/auth/auth-dispatch";
import emitter from "@/config/emitter";
import { getTeacherUuid } from "@/config/auth";
import { setClassess } from "@/store/class/class-dispatch";
import { ProfileDetails } from "@/config/auth";

// interface Navigation {
//   name: string;
//   route: string;
//   icon: string;
//   current: boolean;
//   children?: SubNavigation[];
//   subNavigation?: boolean;
//   custom?: boolean;
//   isHidden?: boolean;
// }

@Component({
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    SidePanelNavigation,
    TopNavigation,
  },
})
export default class AppLayout extends Vue {
  private teacherService: TeacherService = new TeacherService();
  private studentService: StudentService = new StudentService();
  sidebarOpen = false;
  navigation: Navigation[] = [];
  hideNavigation = false;
  icons = AppIcons;
  isSuperAdmin = false;
  eventBus = emitter;
  profile!: ProfileDetails;
  userNavigation = [
    { name: "Your profile", href: "#" },
    { name: "Sign out", href: "#" },
  ];

  async onGetUserProfile(role: string) {
    const service =
      role === "student"
        ? this.studentService.getStudent()
        : this.teacherService.getTeacher();

    try {
      const response = await service;
      if (response.data.ok) {
        const profile = response.data.data;
        this.profile = profile;

        if (role === "student") {
          localStorage.setItem(
            "student",
            profile.schoolAddress
              ? JSON.stringify(profile)
              : JSON.stringify({
                  ...profile,
                  schoolAddress: { country: "", state: "" },
                  schoolName: "",
                })
          );
        } else {
          localStorage.setItem("teacher", JSON.stringify(profile));
        }
        if (profile && role !== "super admin") {
          this.$store.dispatch(
            role === "student" ? setStudentDispatch : setTeacherDispatch,
            profile
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadClasses() {
    try {
      const params = {
        teacher: getTeacherUuid(),
      };
      await this.$store.dispatch(setClassess, params);
    } catch (error) {
      console.error(error);
    }
  }

  getAuthProfile() {
    this.$store.dispatch(getAuthMe);
  }

  // handleFilteredRoutesForStudent() {
  //   this.navigation = this.navigation
  //     .filter((nav: Navigation) => {
  //       return nav.name === "Class" && nav.custom ? false : true;
  //     })
  //     .map((nav: Navigation) => {
  //       if (nav.name === "Dashboard") {
  //         nav.route = "StudentDashboard";
  //       }

  //       return nav;
  //     });
  // }

  mounted() {
    const auth = localStorage.getItem("auth");
    const authenticatedAs = localStorage.getItem("authenticatedAs");

    if (auth) {
      const authData = JSON.parse(auth).data;
      this.isSuperAdmin = authData.roles.includes("super admin");

      this.getAuthProfile();
      this.navigation = this.isSuperAdmin
        ? superAdminNavigation
        : navigationList;

      if (authenticatedAs) {
        const userType = authenticatedAs;
        console.log(userType);
        switch (userType) {
          case "teacher":
            this.onGetUserProfile("teacher");
            this.loadClasses();
            break;
          case "super admin":
            //
            console.log("authData");
            console.log(authData);
            this.profile = {
              ...authData.roleDetails["super admin"],
              email: authData.email,
              workEmail: authData.email,
              profile: {
                uuid: authData.roleDetails["super admin"].profile,
                firstName: "Super",
                lastName: "Admin",
                user: authData.roleDetails["super admin"].user,
              },
            };
            localStorage.setItem("superadmin", JSON.stringify(this.profile));
            break;
          case "student":
            this.onGetUserProfile("student");
            // this.handleFilteredRoutesForStudent();

            this.eventBus.on("UPDATE_MY_ACCOUNT", () => {
              this.onGetUserProfile("student");
            });
            break;
          default:
            break;
        }
      }
    }

    this.eventBus.on("LOAD_CLASSES", () => {
      this.loadClasses();
    });
  }

  @Watch("$route", { immediate: true, deep: true })
  routeWatcher(value: RouteRecord) {
    if (!value) return;

    this.navigation.map((nav) => {
      if (nav.route === value.name) {
        nav.current = true;
      } else {
        nav.current = false;
      }
    });
  }

  handleNavToggle(value: boolean) {
    const main = document.getElementById("main-content");
    if (main) {
      if (value) {
        main.classList.add("extended-nav");
      } else {
        main.classList.remove("extended-nav");
      }
    }
  }
}
</script>
<template>
  <div>
    <SidePanelNavigation
      :navigation="navigation"
      @onNavToggle="handleNavToggle"
    />
    <div class="main-content" id="main-content">
      <TopNavigation
        v-model:sidebarOpen="sidebarOpen"
        v-model:hideNavigation="hideNavigation"
        :isSuperAdmin="isSuperAdmin"
        :profile="profile"
      />

      <main class="">
        <div class="">
          <div id="custom-overlay-menu"></div>
          <!-- Your content -->
          <slot />
        </div>
      </main>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.main-content {
  padding-left: 64px;
  transition: all 0.5s ease;
  height: 100vh;
  overflow: auto;
}
.extended-nav {
  padding-left: 244px !important;
}
</style>
