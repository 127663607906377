import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuthSelectionComponent = _resolveComponent("AuthSelectionComponent")!

  return (_openBlock(), _createBlock(_component_AuthSelectionComponent, {
    title: _ctx.title,
    view: 'register',
    updateView: _ctx.updateView
  }, null, 8, ["title", "updateView"]))
}