import { ActionTree } from "vuex";
import { RootState } from "@/store";
import { CreateClassState, ClassInfo, Student, UpdateStudent } from "../types";

const actions: ActionTree<CreateClassState, RootState> = {
  setType({ commit }, type: string) {
    commit("SET_TYPE", type);
  },
  setClassInfo({ commit }, classInfo: ClassInfo) {
    commit("SET_CLASS_INFO", classInfo);
  },
  resetClass({ commit }) {
    commit("RESET_CLASS");
  },
  addStudent({ commit }, student: Student) {
    commit("SET_STUDENT", student);
  },
  removeStudent({ commit }, index: number) {
    commit("REMOVE_STUDENT", index);
  },
  updateStudent({ commit }, student: UpdateStudent) {
    commit("SET_STUDENT_UPDATE", student);
  },
  setFile({ commit }, file: File[]) {
    commit("SET_FILE", file);
  },
  removeFile({ commit }) {
    commit("REMOVE_FILE");
  },
  setClass({ commit }, selectedClass: string) {
    commit("SET_CLASS", selectedClass);
  },
  removeClass({ commit }) {
    commit("REMOVE_CLASS");
  },
};

export default actions;
