import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StudentSidePanelComponent = _resolveComponent("StudentSidePanelComponent")!

  return (_openBlock(), _createElementBlock("div", {
    class: "max-w-[290px] w-full flex flex-col gap-[16px] p-[16px] text-sm",
    style: _normalizeStyle({
      backgroundColor: 'rgba(252, 234, 232, 1)',
      color: 'rgba(0, 0, 0, 1)',
    })
  }, [
    _createVNode(_component_StudentSidePanelComponent, {
      viewType: _ctx.viewType,
      assignmentStatus: _ctx.assignmentStatus,
      comment: _ctx.comment,
      assignment: _ctx.assignment,
      pastLearningGoal: _ctx.pastLearningGoal,
      pdfBase64: _ctx.pdfBase64,
      studentLearningGoal: _ctx.learningGoal,
      submissionId: _ctx.submissionId,
      submission: _ctx.submission,
      studentId: _ctx.studentId
    }, null, 8, ["viewType", "assignmentStatus", "comment", "assignment", "pastLearningGoal", "pdfBase64", "studentLearningGoal", "submissionId", "submission", "studentId"])
  ], 4))
}