import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalHeaderComponent = _resolveComponent("ModalHeaderComponent")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["mx-[auto] rounded-[4px] overflow-hidden", _ctx.containerClass])
  }, [
    _createVNode(_component_ModalHeaderComponent, {
      title: _ctx.title,
      subtitle: _ctx.subtitle,
      headerClass: _ctx.headerClass
    }, null, 8, ["title", "subtitle", "headerClass"]),
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 2))
}