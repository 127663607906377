<script lang="ts">
import { Vue, Component, Model } from "vue-facing-decorator";
import FormInputDropdown from "../Layout/Forms/FormInputDropdown.vue";

interface Tab {
  label: string;
  color: string;
  textColor: string;
  starColor: string;
}
@Component({
  components: {
    FormInputDropdown,
  },
})
export default class SelectCommentBankStatus extends Vue {
  @Model({
    type: [String, Number],
    required: true,
  })
  model!: string | number;

  tabs: Tab[] = [
    {
      label: "Went well",
      color: "#9CD8B9",
      textColor: "text-gray-800",
      starColor: "rgb(187 247 208)",
    },
    {
      label: "Targets",
      color: "#FFDD7B",
      textColor: "text-gray-900",
      starColor: "rgb(255, 221, 124)",
    },
    {
      label: "Needs improvement",
      color: "#A7D4E7",
      textColor: "text-gray-900",
      starColor: "#90c7db",
    },
    {
      label: "Other",
      color: "#F4F4F4",
      textColor: "text-gray-800",
      starColor: "rgb(202 138 4)",
    },
  ];
}
</script>
<template>
  <div class="w-full" v-bind="$attrs">
    <FormInputDropdown
      v-model="model"
      :options="tabs"
      option-value="label"
      optionLabel="label"
    >
    </FormInputDropdown>
  </div>
</template>
