import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "w-full flex justify-start items-start" }
const _hoisted_3 = { class: "flex flex-col w-full mt-6" }
const _hoisted_4 = { class: "w-full flex justify-start items-start flex-col" }
const _hoisted_5 = {
  key: 0,
  class: "w-full flex justify-start items-start flex-col mt-5"
}
const _hoisted_6 = {
  key: 0,
  class: "w-full flex justify-start items-start mt-6"
}
const _hoisted_7 = { class: "flex flex-col w-full mt-5" }
const _hoisted_8 = {
  key: 0,
  class: "w-full flex justify-start items-start flex-col"
}
const _hoisted_9 = {
  key: 1,
  class: "w-full flex justify-start items-start flex-col mt-5"
}
const _hoisted_10 = {
  key: 2,
  class: "w-full flex justify-start items-start flex-col mt-5"
}
const _hoisted_11 = { class: "w-full flex flex-col sm:flex-row mt-5 justify-center items-center pb-5" }
const _hoisted_12 = { class: "flex-1 flex justify-start items-center" }
const _hoisted_13 = { class: "flex-1 justify-start items-start flex py-2" }
const _hoisted_14 = { class: "w-full flex justify-end items-center gap-x-4" }
const _hoisted_15 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTypographyText = _resolveComponent("AppTypographyText")!
  const _component_AssignmentAdvanceSettingVisibility = _resolveComponent("AssignmentAdvanceSettingVisibility")!
  const _component_AssignmentAdvanceSettingStudentNotification = _resolveComponent("AssignmentAdvanceSettingStudentNotification")!
  const _component_AssignmentAdvanceSettingTaskInstruction = _resolveComponent("AssignmentAdvanceSettingTaskInstruction")!
  const _component_AssignmentAdvanceSettingCriteria = _resolveComponent("AssignmentAdvanceSettingCriteria")!
  const _component_AssignmentAdvanceSettingGrade = _resolveComponent("AssignmentAdvanceSettingGrade")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AppTypographyText, { label: "Assignment release settings" })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_AssignmentAdvanceSettingVisibility, {
          modelValue: _ctx.settings.studentVisibility,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.settings.studentVisibility) = $event))
        }, null, 8, ["modelValue"])
      ]),
      false
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_AssignmentAdvanceSettingStudentNotification, {
              modelValue: _ctx.settings.notificationVisibility,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.settings.notificationVisibility) = $event))
            }, null, 8, ["modelValue"])
          ]))
        : _createCommentVNode("", true)
    ]),
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_AppTypographyText, { label: "Visibilty Settings" })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      false
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_component_AssignmentAdvanceSettingTaskInstruction, {
              modelValue: _ctx.settings.instructions,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.settings.instructions) = $event))
            }, null, 8, ["modelValue"])
          ]))
        : _createCommentVNode("", true),
      false
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createVNode(_component_AssignmentAdvanceSettingCriteria, {
              modelValue: _ctx.settings.criteria,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.settings.criteria) = $event))
            }, null, 8, ["modelValue"])
          ]))
        : _createCommentVNode("", true),
      false
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createVNode(_component_AssignmentAdvanceSettingGrade, {
              modelValue: _ctx.settings.gradeVisibility,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.settings.gradeVisibility) = $event))
            }, null, 8, ["modelValue"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_AppButton, {
          text: "",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleClickSkipOrGoBack()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Go Back ")
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_component_AppButton, {
            text: "",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.handleClickSkipOrGoBack(4)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Skip this step ")
            ]),
            _: 1
          }),
          _createVNode(_component_AppButton, {
            type: "submit",
            loading: _ctx.loadingSaving,
            disabled: _ctx.loadingSaving,
            onClick: _ctx.handleClickAdvanceSettings
          }, {
            icon_left: _withCtx(() => [
              _createElementVNode("span", {
                innerHTML: _ctx.icon.checkBlack
              }, null, 8, _hoisted_15)
            ]),
            default: _withCtx(() => [
              _createTextVNode(" Save ")
            ]),
            _: 1
          }, 8, ["loading", "disabled", "onClick"])
        ])
      ])
    ])
  ]))
}