<script lang="ts">
import RadioButton from "primevue/radiobutton";
import { Vue, Component, Prop, Model } from "vue-facing-decorator";
@Component({ components: { RadioButton } })
export default class FormRadioButton extends Vue {
  @Model({
    type: [String, Boolean],
    default: "",
  })
  model!: boolean | string;

  @Prop({
    type: String,
    default: "",
  })
  inputId!: string;

  @Prop({
    type: String,
    default: "",
  })
  name!: string;

  @Prop({
    type: [String, Boolean],
    default: "",
  })
  value!: boolean | string;
}
</script>
<template>
  <RadioButton
    v-bind="$attrs"
    v-model="model"
    :inputId="inputId"
    :name="name"
    :value="value"
  />
</template>
