import { CommentState } from "./types";

const state: CommentState = {
  comments: [],
  selectedComment: {
    assignment: "",
    name: "",
    comments: [],
    uuid: "",
  },
  copiedComment: {
    assignment: "",
    comments: [],
    name: "",
  },
  assignmentUuid: "",
};

export default state;
