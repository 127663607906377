<template>
  <div
    @mouseleave="isEllipsis = false"
    @mouseenter="handleMouseEnter"
    :style="{ width: width }"
    :class="textAlign === 'center' ? 'text-center' : ''"
    class="ellipsis relative"
  >
    <div
      v-if="tooltipPosition === 'left'"
      class="tooltip"
      v-tooltip.left="{
        value: isEllipsis ? tooltipText : '',
        pt: {
          text: customClass,
        },
      }"
    ></div>
    <div
      v-else-if="tooltipPosition === 'right'"
      class="tooltip"
      v-tooltip.right="{
        value: isEllipsis ? tooltipText : '',
        pt: {
          text: customClass,
        },
      }"
    ></div>
    <div
      v-else-if="tooltipPosition === 'bottom'"
      class="tooltip"
      v-tooltip.bottom="{
        value: isEllipsis ? tooltipText : '',
        pt: {
          text: customClass,
        },
      }"
    ></div>
    <div
      v-else
      class="tooltip"
      v-tooltip.top="{
        value: isEllipsis ? tooltipText : '',
        pt: {
          text: customClass,
        },
      }"
    ></div>

    <span> {{ content }}</span>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-facing-decorator";
@Component({
  components: {},
})
export default class Ellipsis extends Vue {
  @Prop({
    type: String,
    required: false,
  })
  tooltip!: string;

  @Prop({
    type: String,
    required: false,
  })
  tooltipPosition!: string;

  @Prop({
    type: String,
    default: "",
    required: true,
  })
  content!: string;

  @Prop({
    type: String,
    required: false,
  })
  width!: string;

  @Prop({
    type: String,
    required: false,
  })
  textAlign!: string;

  @Prop({
    type: String,
    required: false,
    default: "",
  })
  customClass!: string;

  isEllipsis = false;
  tooltipText = "";

  mounted() {
    this.tooltipText = this.tooltip ? this.tooltip : this.content;
  }

  handleMouseEnter(e: any) {
    try {
      if (e.target) {
        var tolerance = 1;
        const isEllipsis =
          e.target.offsetWidth + tolerance < e.target.scrollWidth;
        if (isEllipsis) {
          this.isEllipsis = true;
        }
      }
    } catch (err) {
      //
    }
    // e.target.style.overflow = "unset";
  }
}
</script>

<style scoped lang="scss">
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tooltip {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
