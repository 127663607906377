import { MutationTree } from "vuex";
import { CreateClassState, ClassInfo, Student, UpdateStudent } from "../types";

const mutations: MutationTree<CreateClassState> = {
  SET_TYPE(state, type: string) {
    state.type = type;
  },
  SET_CLASS_INFO(state, info: ClassInfo) {
    state.classInfo = info;
  },
  SET_STUDENT(state, student: Student) {
    state.addManually.push(student);
  },
  REMOVE_STUDENT(state, index: number) {
    state.addManually.splice(index, 1);
  },
  RESET_CLASS(state) {
    state.classInfo = {
      code: "",
      subject: "",
      yearLevel: "",
      createdBy: "",
      inviteCode: "",
      teacherUuid: "",
      teacher: "",
      uuid: "",
    };
    state.type = "";
    state.addManually = [];
    state.file = null;
    state.selectedClass = "";
  },
  SET_STUDENT_UPDATE(state, payload: UpdateStudent) {
    state.addManually[payload.index] = payload.student;
  },
  SET_FILE(state, file: File[]) {
    state.file = file;
  },
  REMOVE_FILE(state) {
    state.file = null;
  },
  SET_CLASS(state, selectedClass: string) {
    state.selectedClass = selectedClass;
  },
  REMOVE_CLASS(state) {
    state.selectedClass = "";
  },
};

export default mutations;
