<template>
  <div
    class="cursor-pointer h-[180px] w-[185px] rounded-[4px] flex justify-center items-center relative p-[20px] text-center font-[700] text-[14px]"
    :style="{
      backgroundColor: active
        ? 'rgba(251, 209, 186, 1)'
        : 'rgba(254, 243, 226, 1)',
    }"
    @click="handleBlockClick"
  >
    <slot></slot>
    <img
      :src="getImagePath()"
      class="absolute"
      :style="
        imagePosition === 'top'
          ? { top: '0', left: '0' }
          : { bottom: '0', right: '0' }
      "
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";

import { TAddStudent } from "../types";

@Component({
  components: {},
})
export default class AddStudentBlock extends Vue {
  @Prop({
    type: String,
  })
  type!: TAddStudent;
  @Prop({
    type: String,
  })
  text!: string;
  @Prop({
    type: String,
  })
  image!: string;
  @Prop({
    type: String,
  })
  imagePosition!: "top" | "bottom";
  @Prop({
    type: String,
  })
  addStudentType!: string;

  @Prop({
    type: Function,
  })
  handleAddStudentType!: (type: string) => void;

  active = false;

  getImagePath() {
    return this.image ? require(`@/assets/${this.image}`) : "";
  }

  handleBlockClick() {
    this.handleAddStudentType(this.type);
  }

  mounted() {
    if (this.addStudentType) {
      this.active = this.type === this.addStudentType;
    }
  }

  updated() {
    this.active = this.type === this.addStudentType;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.active {
  background-color: rgba(251, 209, 186, 1);
}
</style>
