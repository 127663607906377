<template>
  <div
    class="w-full rounded-[8px] px-3 flex flex-col border border-solid border-flohh-neutral-85"
    v-bind="$attrs"
  >
    <div
      class="w-full py-4 flex justif y-center items-center"
      v-if="selectedClass && selectedClass.uuid"
    >
      <div class="flex flex-row w-full">
        <div
          class="flex justify-start items-start text-flohh-text-body font-flohh-font-medium"
        >
          <AppTypographyText variant="md" type="body" label="Class Title" />
        </div>
        <div class="flex-1" />
        <div
          class="flex justify-end items-end font-flohh-font-bold text-flohh-text-body"
        >
          <AppTypographyText
            variant="bd"
            type="body"
            :label="selectedClass.code"
          />
        </div>
      </div>
    </div>
    <div class="border-b border-solid border-flohh-neutral-85 w-full" />
    <div
      class="w-full py-4 flex justify-start items-start"
      v-if="selectedAssignment && selectedAssignment.uuid"
    >
      <div class="flex flex-row w-full">
        <div
          class="flex justify-start items-start text-flohh-text-body font-flohh-font-medium"
        >
          <AppTypographyText
            variant="md"
            type="body"
            label="Assignment Title"
          />
        </div>
        <div class="flex-1" />
        <div
          class="flex justify-end items-end font-flohh-font-bold text-flohh-text-body"
        >
          <AppTypographyText
            variant="bd"
            type="body"
            :label="selectedAssignment.title"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import { Vue, Component } from "vue-facing-decorator";
import { AssignmentData } from "@/store/assignment/assignmentTypes";
import { ClassData } from "@/store/class/classTypes";
@Component({
  components: {
    AppTypographyText,
  },
})
export default class AssignmentInformationCard extends Vue {
  //
  selectedAssignment!: AssignmentData;
  selectedClass!: ClassData;
  mounted() {
    ///
    if (this.$store.state.assignment.selectedAssignment) {
      this.selectedAssignment = structuredClone(
        this.$store.state.assignment.selectedAssignment
      );
    }
    if (this.$store.state.teacherClass.classInformation) {
      this.selectedClass = this.$store.state.teacherClass.classInformation;
    }
  }
}
</script>

<style scoped></style>
