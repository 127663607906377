import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "px-4 bg-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StepsUtility = _resolveComponent("StepsUtility")!
  const _component_AssignmentDetails = _resolveComponent("AssignmentDetails")!
  const _component_AssignmentTaskInstructions = _resolveComponent("AssignmentTaskInstructions")!
  const _component_AssignmentFeedback = _resolveComponent("AssignmentFeedback")!
  const _component_AssignmentAdvancedSettings = _resolveComponent("AssignmentAdvancedSettings")!
  const _component_SuccessCreatedAssignment = _resolveComponent("SuccessCreatedAssignment")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.activeTab !== 4)
      ? (_openBlock(), _createBlock(_component_StepsUtility, {
          key: 0,
          activeTab: _ctx.activeTab,
          "onUpdate:activeTab": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event)),
          steps: _ctx.steps,
          class: "my-5",
          disabled: _ctx.isDisabledTab,
          allowHistory: true
        }, null, 8, ["activeTab", "steps", "disabled"]))
      : _createCommentVNode("", true),
    (_ctx.activeTab === 0)
      ? (_openBlock(), _createBlock(_component_AssignmentDetails, {
          key: 1,
          assignmentDetails: _ctx.assignmentModel.assignmentDetails,
          "onUpdate:assignmentDetails": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.assignmentModel.assignmentDetails) = $event)),
          onOnSaveAssignmentDetails: _ctx.handleSaveAssignmentDetails,
          isEdit: _ctx.isEdit,
          createToEdit: _ctx.createToEdit
        }, null, 8, ["assignmentDetails", "onOnSaveAssignmentDetails", "isEdit", "createToEdit"]))
      : _createCommentVNode("", true),
    (_ctx.activeTab === 1)
      ? (_openBlock(), _createBlock(_component_AssignmentTaskInstructions, {
          key: 2,
          taskInstructions: _ctx.assignmentModel.taskInstructions,
          "onUpdate:taskInstructions": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.assignmentModel.taskInstructions) = $event)),
          assignment: _ctx.assignmentModel,
          onOnClickSkipOrGoBack: _ctx.handleClickSkipOrGoBack,
          onOnClickSaveInstructions: _ctx.handleClickSaveInstructions,
          isEdit: _ctx.isEdit,
          createToEdit: _ctx.createToEdit
        }, null, 8, ["taskInstructions", "assignment", "onOnClickSkipOrGoBack", "onOnClickSaveInstructions", "isEdit", "createToEdit"]))
      : _createCommentVNode("", true),
    (_ctx.activeTab === 2)
      ? (_openBlock(), _createBlock(_component_AssignmentFeedback, {
          key: 3,
          assignment: _ctx.assignmentModel,
          feedback: _ctx.assignmentModel.feedback,
          "onUpdate:feedback": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.assignmentModel.feedback) = $event)),
          onOnClickSkipOrGoBack: _ctx.handleClickSkipOrGoBack,
          onOnClickSaveFeedback: _ctx.handleClickSaveFeedback,
          isEdit: _ctx.isEdit
        }, null, 8, ["assignment", "feedback", "onOnClickSkipOrGoBack", "onOnClickSaveFeedback", "isEdit"]))
      : _createCommentVNode("", true),
    (_ctx.activeTab === 3)
      ? (_openBlock(), _createBlock(_component_AssignmentAdvancedSettings, {
          key: 4,
          assignment: _ctx.assignmentModel,
          settings: _ctx.assignmentModel.settings,
          "onUpdate:settings": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.assignmentModel.settings) = $event)),
          onOnClickSkipOrGoBack: _ctx.handleClickSkipOrGoBack,
          onOnClickSaveAdvanceSettings: _ctx.hamdleClickSaveAdvanceSettings
        }, null, 8, ["assignment", "settings", "onOnClickSkipOrGoBack", "onOnClickSaveAdvanceSettings"]))
      : _createCommentVNode("", true),
    (_ctx.activeTab === 4)
      ? (_openBlock(), _createBlock(_component_SuccessCreatedAssignment, {
          key: 5,
          assignment: _ctx.assignmentModel,
          onOnClickDone: _ctx.handleClickDone,
          isEdit: _ctx.isEdit
        }, null, 8, ["assignment", "onOnClickDone", "isEdit"]))
      : _createCommentVNode("", true)
  ]))
}