<template>
  <PageTitle title="Class" :routes="routes" />
  <PageViewContainer>
    <ClassesComponent
      v-if="id"
      :classUuid="id"
      @onClassInfoLoaded="handleClassInfoLoaded"
    />
  </PageViewContainer>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import ClassesComponent from "@/components/Classes/ClassesComponent.vue";
import PageTitle, { TRoute } from "@/components/Layout/Partials/PageTitle.vue";
import PageViewContainer from "@/components/Layout/Partials/PageViewContainer.vue";

@Component({
  components: {
    ClassesComponent,
    PageTitle,
    PageViewContainer,
  },
})
export default class ClassesView extends Vue {
  routes: TRoute[] = [];
  @Prop({
    type: String,
    required: true,
  })
  id!: string;

  handleClassInfoLoaded(classTitle: string) {
    this.routes = [
      {
        title: "Classes",
        routeTo: "/classes/all",
        current: false,
      },
      {
        title: classTitle,
        routeTo: `/classes/${this.id}`,
        current: true,
      },
    ];
  }
}
</script>

<style scoped lang="scss">
body {
  margin: 0;
  overflow: hidden !important;
}
</style>
