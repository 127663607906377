import { ActionContext } from "vuex";
import { RootState } from "@/store";
import * as mutationType from "../mutation-types";
import {
  AssignmentState,
  AssignmentSubmissionSummaryStudents,
} from "../assignmentTypes";

export const SetSelectedStudentSummaryAction = async (
  context: ActionContext<AssignmentState, RootState>,
  summary: AssignmentSubmissionSummaryStudents
) => {
  context.commit(mutationType.SET_SELECTED_STUDENT_SUMMARY, summary);
};
