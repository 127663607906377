import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInputDropdown = _resolveComponent("FormInputDropdown")!

  return (_openBlock(), _createBlock(_component_FormInputDropdown, {
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    filter: true,
    disabled: _ctx.disabled,
    options: _ctx.assignmentsClone,
    optionLabel: "title",
    optionValue: "uuid",
    loading: _ctx.loadingAssignments,
    placeholder: _ctx.loadingAssignments ? 'Loading assignments...' : _ctx.placeholder,
    background: false,
    invalid: _ctx.invalid,
    iconLeft: _ctx.iconLeft
  }, null, 8, ["modelValue", "disabled", "options", "loading", "placeholder", "invalid", "iconLeft"]))
}