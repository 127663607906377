// ClearUser.ts
// Mutation that is used when a ClearUser is successful

import { UserState, UserInfo } from "../types";

export const ClearUser = (state: UserState, params: UserInfo) => {
  console.debug(`[mutation] ClearUser :`, params);
  // update the lastModified value so we know when we changed something
  state.user = null;
};
