import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7105c24c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInputComponent = _resolveComponent("TextInputComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TextInputComponent, {
      type: "text",
      name: "email",
      label: "Email",
      value: _ctx.email,
      required: _ctx.isRequired,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInput($event, 'update:email'))),
      placeholder: "Enter Email"
    }, null, 8, ["value", "required"]),
    _createVNode(_component_TextInputComponent, {
      type: "password",
      name: "password",
      label: 'Password',
      value: _ctx.password,
      required: _ctx.isRequired,
      onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInput($event, 'update:password'))),
      placeholder: "Enter Password"
    }, null, 8, ["value", "required"])
  ]))
}