<script lang="ts">
import FormField from "@/components/Layout/Forms/FormField.vue";
import { Student } from "@/store/createClass/types";
import { Vue, Component, Prop, Emit } from "vue-facing-decorator";

type Error = {
  email?: string;
  firstName?: string;
  lastName?: string;
  studentId?: string;
};

@Component({
  components: { FormField },
})
export default class AddStudent extends Vue {
  @Prop({
    type: Number,
    required: true,
  })
  index!: number;

  @Prop({
    type: Object,
  })
  student!: Student;

  @Prop({
    type: Object,
  })
  error!: Error;
  propertyEmail = "email";

  @Prop({
    type: Number,
    required: true,
  })
  studentCount!: number;

  studentModel: Student = {
    firstName: "",
    email: "",
    lastName: "",
    studentId: "",
  };

  @Emit("onRemoveStudent")
  handleOnRemoveStudent() {
    return this.index;
  }

  @Emit("onUpdateListOfStudents")
  handleOnUpdateListOfStudentsEmit() {
    return;
  }

  handleClickRemoveStudent() {
    this.handleOnRemoveStudent();
  }

  mounted() {
    this.studentModel = structuredClone(this.student);
  }

  updated() {
    this.$store.dispatch("createClassPopup/updateStudent", {
      index: this.index,
      student: structuredClone(this.student),
    });
  }
}
</script>
<template>
  <div class="flex flex-row gap-x-4">
    <FormField
      type="text"
      :label="index === 0 ? 'First Name' : ''"
      placeholder="Student First Name"
      v-model="student.firstName"
      :error="error && error.firstName ? error.firstName : ''"
      required
    ></FormField>
    <FormField
      type="text"
      :label="index === 0 ? 'Last Name' : ''"
      placeholder="Student Last Name"
      v-model="student.lastName"
      :error="error && error.lastName ? error.lastName : ''"
      required
    ></FormField>
    <FormField
      type="text"
      :label="index === 0 ? 'Student ID' : ''"
      placeholder="Student ID"
      v-model="student.studentId"
      :error="error && error.studentId ? error.studentId : ''"
      required
    ></FormField>
    <FormField
      type="text"
      :label="index === 0 ? 'Email' : ''"
      placeholder="Student Email"
      v-model="student.email"
      :error="error && error.email ? error.email : ''"
      required
    ></FormField>
    <div class="px-1 flex justify-center items-center">
      <i
        v-if="studentCount === 1"
        :class="index === 0 ? 'mt-11' : ''"
        class="pi pi-times-circle text-lg bg-gray-200 rounded-full cursor-not-allowed"
      ></i>
      <i
        v-else
        @click="handleClickRemoveStudent"
        :class="index === 0 ? 'mt-11' : ''"
        class="pi pi-times-circle text-lg bg-gray-200 rounded-full cursor-pointer"
      ></i>
    </div>
  </div>
</template>
