<template>
  <PageViewContainer>
    <CriteriaSheetComponent :relative="true" />
  </PageViewContainer>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import CriteriaSheetComponent from "@/components/PSPDFKit/CriteriaSheetComponent.vue";
import PageViewContainer from "@/components/Layout/Partials/PageViewContainer.vue";

@Component({
  components: { CriteriaSheetComponent, PageViewContainer },
})
export default class CriteriaSheetView extends Vue {
  isCriteriaSheetOpen = true;
}
</script>

<style>
body {
  margin: 0;
}
</style>
