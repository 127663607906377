import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ae7d03a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tooltip"
}
const _hoisted_2 = {
  key: 1,
  class: "tooltip"
}
const _hoisted_3 = {
  key: 2,
  class: "tooltip"
}
const _hoisted_4 = {
  key: 3,
  class: "tooltip"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    onMouseleave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isEllipsis = false)),
    onMouseenter: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleMouseEnter && _ctx.handleMouseEnter(...args))),
    style: _normalizeStyle({ width: _ctx.width }),
    class: _normalizeClass([_ctx.textAlign === 'center' ? 'text-center' : '', "ellipsis relative"])
  }, [
    (_ctx.tooltipPosition === 'left')
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, null, 512)), [
          [
            _directive_tooltip,
            {
        value: _ctx.isEllipsis ? _ctx.tooltipText : '',
        pt: {
          text: _ctx.customClass,
        },
      },
            void 0,
            { left: true }
          ]
        ])
      : (_ctx.tooltipPosition === 'right')
        ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, null, 512)), [
            [
              _directive_tooltip,
              {
        value: _ctx.isEllipsis ? _ctx.tooltipText : '',
        pt: {
          text: _ctx.customClass,
        },
      },
              void 0,
              { right: true }
            ]
          ])
        : (_ctx.tooltipPosition === 'bottom')
          ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, null, 512)), [
              [
                _directive_tooltip,
                {
        value: _ctx.isEllipsis ? _ctx.tooltipText : '',
        pt: {
          text: _ctx.customClass,
        },
      },
                void 0,
                { bottom: true }
              ]
            ])
          : _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, null, 512)), [
              [
                _directive_tooltip,
                {
        value: _ctx.isEllipsis ? _ctx.tooltipText : '',
        pt: {
          text: _ctx.customClass,
        },
      },
                void 0,
                { top: true }
              ]
            ]),
    _createElementVNode("span", null, _toDisplayString(_ctx.content), 1)
  ], 38))
}