<template>
  <div
    class="login-container flex py-20 items-center justify-center w-full max-w-[600px] max-w-[ 70%] px-5 mx-auto"
  >
    <div
      class="rounded-md flex flex-col justify-center items-center pb-0 px-6 md:px-10 pt-5 bg-white w-full"
    >
      <div
        class="h idden flex flex-col w-full items-center justify-center p-4 relative z-[1]"
      >
        <img
          src="@/assets/flohh-logo.svg"
          class="object-contain object-[14px] max-w-[210px] w-full mx-auto my-0"
        />
      </div>
      <Divider />
      <div v-if="!isLoading">
        <div
          v-if="isAuth && isStudentJoined"
          class="pt-2 text-center flex flex-col"
        >
          <p
            class="text-flohh-text-title color-flohh-neutral-20 font-bold mb-1"
          >
            Looks like you're already a member of this class
          </p>
          <label
            class="ml-2 text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20"
            >You can go to your dashboard by clicking the button below
          </label>
          <AppButton
            type="submit"
            class="mt-4 max-w-[200px] mx-auto"
            @click="
              (e: Event) => {
                e.preventDefault();
                $router.push('/student/dashboard');
              }
            "
          >
            Go To My Dashboard
          </AppButton>
        </div>
        <div v-else>
          <div class="pt-2 pb-6 text-center max-w-2xl">
            <p class="text-flohh-text-title color-flohh-neutral-20 font-bold">
              Join Your Teacher's Flohh Class
            </p>
            <label
              class="ml-2 text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20"
              for="isAgree"
              >Add your details below to join your teacher's Flohh class so you
              can receive updates about assignments and get your feedback. Enter
              your details and log in to&nbsp;continue
            </label>
          </div>

          <section class="pb-8 w-full">
            <h4
              class="text-flohh-text-title font-flohh-font-bold text-flohh-neutral-20"
            >
              Class Information
            </h4>
            <div
              class="p-5 border border-solid border-flohh-neutral-85 rounded-lg mt-5 flex flex-col"
            >
              <div class="flex justify-between w-full gap-2">
                <p class="text-flohh-text-body text-flohh-neutral-35 w-[30%]">
                  Class Name
                </p>
                <p
                  class="text-flohh-text-body font-flohh-font-bold text-flohh-neutral-35 w-[70%] text-right"
                >
                  {{ classDetails?.class }}
                </p>
              </div>
              <Divider />
              <div class="flex justify-between w-full gap-2">
                <p class="text-flohh-text-body text-flohh-neutral-35 w-[30%]">
                  Teacher
                </p>
                <p
                  class="text-flohh-text-body font-flohh-font-bold text-flohh-neutral-35 w-[70%] text-right"
                >
                  {{ classDetails?.teacher }}
                </p>
              </div>
            </div>
          </section>

          <template v-if="isAuth && inviteCode">
            <div class="w-full flex justify-center">
              <AppButton
                type="submit"
                v-bind="$attrs"
                @click="handleClickJoin(inviteCode)"
              >
                <template #icon_left>
                  <span v-html="icons.checkBlack" class="pr-2"></span>
                </template>
                Join Now
              </AppButton>
            </div>
          </template>
          <template v-else>
            <label
              class="ml-2 text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20 text-center"
              for="isAgree"
              >Already have an account?
              <span
                @click="handleSignInRedirect"
                class="text-flohh-primary-pink underline cursor-pointer"
                >Sign in here</span
              >
            </label>

            <Divider align="center" type="solid">
              <span>or</span>
            </Divider>
            <p class="text-flohh-text-body pb-6">
              Don’t have an account yet? Choose an option below to continue
            </p>
            <section
              class="w-full flex items-center justify-center flex-col gap -5"
            >
              <AppButton
                @click="handleGoogleAuth"
                type="transparent"
                class="flex w-full !gap-4 text-flohh-text-body font-flohh-font-medium leading-[1.2] text-center !justify-start text-[#333] h-10 mb-4"
              >
                <i><img src="@/assets/icon-google.svg" /></i>
                Continue with Google
              </AppButton>
              <AppButton
                @click="handleMicrosoftAuth"
                type="transparent"
                class="flex w-full !gap-4 text-flohh-text-body font-flohh-font-medium leading-[1.2] text-center !justify-start text-[#333] h-10 mb-4"
              >
                <i><img src="@/assets/icon-microsoft.svg" /></i>
                Continue with Microsoft
              </AppButton>
              <AppButton
                type="transparent"
                @click="handleView"
                class="flex w-full !gap-4 text-flohh-text-body font-flohh-font-medium leading-[1.2] text-center !justify-start text-[#333] h-10 mb-4"
              >
                <i><img src="@/assets/icon-email.svg" /></i>
                Continue with Email
              </AppButton>
            </section>
          </template>
        </div>
      </div>
      <div
        v-if="isLoading"
        class="flex justify-center items-center min-h-[400px]"
      >
        <ProgressLoader />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import ModalCardComponent from "@/components/Modal/ModalCardComponent.vue";
import Divider from "primevue/divider";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import axios, { AxiosResponse } from "axios";
import { icons as AppIcons } from "@/utils/icons";
import { environment } from "@/environments/environment";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";
interface IClass {
  class: string;
  teacher: string;
}

@Component({
  components: {
    ModalCardComponent,
    AppButton,
    Divider,
    ProgressLoader,
  },
})
export default class StudentInviteView extends Vue {
  //
  isTrue = true;
  classDetails!: IClass;
  inviteCode = "";
  tokenCode = "";

  isLoading = true;
  isAuth = false;
  studentUuid = null;
  isStudentJoined = false;
  icons = AppIcons;
  studentInfo = {
    name: "",
    email: "",
    status: "",
  };

  BASE_URL = environment.apiEndpoint;
  REDIRECT_BASE_URL = "";
  googleCallbackUrl: string | null = "";
  microsoftCallbackUrl: string | null = "";

  async handleClickJoin(inviteCode: string) {
    const response = await axios.post(`student/class/invite/${inviteCode}`);
    this.$router.push({
      path: "/student/dashboard",
    });
  }

  handleSignInRedirect() {
    if (this.inviteCode) {
      this.$router.push({
        path: "/student/login",
        query: { code: this.inviteCode },
      });
    }
  }

  mounted() {
    this.isLoading = true;

    this.REDIRECT_BASE_URL = `${this.BASE_URL}/authentication/social`;
    const CALLBACK_BASE_URL = `${window.location.protocol}//${window.location.host}/authenticate`;

    const userDetails = localStorage.getItem("auth");
    if (userDetails) {
      const userDetailsObj = JSON.parse(userDetails);
      this.isAuth = true;
      this.studentUuid = userDetailsObj.data.roleDetails.student.uuid;
    }
    this.inviteCode = this.$route.query.code
      ? this.$route.query.code.toString()
      : "";

    this.tokenCode = this.$route.query.token
      ? this.$route.query.token.toString()
      : "";

    this.googleCallbackUrl = `${CALLBACK_BASE_URL}/google?authenticatedAs=student`;
    this.microsoftCallbackUrl = `${CALLBACK_BASE_URL}/microsoft?authenticatedAs=student`;
    if (this.inviteCode || this.tokenCode) {
      this.getClassDetails();
    } else {
      if (this.isAuth) {
        this.$router.push({
          path: "/student/dashboard",
        });
        return;
      }
      this.$router.push({
        path: "/student/login",
      });
    }
  }

  handleGoogleAuth() {
    if (this.inviteCode) {
      window.location.href = `${this.REDIRECT_BASE_URL}/google?authenticatingAs=student&inviteCode=${this.inviteCode}&successRedirectUrl=${this.googleCallbackUrl}&failedRedirectUrl=${this.googleCallbackUrl}`;
    } else {
      window.location.href = `${this.REDIRECT_BASE_URL}/google?authenticatingAs=student&successRedirectUrl=${this.googleCallbackUrl}&failedRedirectUrl=${this.googleCallbackUrl}`;
    }
  }

  handleMicrosoftAuth() {
    if (this.inviteCode) {
      window.location.href = `${this.REDIRECT_BASE_URL}/microsoft?authenticatingAs=student&inviteCode=${this.inviteCode}&successRedirectUrl=${this.microsoftCallbackUrl}&failedRedirectUrl=${this.microsoftCallbackUrl}`;
    } else {
      window.location.href = `${this.REDIRECT_BASE_URL}/microsoft?authenticatingAs=student&successRedirectUrl=${this.microsoftCallbackUrl}&failedRedirectUrl=${this.microsoftCallbackUrl}`;
    }
  }

  async getClassDetails() {
    let endpoint = "";
    if (this.inviteCode) {
      endpoint = `class/invite-code/${this.inviteCode}`;
    } else {
      endpoint = `users/activate?activationToken=${this.tokenCode}&role=student`;
    }

    try {
      //
      const response = await axios.get(endpoint);
      let className = "";
      let teacherName = "";
      if (response.data.ok) {
        if (this.inviteCode) {
          if (
            this.studentUuid &&
            response.data.data.studentUuids.includes(this.studentUuid)
          ) {
            this.isStudentJoined = true;
          }
          const { code, teacher } = response.data.data;
          teacherName = teacher
            ? teacher.profile.firstName && teacher.profile.lastName
              ? `${teacher.profile.firstName} ${teacher.profile.lastName}`
              : ""
            : "";
          className = code;
        } else {
          if (response.data.data.status === "active") {
            this.$router.push({
              path: "/student/login",
            });
            return;
          }
          const data = response.data.data;
          className = data.class.code;
          teacherName =
            data.class.teacher.profile.firstName +
            " " +
            data.class.teacher.profile.lastName;
        }

        this.classDetails = {
          class: className,
          teacher: teacherName,
        };
      }
      this.isLoading = false;
    } catch (e) {
      //
      this.isLoading = false;
    } finally {
      //
      this.isLoading = false;
    }
  }

  handleView() {
    if (this.inviteCode) {
      this.$router.push({
        path: "/student/registration",
        query: this.inviteCode ? { code: this.inviteCode } : undefined,
      });
    }

    if (this.tokenCode) {
      this.$router.push({
        path: "/student/registration",
        query: this.tokenCode ? { token: this.tokenCode } : undefined,
      });
    }
  }
}
</script>

<style scoped lang="scss"></style>
