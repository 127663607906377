import { EventDetail, EventName } from "../type";

const assignmentModalEvents = (eventName: EventName): EventDetail[] => [
  {
    id: "AM001",
    name: eventName.button,
    description: "Save assignment basic details",
  },
  {
    id: "AM002",
    name: eventName.button,
    description: "Save assignment instructions",
  },
  {
    id: "AM003",
    name: eventName.view,
    description: "Create / update comment bank",
  },
  {
    id: "AM004",
    name: eventName.button,
    description: "Save comment bank",
  },
  {
    id: "AM005",
    name: eventName.view,
    description: "Create / update criteria sheet",
  },
  {
    id: "AM006",
    name: eventName.button,
    description: "Save assignment advance settings",
  },
];

export default assignmentModalEvents;
