import { CreateClassState } from "./types";

const state: CreateClassState = {
  classInfo: {
    code: "",
    subject: "",
    yearLevel: "",
    teacher: "",
    uuid: "",
    createdBy: "",
    inviteCode: "",
    teacherUuid: "",
  },
  addManually: [],
  file: null,
  type: "",
  selectedClass: "",
};

export default state;
