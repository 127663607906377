import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")!

  return (_openBlock(), _createBlock(_component_AutoComplete, {
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    dropdown: _ctx.dropdown,
    suggestions: _ctx.items,
    onComplete: _ctx.search,
    dropdownClass: "bg-flohh-primary-pink",
    placeholder: _ctx.placeholder,
    multiple: _ctx.multiple,
    inputClass: "text-flohh-text-body w-full font-flohh-font-medium ",
    class: _normalizeClass([
      'w-full ',
      {
        'p-invalid': _ctx.invalid,
      },
    ])
  }, null, 8, ["modelValue", "dropdown", "suggestions", "onComplete", "placeholder", "multiple", "class"]))
}