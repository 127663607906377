import {
  BulkUploadState,
  BulkUpload,
  RemoveAssignment,
} from "../bulkUploadStateTypes";

export const SetRemoveAssignment = (
  state: BulkUploadState,
  params: RemoveAssignment
) => {
  const assignments: BulkUpload[] = structuredClone(state.bulkUploads);
  const selectedAssignment = state.bulkUploads[params.bulkIndex];
  selectedAssignment.assignments.splice(params.assignmentIndex, 1);
};
