<template>
  <div
    class="text-left flex flex-col gap-[20px]"
    :style="{
      color: 'rgba(51, 51, 51, 1)',
    }"
  >
    <p class="font-bold text-[16px]">Select Class</p>
    <SelectComponent :options="SAMPLE_CLASS" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";

import SelectComponent from "@/components/Input/Select/SelectComponent.vue";

import { TStudent } from "../types";

@Component({ components: { SelectComponent } })
export default class GoogleClassroomAdd extends Vue {
  @Prop({
    type: Function,
  })
  handleStudents!: (studentsList: TStudent[]) => void;

  @Prop({
    type: Array,
  })
  students!: TStudent[];

  // TODO: Replace with courses when connected with google classroom account
  SAMPLE_CLASS = [
    "Mathematics",
    "English Language Arts",
    "Science",
    "Social Studies",
    "Physical Education (PE)",
    "Art",
    "Music",
    "Computer Science",
    "History",
    "Foreign Language",
  ];
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
