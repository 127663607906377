<script lang="ts">
import FormField from "@/components/Layout/Forms/FormField.vue";
import { Vue, Model, Component } from "vue-facing-decorator";

@Component({ components: { FormField } })
export default class AssignmentAdvanceSettingVisibility extends Vue {
  @Model({
    type: String,
    required: true,
  })
  value!: string;

  options = [
    {
      value: "students_can_see_criteria",
      label: "Students can see criteria (default)",
    },
    {
      value: "students_cannot_see_criteria",
      label: "Students cannot see criteria",
    },
    {
      value: "students_can_see_criteria_when_feedback_has_been_returned",
      label: "Students can see criteria when feedback has been returned",
    },
  ];
}
</script>
<template>
  <FormField
    v-model="value"
    :options="options"
    option-value="value"
    option-label="label"
    label="Criteria:"
    type="select"
  />
</template>
