import { BulkUploadState } from "./bulkUploadStateTypes";

const bulkUploadState: BulkUploadState = {
  bulkUploads: [
    {
      student: "",
      assignments: [
        {
          id: 1,
          file: "sample/assignment1.png",
          fromIndex: null,
        },
        {
          id: 2,
          file: "sample/assignment2.png",
          fromIndex: null,
        },
        {
          id: 3,
          file: "sample/assignment3.png",
          fromIndex: null,
        },
        {
          id: 4,
          file: "sample/assignment4.png",
          fromIndex: null,
        },
        {
          id: 5,
          file: "sample/assignment5.png",
          fromIndex: null,
        },
      ],
    },
  ],
  unassignedStudents: [],
};

export default bulkUploadState;
