import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "w-full h-20 font-semibold flex justify-center items-center px-4 text-center" }
const _hoisted_3 = { class: "mx-auto capitalize" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClickSelectManual && _ctx.handleClickSelectManual(...args))),
    class: _normalizeClass([
      {
        'bg-flohh-light-yellow': !_ctx.selection.current,
        'bg-flohh-secondary-yellow': _ctx.selection.current,
      },
      'bg-flohh-light-yellow h-[180px] w-[185px] rounded-lg relative flex justify-center items-center cursor-pointer',
    ])
  }, [
    _createElementVNode("img", {
      class: _normalizeClass([
        'absolute',
        _ctx.selection.imagePosition === 'tl' ? 'top-0 left-0' : '',
        _ctx.selection.imagePosition === 'br' ? 'bottom-0 right-0' : '',
      ]),
      src: require(`@/assets/${_ctx.selection.image}`)
    }, null, 10, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.selection.name), 1)
    ])
  ], 2))
}