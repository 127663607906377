<script lang="ts">
import AutoComplete, { AutoCompleteCompleteEvent } from "primevue/autocomplete";
import { Vue, Component, Prop, Model, Emit } from "vue-facing-decorator";

@Component({ components: { AutoComplete } })
export default class FormAutocomplete extends Vue {
  @Model({
    type: [String, Number],
  })
  value!: string | number;

  @Prop({
    type: Boolean,
    default: true,
    required: false,
  })
  dropdown!: boolean;

  @Prop({
    type: Array,
    required: true,
  })
  items!: [];

  @Prop({
    type: String,
    default: "",
  })
  optionLabel!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  invalid!: boolean;

  @Prop({
    type: String,
    default: "Type to search",
  })
  placeholder!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  multiple!: boolean;

  //   emit query string
  @Emit("onSearch")
  search(event: AutoCompleteCompleteEvent) {
    return event.query;
  }
}
</script>
<template>
  <AutoComplete
    v-model="value"
    :dropdown="dropdown"
    :suggestions="items"
    @complete="search"
    dropdownClass="bg-flohh-primary-pink"
    :placeholder="placeholder"
    :multiple="multiple"
    inputClass="text-flohh-text-body w-full font-flohh-font-medium "
    :class="[
      'w-full ',
      {
        'p-invalid': invalid,
      },
    ]"
  >
  </AutoComplete>
</template>
<style lang="scss" scoped>
.p-autocomplete-input {
  @apply bg-flohh-neutral-95 text-flohh-text-body font-flohh-font-medium;
}
</style>
