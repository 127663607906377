<template>
  <PageTitle title="Assignments" :routes="routes" />
  <PageViewContainer>
    <AssignmentsListComponent />
  </PageViewContainer>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import AssignmentsListComponent from "@/components/Assignments/AssignmentsListComponent.vue";
import PageTitle, { TRoute } from "@/components/Layout/Partials/PageTitle.vue";
import PageViewContainer from "@/components/Layout/Partials/PageViewContainer.vue";

@Component({
  components: {
    AssignmentsListComponent,
    PageTitle,
    PageViewContainer,
  },
})
export default class AssignmentsListView extends Vue {
  routes: TRoute[] = [
    {
      title: "Assignments",
      routeTo: "/assignments/all",
      current: true,
    },
  ];
}
</script>

<style scoped lang="scss">
body {
  margin: 0;
  overflow: hidden !important;
}
</style>
