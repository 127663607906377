import axios from "axios";
import { AllAssignmentLearningGoal } from "@/models/LearningGoal";
import { TELEMETRY_HEAD } from "@/utils/telemetry/data";

const LEARNING_GOAL_BASE_URL = `/student/submissions`;
export default class LearningGoalService {
  getLearningGoals(classUuid: string | number) {
    return axios.get(`/class/${classUuid}/learning-goal-summary`);
  }

  postLearningGoal(payload: any) {
    return axios.post(
      `${LEARNING_GOAL_BASE_URL}/${payload.submissionUuid}/learning-goals`,
      payload.learningGoal,
      { headers: TELEMETRY_HEAD.postLearningGoal }
    );
  }

  putLearningGoal(payload: any) {
    return axios.put(
      `submissions/${payload.submissionUuid}/learning-goal/${payload.status}`,
      {
        headers:
          payload.status === "accepted"
            ? TELEMETRY_HEAD.putLearningGoalApprove
            : TELEMETRY_HEAD.putLearningGoalReject,
      }
    );
  }

  getStudentLearningGoalByClass(classUuid: string | number) {
    return axios.get(
      `${LEARNING_GOAL_BASE_URL}/class/${classUuid}/learning-goals/me?sortOrder=asc`
    );
  }
}
