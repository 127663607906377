import { RootState } from "..";
import { BulkUploadState } from "./bulkUploadStateTypes";
import state from "./bulkUploadState";
import { Module } from "vuex";
import * as mutation from "./mutations";
import * as mutationType from "./mutation-types";
import * as action from "./actions";
import * as actionName from "./action-names";

const bulkUpload: Module<BulkUploadState, RootState> = {
  namespaced: true,
  state,
  actions: {
    [actionName.SET_BULK_UPLOAD]: action.SetBulkUploadAction,
    [actionName.SET_CUT_ASSIGNMENT]: action.SetCutAssignmentAction,
    [actionName.SET_REMOVE_ASSIGNMENT]: action.SetRemoveAssignmentAction,
    [actionName.SET_MERGE_ASSIGNMENT]: action.SetMergeAssignmentAction,
    [actionName.SET_ASSIGNEE]: action.SetAssigneeAction,
    [actionName.SET_UNASSIGNED]: action.SetUnassignedAction,
  },
  mutations: {
    [mutationType.SET_BULK_UPLOAD]: mutation.SetBulkUpload,
    [mutationType.SET_CUT_ASSIGNMENT]: mutation.SetCutAssignment,
    [mutationType.SET_REMOVE_ASSIGNMENT]: mutation.SetRemoveAssignment,
    [mutationType.SET_MERGE_ASSIGNMENT]: mutation.SetMergeAssignment,
    [mutationType.SET_ASSIGNEE]: mutation.SetAssignee,
    [mutationType.SET_UNASSIGNED]: mutation.SetUnassigned,
  },
};

export default bulkUpload;
