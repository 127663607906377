<template>
  <template
    v-for="(student, index) in studentsModel"
    :key="student.studentID + gUid()"
  >
    <AddStudentInputs
      :index="index"
      :student="student"
      :handleStudents="handleStudents"
      @onRemoveStudentByIndex="deleteStudentRow"
    />
  </template>
  <div>
    <button class="modal-default-btn" @click="addStudentRow()">
      <div class="plus-icon-wrapper">
        <img src="../../../assets/icon-plus-white-rounded.svg" />
      </div>
      Add more rows
    </button>
  </div>
</template>

<script lang="ts">
import { Component, Model, Prop, Vue } from "vue-facing-decorator";

import AddStudentInputs from "./AddStudentInputs.vue";

import { generateGuid } from "../../../utils/helper";
import { TStudent } from "../types";

@Component({ components: { AddStudentInputs } })
export default class ManualComponent extends Vue {
  @Prop({
    type: Function,
  })
  handleStudents!: (studentsList: TStudent[]) => void;

  @Model({
    type: Array,
    name: "students",
  })
  studentsModel!: TStudent[];

  gUid() {
    return generateGuid();
  }

  addStudentRow() {
    this.studentsModel.push({
      firstName: "",
      lastName: "",
      studentID: "",
      email: "",
    });
  }

  deleteStudentRow(index: number) {
    this.studentsModel.splice(index, 1);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.modal-default-btn {
  margin-top: 20px;
  background-color: rgba(145, 206, 225, 1);
  padding: 10px 14px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: white;
  border-radius: 4px;
  transition: 0.3s ease;

  &:hover {
    opacity: 0.6;
  }

  &:disabled {
    background-color: rgba(153, 153, 153, 1);
    pointer-events: none;
  }
}

.plus-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;

  margin-right: 5px;
}
</style>
