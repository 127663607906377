<template>
  <div
    class="bg-white rounded-lg p-8 relative z-10"
    :class="loadingClass ? 'h-full' : ''"
  >
    <div
      v-if="loadingClass"
      class="w-full flex flex-col h-full justify-center items-center"
    >
      <ProgressLoader label="Loading..." />
    </div>
    <div v-else>
      <ClassesHeaderComponent :classUuid="classUuid" :classInfo="classInfo" />
      <ClassesTabsComponent :classUuid="classUuid" :classInfo="classInfo" />
    </div>

    <div
      v-if="loadingData.showLoader"
      class="absolute top-[-2rem] left-[-0.75rem] bg-[rgba(0,0,0,0.5)] z-[5] w-[calc(100%_+_1.5rem)] h-[calc(100%_+_2rem)] flex justify-center items-center"
    >
      <LoadingState :state="loadingData.message" backdrop="0" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import ClassesHeaderComponent from "./ClassesHeaderComponent.vue";
import ClassesTabsComponent from "./ClassesTabsComponent.vue";
import { setClassInformation } from "@/store/class/class-dispatch";
import emitter from "@/config/emitter";
import { RouteLocation } from "vue-router";
import LoadingState from "../utilities/LoadingState.vue";
import { StorageDataKey } from "@/enums/enums";
import { ClassData } from "@/store/class/classTypes";
import ProgressLoader from "../utilities/ProgressLoader.vue";

@Component({
  components: {
    ClassesHeaderComponent,
    ClassesTabsComponent,
    LoadingState,
    ProgressLoader,
  },
})
export default class ClassesComponent extends Vue {
  @Prop({
    type: [String, Number],
    required: true,
  })
  classUuid!: string | number;

  eventBus = emitter;
  loadingClass = true;
  loadingData = {
    showLoader: false,
    message: "",
  };
  classInfo!: ClassData;

  async handleFetchClassInformation(isLoading = true) {
    if (this.$route.params.id) {
      this.loadingClass = isLoading;
      await this.$store.dispatch(setClassInformation, this.classUuid);
      const classInfo: ClassData = structuredClone(
        this.$store.state.teacherClass.classInformation
      );
      this.classInfo = classInfo;
      this.$emit("onClassInfoLoaded", classInfo.code);
      this.loadingClass = false;

      this.loadingData = {
        showLoader: false,
        message: "",
      };
    }
  }

  mounted() {
    this.handleFetchClassInformation();
    this.eventBus.on("REFRESH_SELECTED_CLASS", () => {
      this.handleFetchClassInformation();
    });
    this.eventBus.on("REFRESH_SELECTED_CLASS_NO_LOADING", () => {
      this.handleFetchClassInformation(false);
    });

    this.eventBus.on("SHOW_LOADING", (data: any) => {
      // this.loadingData = {
      //   showLoader: data.showLoader,
      //   message: data.message,
      // };
    });

    localStorage.removeItem(StorageDataKey.CriteriaSheet);
    localStorage.removeItem(StorageDataKey.CriteriaSheetConfig);
    localStorage.removeItem(StorageDataKey.RedoLayout);
    localStorage.removeItem(StorageDataKey.DeletedLayout);
  }

  @Watch("$route")
  routeWatcher(newRoute: RouteLocation, oldRoute: RouteLocation) {
    if (newRoute.params.id !== oldRoute.params.id) {
      this.handleFetchClassInformation();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
//
</style>
