// axios.ts
// Setting of api headers

import axios from "axios";
import { environment } from "@/environments/environment";

const auth = localStorage.getItem("auth");
const tokenData = auth ? JSON.parse(auth) : "";
const authorization = `Bearer ${tokenData.accessToken}`;
axios.defaults.baseURL = environment.apiEndpoint;
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.common["Authorization"] = authorization;
