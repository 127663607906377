import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2cf2411f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative tooltip-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.tooltip)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(`custom-tooltiptext ${
        _ctx.position === 'right' && _ctx.tooltip.length > 50 ? 'tooltip-lg' : ''
      }`)
        }, _toDisplayString(_ctx.tooltip), 3))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.ellipsis ? 'ellipsis' : '')
    }, [
      _renderSlot(_ctx.$slots, "body", {}, undefined, true)
    ], 2)
  ]))
}