<template>
  <div class="w-full relative h-full bg-gray-600">
    <embed class="w-full h-full absolute" :src="pdfFile" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

@Component({ components: {} })
export default class PdfPreviewComponent extends Vue {
  pdfFile =
    "https://res.cloudinary.com/dzafs9h3x/image/upload/v1693472367/asignment-coversheet_vo1lzn.pdf#toolbar=0";
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
