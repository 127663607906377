import { RouteRecordRaw } from "vue-router";
import router from "./router";

type Module = {
  router: RouteRecordRaw;
};

type Modules = {
  [key: string]: Module;
};

const registerModule = (module: Module) => {
  if (module) {
    router.addRoute(module.router);
  }
};

export const registerModules = (modules: Modules) => {
  Object.keys(modules).forEach((moduleKey) => {
    const module = modules[moduleKey];
    registerModule(module);
  });
};
