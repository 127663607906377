<template>
  <div
    v-bind="$attrs"
    class="text-flohh-text-caption text-flohh-secondary-red font-flohh-font-medium my-[8px]"
  >
    <slot />
  </div>
</template>
<script lang="ts">
import { Vue, Component } from "vue-facing-decorator";

@Component({})
export default class FormErrorSpan extends Vue {}
</script>
