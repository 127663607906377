<template>
  <button v-bind="$attrs">
    {{ title }} <i v-if="icon"><img src="@/assets/chev-right.svg" /></i>
  </button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";

@Component
export default class ButtonComponent extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  title!: string;

  @Prop({
    type: String,
    required: true,
  })
  icon!: string;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
button {
  border: 0;
  background: #333333;
  color: #fff;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  transition: 0.3s ease;
  line-height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    margin-right: -10px;
    display: block;
  }
  &:hover {
    opacity: 0.7;
  }
  &[type="submit"] {
    background-color: #91cee1;
  }
}
</style>
