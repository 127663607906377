import { AuthState } from "./AuthStateTypes";

const state: AuthState = {
  auth: {
    accessToken: "",
    accessTokenExpiresOn: "",
    data: {
      email: "",
      roles: [],
      status: "",
      uuid: "",
    },
    refeshToken: "",
    refeshTokenExpiresOn: "",
    type: "",
  },
  teacher: {
    profile: "",
    profileDetails: {
      firstName: "",
      lastName: "",
      user: "",
      uuid: "",
    },
    schoolAddress: {
      country: "",
      state: "",
      address1: "",
      address2: "",
      postCode: "",
      suburb: "",
    },
    schoolName: "",
    user: "",
    uuid: "",
  },
  loadingAuth: false,
  accesOnBoarding: false,
};

export default state;
