import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "assignment" }
const _hoisted_2 = { class: "assignment-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!
  const _component_CriteriaBankComponent = _resolveComponent("CriteriaBankComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ButtonComponent, _mergeProps(_ctx.$attrs, {
        title: 'Click Here',
        onClick: _ctx.handleCriteriaBank,
        style: {"width":"120px"}
      }), null, 16, ["onClick"])
    ]),
    (_ctx.showCriteriaBank)
      ? (_openBlock(), _createBlock(_component_CriteriaBankComponent, {
          key: 0,
          close: _ctx.closeCriteriaBank
        }, null, 8, ["close"]))
      : _createCommentVNode("", true)
  ]))
}