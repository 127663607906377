import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "w-full flex justify-start items-start flex-col" }
const _hoisted_3 = { class: "w-full flex justify-start items-start flex-col mt-5" }
const _hoisted_4 = { class: "w-full flex flex-col sm:flex-row mt-5 border-t border-flohh-neutral-85 border-solid mb-4 pt-2" }
const _hoisted_5 = { class: "flex-1 flex justify-start items-center" }
const _hoisted_6 = { class: "flex-1 justify-start items-start flex py-2" }
const _hoisted_7 = { class: "w-full flex justify-end items-center gap-x-4" }
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectComment = _resolveComponent("SelectComment")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_SelectGrading = _resolveComponent("SelectGrading")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FormField, { label: "Comment Bank" }, {
        default: _withCtx(() => [
          _createVNode(_component_SelectComment, {
            modelValue: _ctx.bankModel,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.bankModel) = $event)),
            isEdit: _ctx.isEdit,
            assignmentUuid: _ctx.assignmentUuid,
            readonly: true,
            onOnSelectedChanged: _ctx.handleCommentBankData
          }, null, 8, ["modelValue", "isEdit", "assignmentUuid", "onOnSelectedChanged"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_FormField, { label: "Grading" }, {
        default: _withCtx(() => [
          (_ctx.assignmentUuid)
            ? (_openBlock(), _createBlock(_component_SelectGrading, {
                key: 0,
                ref: "SelectGradingRef",
                modelValue: _ctx.gradingModel,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.gradingModel) = $event)),
                readonly: "",
                assignmentUuid: _ctx.assignmentUuid
              }, null, 8, ["modelValue", "assignmentUuid"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_AppButton, {
          text: "",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleClickSkipOrGoBack()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Go Back ")
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_AppButton, {
            text: "",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleClickSkipOrGoBack(3)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Skip this step ")
            ]),
            _: 1
          }),
          _createVNode(_component_AppButton, {
            type: "submit",
            loading: _ctx.loadingFeedback,
            disabled: _ctx.loadingFeedback,
            onClick: _cache[4] || (_cache[4] = 
              () => {
                _ctx.handleClickSaveFeedback();
              }
            )
          }, {
            icon_left: _withCtx(() => [
              _createElementVNode("span", {
                innerHTML: _ctx.icon.checkBlack
              }, null, 8, _hoisted_8)
            ]),
            default: _withCtx(() => [
              _createTextVNode(" Save ")
            ]),
            _: 1
          }, 8, ["loading", "disabled"])
        ])
      ])
    ])
  ]))
}