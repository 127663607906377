import { EventDetail, EventName } from "../type";
import studentDashboardEvents from "./studentDashboard";
import generalEvents from "./general";
import teacherDashboardEvents from "./teacherDashboard";
import bulkUploadEvents from "./bulkUpload";
import classPageEvents from "./classPage";
import classModalEvents from "./classModal";
import gradeSheetEvents from "./gradeSheet";
import markingScreenEvents from "./markingScreen";
import assignmentModalEvents from "./assignmentModal";

const eventName: EventName = {
  button: "ui-button-click",
  menu: "ui-menu-selection",
  view: "ui-view",
  page: "page-view",
  dropdown: "ui-dropown-selection",
  drag: "ui-drag",
};

const events: EventDetail[] = [
  ...generalEvents(eventName),
  ...studentDashboardEvents(eventName),
  ...teacherDashboardEvents(eventName),
  ...bulkUploadEvents(eventName),
  ...classPageEvents(eventName),
  ...gradeSheetEvents(eventName),
  ...markingScreenEvents(eventName),
  ...assignmentModalEvents(eventName),
  ...classModalEvents(eventName),
];

// import emitter from "@/config/emitter";

// eventBus = emitter;

// this.eventBus.emit("EVENT_TRIGGER");
// this.eventBus.emit("EVENT_TRIGGER", "");

export default events;
