<template>
  <div
    class="login-container h-screen flex py-20 items-center justify-center w-full px-5 mx-auto max-w-[max-content]"
  >
    <div
      class="rounded-md flex flex-col justify-center items-center pb-14 px-14 pt-5 bg-white w-full"
    >
      <div
        class="flex flex-col w-full items-center justify-center p-4 relative z-[1]"
      >
        <img
          src="@/assets/flohh-logo.svg"
          class="object-contain object-[14px] max-w-[210px] w-full mx-auto my-0"
        />
      </div>
      <Divider />
      <div class="pt-2 text-center flex flex-col">
        <p class="text-flohh-text-title color-flohh-neutral-20 font-bold mb-1">
          Success! You've successfully reset your password.
        </p>
        <label
          class="ml-2 text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20"
        >
          Please login using your account to continue.
        </label>
        <AppButton
          type="submit"
          class="mt-4 max-w-[200px] mx-auto"
          @click="
            (e: Event) => {
              e.preventDefault();
              handleRedirect();
            }
          "
        >
          Proceed To Login
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
// import axios, { AxiosResponse } from "axios";
import Divider from "primevue/divider";
import { icons as AppIcons } from "@/utils/icons";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";

@Component({
  components: {
    AppButton,
    Divider,
  },
})
export default class ResetSuccessView extends Vue {
  //
  isTrue = true;

  hasIcon = false;
  icon = AppIcons;

  handleRedirect() {
    const redirectPath = this.$route.query.redirectPath;

    if (redirectPath === "student") {
      this.$router.push("student/login");
    } else {
      this.$router.push("/login");
    }
  }
}
</script>

<style scoped lang="scss"></style>
