<template>
  <div class="mb-[30px] w-[100%] xl:w-[75%] 3xl:w-[50%]">
    <div
      style="position: relative; padding-bottom: 47.39583333333333%; height: 0"
    >
      <video
        ref="videoPlayer"
        width="100%"
        controls
        @timeupdate="updateCurrentTime"
        @ended="handleVideoDuration"
      >
        <source src="@/assets/onboarding_video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import AppButton from "../Layout/Buttons/AppButton.vue";
import { icons as AppIcons, icons } from "@/utils/icons";
import { StorageDataKey } from "@/enums/enums";
import AppTypographyText from "../Layout/Typhography/AppTypographyText.vue";
import DashboardService from "@/services/DashboardService";
import axios, { AxiosResponse } from "axios";
import emitter from "@/config/emitter";
@Component({
  components: {
    AppButton,
    AppTypographyText,
  },
})
export default class OnboardingVideoComponent extends Vue {
  eventBus = emitter;
  //

  currentTime = 0;
  totalDuration = 0;
  mounted() {
    //
  }

  beforeUnmount() {
    this.handleVideoDuration();
  }
  updateCurrentTime() {
    const video: any = this.$refs.videoPlayer;
    if (video) {
      this.currentTime = video.currentTime;
      this.totalDuration = video.duration;
    }
  }

  handleVideoDuration() {
    if (this.currentTime > 0) {
      const data = {
        watchedDuration: this.currentTime,
        totalDuration: this.totalDuration,
      };
      this.eventBus.emit("CUSTOM_EVENT_TRIGGER", {
        id: "TD005",
        name: "ui-view",
        description: JSON.stringify(data),
      });
      console.log(data);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
