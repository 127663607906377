import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: ""
}
const _hoisted_2 = {
  key: 1,
  class: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AuthenticationView = _resolveComponent("AuthenticationView")!
  const _component_AppLayout = _resolveComponent("AppLayout")!
  const _component_Toast = _resolveComponent("Toast")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isAuthScreen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_AuthenticationView, { view: _ctx.authView }, {
            default: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            _: 1
          }, 8, ["view"])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isAuthScreen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_AppLayout, null, {
            default: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.environment !== 'production')
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "version-container",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.redirectToVersionPage && _ctx.redirectToVersionPage(...args)))
        }, [
          _createElementVNode("p", null, _toDisplayString(_ctx.environment) + " - " + _toDisplayString(_ctx.version) + " - " + _toDisplayString(_ctx.releaseDate) + " - " + _toDisplayString(_ctx.commit), 1)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_Toast, { "base-z-index": 10000 })
  ], 64))
}