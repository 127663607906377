<script lang="ts">
import { Vue, Component, Prop, Emit } from "vue-facing-decorator";

type Selection = {
  name: string;
  type: string;
  image: string;
  current: boolean;
  imagePosition: string;
};

@Component({ components: {} })
export default class SelectionCard extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  selection!: Selection;

  @Emit("onClickSelection")
  handleClickSelectManual() {
    return this.selection;
  }
}
</script>
<template>
  <div
    @click="handleClickSelectManual"
    :class="[
      {
        'bg-flohh-light-yellow': !selection.current,
        'bg-flohh-secondary-yellow': selection.current,
      },
      'bg-flohh-light-yellow h-[180px] w-[185px] rounded-lg relative flex justify-center items-center cursor-pointer',
    ]"
  >
    <img
      :class="[
        'absolute',
        selection.imagePosition === 'tl' ? 'top-0 left-0' : '',
        selection.imagePosition === 'br' ? 'bottom-0 right-0' : '',
      ]"
      :src="require(`@/assets/${selection.image}`)"
    />
    <div
      class="w-full h-20 font-semibold flex justify-center items-center px-4 text-center"
    >
      <p class="mx-auto capitalize">{{ selection.name }}</p>
    </div>
  </div>
</template>
