// SetUser.ts
// Mutation that is used when a SetUser is successful

import { UserState, UserInfo } from "../types";

export const SetUser = (state: UserState, params: UserInfo) => {
  console.debug(`[mutation] SetUser :`, params);
  // update the lastModified value so we know when we changed something
  state.user = params;
};
