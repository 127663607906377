import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full bg-white px-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AssignmentInformation = _resolveComponent("AssignmentInformation")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_AssignmentFeedbackForm = _resolveComponent("AssignmentFeedbackForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormField, {
      class: "mb-6",
      label: "Assignment Information",
      required: false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_AssignmentInformation, {
          class: "mt-5",
          assignment: _ctx.assignment
        }, null, 8, ["assignment"])
      ]),
      _: 1
    }),
    _createVNode(_component_AssignmentFeedbackForm, {
      bank: _ctx.feedbackModel.commentBank,
      "onUpdate:bank": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.feedbackModel.commentBank) = $event)),
      grading: _ctx.feedbackModel.grading,
      "onUpdate:grading": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.feedbackModel.grading) = $event)),
      onOnClickSkipOrGoBack: _ctx.handleGoBackOrSkip,
      onOnClickSaveFeedback: _ctx.handleClickSaveFeedBack,
      assignmentUuid: _ctx.assignment.assignmentDetails.uuid,
      isEdit: _ctx.isEdit
    }, null, 8, ["bank", "grading", "onOnClickSkipOrGoBack", "onOnClickSaveFeedback", "assignmentUuid", "isEdit"])
  ]))
}