import { ActionContext } from "vuex";
import * as mutations from "../mutation-types";
import { MarkingInfo, MarkingState } from "../types";

export async function SaveMarking(
  context: ActionContext<MarkingState, unknown>,
  params: MarkingInfo
) {
  context.commit(mutations.SAVE_MARKING, params);
}
