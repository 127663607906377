import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuthSelectionComponent = _resolveComponent("AuthSelectionComponent")!
  const _component_LoginComponent = _resolveComponent("LoginComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isSelectionView)
      ? (_openBlock(), _createBlock(_component_AuthSelectionComponent, {
          key: 0,
          title: _ctx.title,
          updateView: _ctx.updateView
        }, null, 8, ["title", "updateView"]))
      : _createCommentVNode("", true),
    (!_ctx.isSelectionView)
      ? (_openBlock(), _createBlock(_component_LoginComponent, {
          key: 1,
          updateView: _ctx.updateView
        }, null, 8, ["updateView"]))
      : _createCommentVNode("", true)
  ], 64))
}