import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white px-2 py-2 pb-2" }
const _hoisted_2 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AssignmentInformation = _resolveComponent("AssignmentInformation")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_AssignmentAdvanceSettingsForm = _resolveComponent("AssignmentAdvanceSettingsForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FormField, {
        class: "mb-6",
        label: "Assignment Information",
        required: false
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AssignmentInformation, {
            class: "mt-5",
            assignment: _ctx.assignment
          }, null, 8, ["assignment"])
        ]),
        _: 1
      }),
      _createVNode(_component_AssignmentAdvanceSettingsForm, {
        modelValue: _ctx.settingsModel,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.settingsModel) = $event)),
        loadingSaving: _ctx.loadingUpdateAssignment,
        onOnClickAdvanceSetting: _ctx.handleClickSaveAdvanceSettings,
        onOnClickSkipOrGoBack: _ctx.handleGoBackOrSkip
      }, null, 8, ["modelValue", "loadingSaving", "onOnClickAdvanceSetting", "onOnClickSkipOrGoBack"])
    ])
  ]))
}