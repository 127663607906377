<template>
  <div class="relative tooltip-container">
    <div
      v-if="tooltip"
      :class="`custom-tooltiptext ${
        position === 'right' && tooltip.length > 50 ? 'tooltip-lg' : ''
      }`"
    >
      {{ tooltip }}
    </div>
    <div :class="ellipsis ? 'ellipsis' : ''">
      <slot name="body"></slot>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-facing-decorator";
@Component({
  components: {},
})
export default class CustomTooltip extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  tooltip!: string;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  ellipsis!: boolean;

  @Prop({
    type: String,
    required: false,
    default: "left",
  })
  position!: string;
}
</script>

<style scoped lang="scss">
.custom-tooltiptext {
  width: auto;
  visibility: hidden;
  background-color: #495057;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 10px 18px;
  position: absolute;
  z-index: 10;
  bottom: 120%;
  font-size: 0.8em;
  white-space: nowrap;
  left: 0;
  margin-left: 1%;
}

.custom-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 20%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #495057 transparent transparent transparent;
}

.tooltip-container:hover .custom-tooltiptext {
  visibility: visible;
}

.tooltip-lg {
  left: -90% !important;
}

.tooltip-lg::after {
  left: 60%;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
