<template>
  <ResetPasswordComponent />
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import ResetPasswordComponent from "@/components/Authentication/ResetPassword/ResetPasswordComponent.vue";

@Component({
  components: {
    ResetPasswordComponent,
  },
})
export default class ResetPasswordView extends Vue {
  title = "Reset Password";
}
</script>

<style scoped lang="scss"></style>
