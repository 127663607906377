<template>
  <div
    class="rounded-t-[4px] bg-flohh-primary-pink relative p-[20px] h-28 flex justify-start items-center"
  >
    <div class="z-[1] flex flex-col">
      <p class="font-flohh-font-bold text-flohh-h6 text-flohh-neutral-20">
        {{ header }}
      </p>

      <p
        v-if="subHeader"
        class="font-flohh-font-medium text-flohh-text-body text-flohh-neutral-20"
      >
        {{ subHeader }}
      </p>
    </div>

    <img
      src="../../assets/modal-header.png"
      class="absolute top-0 left-0 z-0 h-full w-full"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";

@Component({
  components: {},
})
export default class HeaderComponent extends Vue {
  @Prop({
    type: String,
  })
  header!: string;
  @Prop({
    type: String,
  })
  subHeader?: string;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
