<template>
  <DataTable :value="students" removableSort tableStyle="min-width: 50rem">
    <Column field="code" header="Code" sortable style="width: 25%"></Column>
    <Column field="name" header="Name" sortable style="width: 25%"></Column>
    <Column
      field="category"
      header="Category"
      sortable
      style="width: 25%"
    ></Column>
    <Column
      field="quantity"
      header="Quantity"
      sortable
      style="width: 25%"
    ></Column>
  </DataTable>
</template>

<script lang="ts">
import { AssignmentSubmissionSummaryStudents } from "@/store/assignment/assignmentTypes";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import { Vue, Component, Prop } from "vue-facing-decorator";
@Component({
  components: {
    DataTable,
    Column,
  },
})
export default class ClassAssignmentStudentsTable extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  students!: AssignmentSubmissionSummaryStudents[];
}
</script>

<style scoped></style>
