import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4df98208"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "bg-white rounded-l-lg p-8 w-full min-h-[80vh] flex items-center justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_AssignmentFormComponent = _resolveComponent("AssignmentFormComponent")!
  const _component_ProgressLoader = _resolveComponent("ProgressLoader")!
  const _component_PageViewContainer = _resolveComponent("PageViewContainer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      title: "Edit Assignment",
      routes: _ctx.routes
    }, null, 8, ["routes"]),
    _createVNode(_component_PageViewContainer, { hasSidebar: true }, {
      default: _withCtx(() => [
        (_ctx.assignmentUuid)
          ? (_openBlock(), _createBlock(_component_AssignmentFormComponent, {
              key: 0,
              isEdit: true,
              assignmentUuid: _ctx.assignmentUuid
            }, null, 8, ["assignmentUuid"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_ProgressLoader, { size: "lg" })
            ]))
      ]),
      _: 1
    })
  ], 64))
}