import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "p-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChangeLogComponent = _resolveComponent("ChangeLogComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "p-5"
      }, [
        _createVNode(_component_ChangeLogComponent, {
          title: item.title,
          date: item.date,
          added: item.added,
          fixed: item.fixed,
          changed: item.changed
        }, null, 8, ["title", "date", "added", "fixed", "changed"])
      ]))
    }), 128))
  ]))
}