<template>
  <div
    class="w-[1200px] bg-[url('@/assets/feedback-header.png')] bg-flohh-primary-pink flex rounded-t-[4px]"
    :style="{ color: 'rgba(0, 0, 0, 1)' }"
  >
    <div class="z-[1] text-left p-[16px] flex justify-between w-full">
      <div class="gap-[5px] flex flex-col">
        <p class="font-extrabold text-[18px]">{{ title }}</p>
        <div class="flex gap-[20px]">
          <p>Class: {{ subjectClass }}</p>
          <p>Student Name: {{ studentName }}</p>
        </div>
        <div class="flex gap-[20px]">
          <p>Due Data: {{ dueDate }}</p>
          <p>Due By: {{ dueTime }}</p>
          <p>Submitted: {{ submittedDate }}</p>
        </div>
      </div>
      <div class="flex gap-[20px] justify-end items-center pr-[30px]">
        <Button
          type="button"
          label="View Instructions"
          icon="pi pi-eye"
          class="bg-blue-dark"
        />
        <Button
          type="button"
          label="Download"
          icon="pi pi-download"
          class="bg-blue-dark"
          @click="openPdf"
        />
        <a
          :href="pdfLink"
          class="hidden"
          target="_blank"
          download="asignment.pdf"
          ref="pdfDownloadRef"
        ></a>
        <Button
          type="button"
          label="Print"
          icon="pi pi-print"
          class="bg-blue-dark"
          @click="openPdf"
        />
      </div>
    </div>

    <div class="btn-close-wrap absolute top-[10px] right-[10px] z-[1]">
      <button class="p-2 text-white rounded-full" @click="handleClickClose">
        <img src="@/assets/close.svg" class="w-4" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import Button from "primevue/button";

@Component({ components: { Button } })
export default class LearningGoalsHeader extends Vue {
  @Prop({
    type: Function,
    required: false,
  })
  handleClickClose!: () => void;

  title = "English Composition";
  subjectClass = "English 5-A";
  studentName = "Janice Tran";
  dueDate = "25/10/22";
  dueTime = "4:00pm";
  submittedDate = "22/10/22 | 5:53pm";

  pdfLink =
    "https://res.cloudinary.com/dzafs9h3x/image/upload/v1693472367/asignment-coversheet_vo1lzn.pdf";

  handlePrint() {
    window.print();
  }

  openPdf() {
    let fileInputElement = this.$refs.pdfDownloadRef as HTMLInputElement;
    fileInputElement.click();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
