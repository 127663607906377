import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-row gap-x-4" }
const _hoisted_2 = { class: "px-1 flex justify-center items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormField, {
      type: "text",
      label: _ctx.index === 0 ? 'First Name' : '',
      placeholder: "Student First Name",
      modelValue: _ctx.student.firstName,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.student.firstName) = $event)),
      error: _ctx.error && _ctx.error.firstName ? _ctx.error.firstName : '',
      required: ""
    }, null, 8, ["label", "modelValue", "error"]),
    _createVNode(_component_FormField, {
      type: "text",
      label: _ctx.index === 0 ? 'Last Name' : '',
      placeholder: "Student Last Name",
      modelValue: _ctx.student.lastName,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.student.lastName) = $event)),
      error: _ctx.error && _ctx.error.lastName ? _ctx.error.lastName : '',
      required: ""
    }, null, 8, ["label", "modelValue", "error"]),
    _createVNode(_component_FormField, {
      type: "text",
      label: _ctx.index === 0 ? 'Student ID' : '',
      placeholder: "Student ID",
      modelValue: _ctx.student.studentId,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.student.studentId) = $event)),
      error: _ctx.error && _ctx.error.studentId ? _ctx.error.studentId : '',
      required: ""
    }, null, 8, ["label", "modelValue", "error"]),
    _createVNode(_component_FormField, {
      type: "text",
      label: _ctx.index === 0 ? 'Email' : '',
      placeholder: "Student Email",
      modelValue: _ctx.student.email,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.student.email) = $event)),
      error: _ctx.error && _ctx.error.email ? _ctx.error.email : '',
      required: ""
    }, null, 8, ["label", "modelValue", "error"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.studentCount === 1)
        ? (_openBlock(), _createElementBlock("i", {
            key: 0,
            class: _normalizeClass([_ctx.index === 0 ? 'mt-11' : '', "pi pi-times-circle text-lg bg-gray-200 rounded-full cursor-not-allowed"])
          }, null, 2))
        : (_openBlock(), _createElementBlock("i", {
            key: 1,
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleClickRemoveStudent && _ctx.handleClickRemoveStudent(...args))),
            class: _normalizeClass([_ctx.index === 0 ? 'mt-11' : '', "pi pi-times-circle text-lg bg-gray-200 rounded-full cursor-pointer"])
          }, null, 2))
    ])
  ]))
}