import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex mb-0 list-none flex-wrap flex-row" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
      return (_openBlock(), _createElementBlock("li", {
        class: "-mb-px flex-1 mr-1 last:mr-0 text-center py-1 min-w-[100px]",
        key: index
      }, [
        _createElementVNode("a", {
          class: _normalizeClass(["text-xs capitalize flex-1 py-1 shadow-lg rounded block leading-normal", {
          ' bg-flohh-primary-pink': _ctx.openTabIndex != index,
          'bg-flohh-secondary-blue': _ctx.openTabIndex == index,
          'cursor-pointer': !_ctx.disabled,
        }]),
          onClick: ($event: any) => (_ctx.toggleTabs(index))
        }, _toDisplayString(tab), 11, _hoisted_2)
      ]))
    }), 128))
  ]))
}