<script lang="ts">
import InputText from "primevue/inputtext";
import { Vue, Component, Model, Prop } from "vue-facing-decorator";
@Component({ components: { InputText } })
export default class InputTextComponent extends Vue {
  @Prop({
    type: String,
    default: "text",
  })
  type!: string;

  @Prop({
    type: String,
    default: "small",
  })
  size!: "small" | "large";

  @Prop({
    type: String,
    default: "Input text",
  })
  placeholder!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  invalid!: boolean;

  @Prop({
    type: String,
    default: "input",
  })
  name!: string;

  @Model({
    type: String,
    default: "",
  })
  value!: string;
}
</script>
<template>
  <InputText
    :type="type"
    v-model="value"
    :size="size"
    :placeholder="placeholder"
    :name="name"
    :class="[
      'w-full',
      {
        'p-invalid': invalid,
      },
    ]"
  />
</template>
