import { BulkUploadState } from "../bulkUploadStateTypes";

export const SetMergeAssignment = (
  state: BulkUploadState,
  bulkIndex: number
) => {
  const toMergeAssignment = structuredClone(
    state.bulkUploads[bulkIndex + 1].assignments
  );
  const studentId = state.bulkUploads[bulkIndex].student;
  const assignment = structuredClone(state.bulkUploads[bulkIndex].assignments);
  const mergedAssignments = [...assignment, ...toMergeAssignment];
  state.bulkUploads.splice(bulkIndex, 1);
  state.bulkUploads[bulkIndex].assignments = mergedAssignments;
  state.bulkUploads[bulkIndex].student = studentId;
};
