import axios from "axios";

const WANDE_BASE_URL = "/super-admin/wonde";

export default class WandeService {
  getSchools(page: number) {
    return axios.get(`${WANDE_BASE_URL}/schools?page=${page}`);
  }
  getSchoolTeachers(id: string, page: number) {
    return axios.get(`${WANDE_BASE_URL}/schools/${id}/teachers?page=${page}`);
  }
  getActivatedSchoolTeachers(id: string) {
    return axios.get(`${WANDE_BASE_URL}/schools/${id}/activated-teachers`);
  }
  activate(uuid: string, payload: any) {
    return axios.post(`${WANDE_BASE_URL}/schools/${uuid}/import`, payload);
  }
  updateActivatedTeacher(uuid: string, payload: any) {
    return axios.post(`${WANDE_BASE_URL}/schools/${uuid}/update-data`, payload);
  }
}
