<script lang="ts">
import { Country, State } from "@/utils/coutries/type";
import { Vue, Component, Model, Prop, Watch } from "vue-facing-decorator";
import Countries from "@/utils/coutries/countriesStates.json";
import Dropdown from "primevue/dropdown";

@Component({ components: { Dropdown } })
export default class SelectState extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: String,
    default: "",
  })
  country!: string;

  @Model({
    type: String,
    required: true,
  })
  value!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  required!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  invalid!: boolean;

  countries: Country[] = Countries as Country[];
  filteredStates: State[] | undefined = [];

  formatCountrData(selectedCountry?: string | undefined | null) {
    const countryValue = selectedCountry ? selectedCountry : this.country;
    if (countryValue) {
      this.filteredStates = this.countries.find(
        (country) => country.name === countryValue
      )?.states;

      return;
    }

    this.filteredStates = [];
  }

  mounted() {
    this.formatCountrData();
  }

  @Watch("country")
  countryWatcher(value: string) {
    this.value = "";
    this.formatCountrData(value);
  }
}
</script>
<template>
  <Dropdown
    v-model="value"
    :options="filteredStates"
    optionLabel="name"
    optionValue="name"
    :filter="true"
    :virtualScrollerOptions="{ itemSize: 46 }"
    placeholder="Select a State"
    filterPlaceholder="Search state"
    :class="['w-full', { 'p-invalid': invalid && required }]"
  />
</template>
