import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { CreateClassState } from "../types";

const getters: GetterTree<CreateClassState, RootState> = {
  classInformation(state) {
    return state.classInfo;
  },
};

export default getters;
