import { EventDetail, EventName } from "../type";

const studentDashboardEvents = (eventName: EventName): EventDetail[] => [
  {
    id: "SD001",
    name: eventName.view,
    description: "View assignment",
  },
  {
    id: "SD002",
    name: eventName.view,
    description: "Open upload assignment modal",
  },
  {
    id: "SD003",
    name: eventName.button,
    description: "Download assignment",
  },
  {
    id: "SD004",
    name: eventName.button,
    description: "Submit learning goal",
  },
];

export default studentDashboardEvents;
