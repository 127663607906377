<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-facing-decorator";
import { icons as AppIcons } from "@/utils/icons";
import { Navigation } from "@/utils/navigations";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import NavigationClassList from "@/components/Classes/navigation/NavigationClassList.vue";
import AppTypographyText from "../Typhography/AppTypographyText.vue";
import SidePanelMenus from "@/components/Layout/Partials/SidePanelMenus.vue";

@Component({
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    NavigationClassList,
    AppTypographyText,
    SidePanelMenus,
  },
})
export default class SidePanelNavigation extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  navigation!: Navigation[];

  roles!: string[];

  icons = AppIcons;
  isSideNavHovered = false;

  selectedNavigation!: string;
  visible = false;
  navigateTo = "#";
  isSideNavExpanded = false;

  @Watch("$route")
  routerWatcher(value: any) {
    if (value) {
      this.selectedNavigation = value.name;
      this.visible = !this.visible;
    }
  }

  mounted() {
    const auth = localStorage.getItem("auth");
    if (auth) {
      const roles = JSON.parse(auth).data.roles;
      if (roles.length > 0) {
        this.navigateTo = roles[0].includes("student")
          ? "/student/dashboard"
          : "/dashboard";
        this.roles = roles;
      }
    }
  }

  handleMouseEnter() {
    this.isSideNavHovered = true;
  }

  handleMouseLeave() {
    this.isSideNavHovered = false;
  }

  handleToggleSideNav(value: boolean) {
    this.isSideNavExpanded = value;
    this.$emit("onNavToggle", value);
  }
}
</script>
<template>
  <div
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
    class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:flex-col animate-nav lg:z-[999] bg-flohh-neutral-100"
  >
    <div
      :class="[
        {
          expanded: isSideNavExpanded,
          collapsed: !isSideNavExpanded,
          'overflow-hidden h-full': isSideNavExpanded,
        },
      ]"
      class="sidenav border-r border-solid border-flohh-neutral-85 px-[8px] pt-[16px] bg-flohh-neutral-100"
    >
      <div
        :class="[
          { 'display-none': !isSideNavExpanded },
          'flex justify-start border-b border-flohh-neutral-85 border-solid pt-[2px] pb-[18px]',
        ]"
      >
        <img
          src="@/assets/flohh-logo-visible.svg"
          class="w-[70%] h-[32px]"
          alt="Flohh Logo"
        />
        <div
          v-if="isSideNavHovered"
          class="cursor-pointer w-[32px] h-[32px] border rounded-lg bg-flohh-neutral-95 hover:bg-flohh-neutral-85 flex items-center justify-center mx-auto"
          v-tooltip="{
            value: 'Hide navigation',
            autoHide: false,
          }"
          @click="() => handleToggleSideNav(false)"
        >
          <span v-html="icons.BarArrowLeftBlack" />
        </div>
      </div>

      <div
        :class="[
          { 'display-none': isSideNavExpanded },
          'border-b border-flohh-neutral-85 border-solid pt-[2px] pb-[18px]',
        ]"
      >
        <img
          :class="[
            { 'display-none': isSideNavHovered },
            'w-[100%] h-[32px] mx-auto',
          ]"
          src="@/assets/flohh-logo-hidden.svg"
          alt="Flohh Logo"
        />
        <div
          :class="[
            { 'display-none': !isSideNavHovered },
            'cursor-pointer rotate-180 w-[32px] h-[32px] border rounded-lg bg-flohh-neutral-95 hover:bg-flohh-neutral-85 flex items-center justify-center mx-auto',
          ]"
          v-tooltip="{
            value: 'Show navigation',
            autoHide: false,
          }"
          @click="() => handleToggleSideNav(true)"
        >
          <span v-html="icons.BarArrowLeftBlack" />
        </div>
      </div>

      <SidePanelMenus
        :navigation="navigation"
        :roles="roles"
        :expanded="isSideNavExpanded"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.animate-nav {
  transition: 100ms linear;
}
.app-info {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3px;
  p {
    font-size: 10px;
  }
}

.sidenav {
  transition: width 0.5s ease;
  height: 100%;
}

.sidenav.collapsed {
  width: 64px;
}

.sidenav.expanded {
  width: 244px;
}

.display-none {
  display: none;
}
</style>
