import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52a49cf0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "changelog-container" }
const _hoisted_2 = { class: "head" }
const _hoisted_3 = { class: "body" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "ml-10" }
const _hoisted_6 = {
  key: 1,
  class: "mt-5"
}
const _hoisted_7 = { class: "ml-10" }
const _hoisted_8 = {
  key: 2,
  class: "mt-5"
}
const _hoisted_9 = { class: "ml-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, [
        _createElementVNode("strong", null, _toDisplayString(_ctx.title) + " (" + _toDisplayString(_ctx.date) + ")", 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.added.length > 0)
        ? (_openBlock(), _createElementBlock("h4", _hoisted_4, "Added"))
        : _createCommentVNode("", true),
      _createElementVNode("ul", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.added, (item, index) => {
          return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(item), 1))
        }), 128))
      ]),
      (_ctx.changed.length > 0)
        ? (_openBlock(), _createElementBlock("h4", _hoisted_6, "Changed"))
        : _createCommentVNode("", true),
      _createElementVNode("ul", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.changed, (item, index) => {
          return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(item), 1))
        }), 128))
      ]),
      (_ctx.fixed.length > 0)
        ? (_openBlock(), _createElementBlock("h4", _hoisted_8, "Fixed"))
        : _createCommentVNode("", true),
      _createElementVNode("ul", _hoisted_9, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fixed, (item, index) => {
          return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(item), 1))
        }), 128))
      ])
    ])
  ]))
}