<template>
  <div class="flex justify-end gap-2">
    <AppButton
      type="transparent"
      @click="
        (e: Event) => {
          e.preventDefault();
          $router.back();
        }
      "
    >
      <span v-html="icons.arrowBackBlack" class="mr-2"></span>
      Go Back
    </AppButton>

    <AppButton type="submit" :loading="isLoading" :disabled="!isVerified">
      <template #icon_left>
        <span v-html="icons.checkBlack" class="pr-2"></span>
      </template>
      {{ isLoading ? "Loading" : "Submit" }}
    </AppButton>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import ButtonComponent from "@/components/Button/ButtonComponent.vue";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons as AppIcons } from "@/utils/icons";

@Component({
  components: {
    ButtonComponent,
    AppButton,
  },
})
export default class RegistrationActionComponent extends Vue {
  icons = AppIcons;

  @Prop({
    type: Boolean,
    required: true,
  })
  public isVerified!: false;

  @Prop({
    type: Boolean,
    required: true,
  })
  public isLoading!: false;

  @Prop({
    type: String,
    required: true,
  })
  public title!: string;

  @Prop({
    type: Function,
    required: false,
  })
  public handleView!: () => void;

  @Prop({
    type: Function,
    required: false,
  })
  public handleSubmit!: () => void;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.registration-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .btn-back {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999;
    font-weight: 700;
    line-height: 23px;
    transition: 0.3s ease;
    max-width: initial;
    &:hover {
      opacity: 0.5;
    }
  }
  button {
    max-width: 124px;
    font-weight: 700;
  }
}
</style>
