<template>
  <div class="modal-btn-wrapper">
    <AppButton
      label="Save"
      :disabled="isNextDisabled"
      whiteLabel
      v-if="buttonType === 'single'"
      @click="handleFormStep('next')"
    >
      <template #icon_right>
        <img src="../../assets/chev-right.svg" />
      </template>
    </AppButton>
    <div class="flex justify-around items-center w-full" v-else>
      <button class="modal-text-btn" @click="handleFormStep('previous')">
        <img src="../../assets/chev-left.svg" />
        Go back
      </button>
      <div class="flex">
        <button
          class="modal-text-btn mr-[10px]"
          @click="handleFormStep('close')"
        >
          Skip this step
        </button>
        <button
          :disabled="isNextDisabled"
          class="modal-default-btn"
          @click="handleFormStep('next')"
        >
          Save
          <img src="../../assets/chev-right.svg" class="w-full h-full" />
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import { TButtonAction } from "./types";
import AppButton from "../Layout/Buttons/AppButton.vue";

@Component({
  components: { AppButton },
})
export default class FooterComponent extends Vue {
  @Prop({
    type: String,
  })
  buttonType!: "single" | "multiple";
  @Prop({
    type: Boolean,
  })
  isNextDisabled?: boolean;
  @Prop({
    type: Function,
  })
  handleFormStep!: (action: TButtonAction) => void;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.modal-btn-wrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 24px;
  font-weight: 700;
}

.modal-default-btn {
  margin-top: 20px;
  background-color: rgba(145, 206, 225, 1);
  padding: 14px 14px 14px 26px;
  display: flex;
  color: white;
  border-radius: 4px;
  transition: 0.3s ease;

  &:hover {
    opacity: 0.6;
  }

  &:disabled {
    background-color: rgba(153, 153, 153, 1);
    pointer-events: none;
  }
}

.modal-text-btn {
  margin-top: 20px;
  padding: 14px 14px 14px 26px;
  display: flex;
  color: rgba(153, 153, 153, 1);
  transition: 0.3s ease;

  &:hover {
    opacity: 0.6;
  }

  &:disabled {
    background-color: rgba(153, 153, 153, 1);
    pointer-events: none;
  }
}
</style>
