import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "w-full" }
const _hoisted_3 = { class: "w-full flex flex-col justify-start items-start h-[34px] gap-y-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _mergeProps({ class: "w-full flex gap-x-2 p-1" }, _ctx.$attrs), [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass([
        'flex-1 flex',
        {
          'cursor-pointer': !_ctx.disabled,
        },
      ]),
        key: index,
        onClick: ($event: any) => (_ctx.handleClickTab(index))
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: _normalizeClass([
              'w-[24px] h-[24px] rounded-full flex justify-center items-center text-flohh-text-caption font-flohh-font-medium ',
              index === _ctx.model || (_ctx.allowHistory && _ctx.tabHistory.includes(index))
                ? 'text-flohh-neutral-20 bg-flohh-primary-pink'
                : 'text-flohh-neutral-70 border border-solid border-flohh-neutral-70',
            ])
            }, _toDisplayString(step.stepper ?? index + 1), 3),
            _createElementVNode("div", {
              class: _normalizeClass([
              'border-b border-solid w-full ',
              index === _ctx.model || (_ctx.allowHistory && _ctx.tabHistory.includes(index))
                ? 'border-flohh-primary-pink'
                : 'border-flohh-neutral-70',
            ])
            }, null, 2)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass([
            'w-full text-flohh-text-body font-flohh-font-bold px-1 py-[8px]',
            index === _ctx.model || (_ctx.allowHistory && _ctx.tabHistory.includes(index))
              ? 'text-flohh-neutral-5'
              : 'text-flohh-neutral-70',
          ])
          }, _toDisplayString(step.name), 3),
          _createElementVNode("div", {
            class: _normalizeClass([
            'font-flohh-font-regular text-flohh-text-small px-1',
            index === _ctx.model || (_ctx.allowHistory && _ctx.tabHistory.includes(index))
              ? 'text-flohh-neutral-5'
              : 'text-flohh-neutral-70',
          ])
          }, _toDisplayString(step.descriptions), 3)
        ])
      ], 10, _hoisted_1))
    }), 128))
  ], 16))
}