<template>
  <HeaderComponent
    :header="'Add your students'"
    :subHeader="'Select one of the options below to add students to your class'"
  />

  <div class="flex flex-col gap-[24px] w-full px-[20px] py-[24px]">
    <div class="flex flex-col items-start gap-[24px]">
      <h4
        class="font-bold text-[18px]"
        :style="{
          color: 'rgba(51, 51, 51, 1)',
        }"
      >
        Class Information
      </h4>

      <div class="flex w-full">
        <div class="flex flex-col px-[24px] w-[50%] items-start">
          <div class="font-[700] text-[16px]">Class Name</div>
          <div>{{ subjectClassName }}</div>
        </div>
        <div class="flex flex-col px-[24px] w-[50%] items-start">
          <div class="font-[700] text-[16px]">Subject</div>
          <div>{{ subject }}</div>
        </div>
      </div>
    </div>

    <div
      class="py-[24px] flex flex-col items-start justify-start text-left gap-[24px]"
    >
      <h4
        class="font-bold text-[14px]"
        :style="{
          backgroundColor: 'rgba(255, 255, 255, 1)',
          color: 'rgba(51, 51, 51, 1)',
        }"
      >
        Add your students
        <span
          class="font-normal"
          :style="{
            color: 'rgba(153, 153, 153, 1)',
          }"
          >(Optional)</span
        >
      </h4>

      <div class="flex justify-between items-center w-full">
        <AddStudentBlock
          :imagePosition="'bottom'"
          :image="'rectangle-green.svg'"
          :addStudentType="addStudentType"
          :handleAddStudentType="handleAddStudentType"
          :type="'googleClassroom'"
        >
          Import Students from Google Classroom
        </AddStudentBlock>
        <span class="italic">or</span>
        <AddStudentBlock
          :imagePosition="'bottom'"
          :image="'rectangle-pink.svg'"
          :type="'link'"
          :handleAddStudentType="handleAddStudentType"
          :addStudentType="addStudentType"
        >
          Invite Students to this Class through a Link
        </AddStudentBlock>
        <span class="italic">or</span>
        <AddStudentBlock
          :type="'upload'"
          :imagePosition="'top'"
          :image="'rectangle-red.svg'"
          :handleAddStudentType="handleAddStudentType"
          :addStudentType="addStudentType"
          >Upload Student’s Information
        </AddStudentBlock>
        <span class="italic">or</span>
        <AddStudentBlock
          :type="'manual'"
          :imagePosition="'top'"
          :image="'rectangle-blue.svg'"
          :handleAddStudentType="handleAddStudentType"
          :addStudentType="addStudentType"
        >
          Add students information manually
        </AddStudentBlock>
      </div>
    </div>
  </div>

  <FooterComponent
    :buttonType="'multiple'"
    :isNextDisabled="!addStudentType"
    :handleFormStep="handleFormStep"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";

import AddStudentBlock from "./AddStudentOptions/AddStudentBlock.vue";
import HeaderComponent from "./HeaderComponent.vue";
import FooterComponent from "./FooterComponent.vue";

@Component({
  components: { AddStudentBlock, HeaderComponent, FooterComponent },
})
export default class Step2Component extends Vue {
  @Prop({
    type: String,
  })
  subject!: string;
  @Prop({
    type: String,
  })
  subjectClassName!: string;
  @Prop({
    type: String,
  })
  addStudentType!: string;
  @Prop({
    type: Function,
  })
  handleFormStep!: (action: string) => void;
  @Prop({
    type: Function,
  })
  handleAddStudentType!: (type: string) => void;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
