import { RootState } from "..";
import { Module } from "vuex";
import * as action from "./actions";
import * as actionName from "./action-names";
import state from "./studentState";
import { StudentState } from "./studentTypes";

const student: Module<StudentState, RootState> = {
  namespaced: true,
  state,
  actions: {
    [actionName.DELETE_STUDENT]: action.DeleteStudentAction,
  },
  mutations: {},
};

export default student;
