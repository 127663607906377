<template>
  <div class="bg-white rounded-lg p-8 relative z-10">
    <div class="flex justify-between items-center">
      <h6 class="text-flohh-h6 font-flohh-font-bold">Classes</h6>
      <CreateClassButton type="submit" icon="plusBlack" />
    </div>
    <div class="py-6">
      <hr />
    </div>
    <ClassesListUtility
      v-model="searchText"
      @onViewChange="handleChangeView"
      :classView="classView"
    />
    <div
      v-if="classesClone.length === 0 && searchText"
      class="justify-center flex items-center h-[200px]"
    >
      <AppTypographyText label="No Class Found" />
    </div>
    <div
      v-else-if="classesClone.length === 0 && !searchText"
      class="justify-center flex items-center h-[200px]"
    >
      <AppTypographyText label="No Available Class" />
    </div>
    <div v-else>
      <div v-if="!assignmentsLoading">
        <ClassesListGridViewComponent
          v-if="classView === 'grid'"
          :classes="classesClone"
          :loadingClasses="loadingClasses"
          :assignments="assignments"
          @onClassEdit="handleEditClass"
          @onStudentInvite="handleInviteStudents"
          @onClassDelete="handleDeleteClass"
        />
        <ClassesListTableViewComponent
          v-else
          :classes="classesClone"
          :loadingClasses="loadingClasses"
          :assignments="assignments"
          @onClassEdit="handleEditClass"
          @onStudentInvite="handleInviteStudents"
          @onClassDelete="handleDeleteClass"
        />
      </div>
      <ProgressLoader v-else />
    </div>

    <InviteStudentPopup
      v-model="openInviteDialog"
      :defaultClass="selectedClassUuid"
    />
    <CreateClassPopup v-model="openClassDialog" :isEdit="true" />

    <Dialog
      :visible="deleteDialogVisible"
      modal
      :style="{ width: '410px' }"
      :closable="false"
      :pt="{
        header: { class: '!py-4 !pl-6' },
        content: { class: '!p-0' },
        footer: { class: '!p-0' },
      }"
    >
      <template #header>
        <div class="">
          <h5
            class="text-default text-neutral-gray-500 flex font-bold items-center justify-start"
          >
            <i v-html="icons.trashBlack" class="mr-2"></i>Delete Class
          </h5>
        </div>
      </template>
      <div class="border-t border-solid border-neutral-gray-500 py-4 px-6">
        <p
          class="font-flohh-text-body text-neutral-gray-500 font-flohh-font-bold"
        >
          Are you sure you want to delete class '{{ classInfo.code }}'?
        </p>
        <br />
        <p class="text-sm text-neutral-gray-500">
          This will delete this class permanently. You cannot undo this action.
        </p>
      </div>
      <template #footer>
        <div class="py-2 px-6 flex items-center justify-end">
          <Button
            label="Cancel"
            @click="handleHideDeleteDialog"
            class="border border-solid border-neutral-gray-70 bg-transparent text-sm text-neutral-gray-70 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5"
          />
          <Button
            label="Delete Class"
            @click="() => handleDelete(classInfo.uuid)"
            class="border border-solid border-neutral-gray-500 bg-neutral-gray-85 text-sm text-neutral-gray-500 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5 !mr-0"
            :disabled="loadingDeleteClass"
            :loading="loadingDeleteClass"
            :class="[loadingDeleteClass ? 'cursor-progress' : '']"
          />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import ClassesListTableViewComponent from "./ClassesListTableViewComponent.vue";
import ClassesListGridViewComponent from "./ClassesListGridViewComponent.vue";
import LoadingState from "../../utilities/LoadingState.vue";
import { ClassData } from "@/store/class/classTypes";
import { icons as AppIcons } from "@/utils/icons";
import ClassesHeaderComponent from "@/components/Classes/ClassesHeaderComponent.vue";
import CreateClassButton from "@/components/CreateClass/CreateClassButton.vue";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import CreateClassPopup from "@/components/CreateClass/CreateClassPopup.vue";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import ClassService from "@/services/ClassService";
import emitter from "@/config/emitter";
import { useToast } from "primevue/usetoast";
import { setClassInformation } from "@/store/class/class-dispatch";
import InviteStudentPopup from "@/components/InviteStudent/InviteStudentPopup.vue";
import ClassesListUtility from "./ClassesListUtility.vue";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import { AxiosResponse } from "axios";
import DashboardService from "@/services/DashboardService";
import { AssignmentDashboard } from "@/store/dashboard/dashboardTypes";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";

@Component({
  components: {
    LoadingState,
    ClassesListTableViewComponent,
    ClassesHeaderComponent,
    ClassesListGridViewComponent,
    CreateClassButton,
    AppButton,
    CreateClassPopup,
    Dialog,
    Button,
    InviteStudentPopup,
    ClassesListUtility,
    AppTypographyText,
    ProgressLoader,
  },
})
export default class ClassesListComponent extends Vue {
  private dashboardService = new DashboardService();
  private classService = new ClassService();

  icons = AppIcons;
  eventBus = emitter;
  toast = useToast();

  @Prop({
    type: Array,
    required: true,
  })
  classes!: ClassData[];

  @Prop({
    type: Boolean,
    required: true,
  })
  loadingClasses!: boolean;

  classView!: string | null; // grid or table

  classInfo!: ClassData;
  loadingDeleteClass = false;
  deleteDialogVisible = false;
  openClassDialog = false;
  openInviteDialog = false;

  classesClone: ClassData[] = [];
  filterResultMessage = "No Class Found";
  selectedClassUuid = "";
  searchText = "";
  assignmentsLoading = true;
  assignments!: AssignmentDashboard[];

  async mounted() {
    const searchParams = new URLSearchParams(window.location.search);
    this.classesClone = this.classes;

    if (searchParams.has("view")) {
      this.classView = searchParams.get("view");
    } else {
      this.classView = "grid";
    }

    this.$router.push({
      name: "ClassesList",
      query: { view: this.classView },
    });
    await this.handleGetAssignments();
  }

  async handleGetAssignments() {
    try {
      this.assignmentsLoading = true;
      const response: AxiosResponse =
        await this.dashboardService.getTeacherDashboard();

      if (response.data.ok) {
        this.assignments = response.data.data;
      } else {
        throw new Error();
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.assignmentsLoading = false;
    }
  }

  handleHideDeleteDialog() {
    this.deleteDialogVisible = false;
  }

  async handleDelete(uuid: string) {
    try {
      const response = await this.classService.deleteClass(uuid);
      if (response.data.ok) {
        this.toast.add({
          severity: "success",
          detail: "Class deleted successfully",
          life: 3000,
        });
      }
      this.handleHideDeleteDialog();
      this.eventBus.emit("LOAD_CLASSES");
    } catch (error) {
      console.error(error);
    }
  }

  async handleEditClass(classInfo: ClassData) {
    await this.$store.dispatch(setClassInformation, classInfo.uuid);
    this.openClassDialog = true;
  }

  handleDeleteClass(classInfo: ClassData) {
    this.deleteDialogVisible = true;
    this.classInfo = classInfo;
  }

  async handleInviteStudents(classInfo: ClassData) {
    this.selectedClassUuid = classInfo.uuid;
    this.openInviteDialog = true;
  }

  handleChangeView(view: string) {
    this.$router.push({
      name: "ClassesList",
      query: { view: view },
    });
    this.classView = view;
  }

  @Watch("searchText")
  watchSearchText(text: string) {
    if (text) {
      this.classesClone = this.classes.filter((classInfo: ClassData) =>
        classInfo.code.toLowerCase().includes(text)
      );
    } else {
      this.classesClone = this.classes;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.actions {
  &-cell {
    left: 0;
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 1em;
    text-align: center;
  }

  &-body {
    background-color: #ffc9ad;
  }
}
.action-icon-container {
  max-height: 24px;
  max-width: 24px;
  margin: 0 auto;
}
</style>
