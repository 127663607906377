<template>
  <PageTitle title="Edit Assignment" :routes="routes" />
  <PageViewContainer :hasSidebar="true">
    <AssignmentFormComponent
      v-if="assignmentUuid"
      :isEdit="true"
      :assignmentUuid="assignmentUuid"
    />
    <div
      v-else
      class="bg-white rounded-l-lg p-8 w-full min-h-[80vh] flex items-center justify-center"
    >
      <ProgressLoader size="lg" />
    </div>
  </PageViewContainer>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import AssignmentFormComponent from "@/components/Assignments/AssignmentFormComponent.vue";
import { setAssignment } from "@/store/assignment/assignment-dispatch";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";
import PageTitle, { TRoute } from "@/components/Layout/Partials/PageTitle.vue";
import PageViewContainer from "@/components/Layout/Partials/PageViewContainer.vue";

@Component({
  components: {
    AssignmentFormComponent,
    ProgressLoader,
    PageTitle,
    PageViewContainer,
  },
})
export default class EditAssignmentView extends Vue {
  routes: TRoute[] = [];
  assignmentUuid = "";
  async created() {
    try {
      if (this.$route.params.id) {
        const assignmentUuid = this.$route.params.id as string;

        await this.$store.dispatch(setAssignment, assignmentUuid);
        this.assignmentUuid = assignmentUuid;

        this.routes = [
          {
            title: "Assignments",
            routeTo: "/assignments/all",
            current: false,
          },
          {
            title: "Edit",
            routeTo: `/assignments/${assignmentUuid}`,
            current: true,
          },
        ];
      }
    } catch (error) {
      this.$router.push({ name: "AssignmentsList" });
      console.error(error);
    }
  }
}
</script>

<style scoped lang="scss">
body {
  margin: 0;
  overflow: hidden !important;
}
.assignments {
  margin-left: 0;
  height: 100vh;
  position: relative;
  background-color: #f1f3f3;
}
</style>
