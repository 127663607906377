<script lang="ts">
import { Vue, Component, Prop } from "vue-facing-decorator";
import { Student } from "../../../store/createClass/types";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import AddStudent from "./AddStudent.vue";

type Error = {
  index?: number;
  firstName?: string;
  lastName?: string;
  studentId?: string;
  email?: string;
};

@Component({ components: { AppButton, AddStudent } })
export default class AddStudentManually extends Vue {
  @Prop({
    type: Array,
  })
  errors!: Error[];

  students: Student[] = [];
  isAddStudent = true;

  handleSetInitialStudent() {
    if (
      this.$store.state?.createClassPopup.addManually.length &&
      this.isAddStudent
    ) {
      this.students = structuredClone(
        this.$store.state?.createClassPopup.addManually
      );
      this.isAddStudent = false;
      return;
    }
    this.isAddStudent = false;
    const student: Student = {
      email: "",
      firstName: "",
      lastName: "",
      studentId: "",
    };
    this.students.push(structuredClone(student));
    this.$store.dispatch(
      "createClassPopup/addStudent",
      structuredClone(student)
    );
  }

  handleClickRemoveStudent(index: number) {
    this.students.splice(index, 1);
    this.$store.dispatch("createClassPopup/removeStudent", index);
  }

  handleOnUpdateListOfStudentsEmit() {
    this.$store.dispatch("createClassPopup/updateStudent", this.students);
  }

  mounted() {
    this.handleSetInitialStudent();
  }
}
</script>
<template>
  <AddStudent
    v-for="(student, index) in students"
    :key="index"
    :index="index"
    :student="student"
    @onRemoveStudent="handleClickRemoveStudent"
    :error="errors.find((error) => error.index === index)"
    :studentCount="students.length"
  />
  <div class="flex justify-start items-center pt-2">
    <AppButton
      @click="handleSetInitialStudent"
      type="primary"
      label="Add more row"
      whiteLabel
    >
      <template #icon_left>
        <img src="../../../assets/icon-plus-white-rounded.svg" />
      </template>
    </AppButton>
  </div>
</template>
