// template.ts
import { generateGuid } from "@/utils/helper";
import { THeadingLayout, TLayout } from "./types";

export const HEADING_LAYOUT: THeadingLayout = [
  {
    x: 0,
    y: 0,
    w: 3,
    h: 1,
    i: "0%",
    static: false,
    isPreventCollision: false,
  },
  {
    x: 3,
    y: 0,
    w: 3,
    h: 1,
    i: "25%",
    static: false,
    isPreventCollision: false,
  },
  {
    x: 6,
    y: 0,
    w: 3,
    h: 1,
    i: "50%",
    static: false,
    isPreventCollision: false,
  },
  {
    x: 9,
    y: 0,
    w: 3,
    h: 1,
    i: "75%",
    static: false,
    isPreventCollision: false,
  },
  {
    x: 12,
    y: 0,
    w: 3,
    h: 1,
    i: "100%",
    static: false,
    isPreventCollision: false,
  },
];

export const LAYOUT: TLayout = [
  [
    {
      x: 0,
      y: 0,
      w: 3,
      h: 2,
      i: generateGuid(),
      o: {
        data: {
          type: "goal",
          done: 1,
          total: 10,
          name: "Assignment 1",
          subject: "testSubject",
          subjectClass: "test class",
        },
        color: "#fff",
        isBlank: false,
      },
      static: false,
    },
    {
      x: 0,
      y: 2,
      w: 3,
      h: 2,
      i: generateGuid(),
      o: {
        data: {
          type: "submission",
          done: 1,
          total: 10,
          name: "Assignment 2",
          subject: "testSubject",
          subjectClass: "test class",
        },
        color: "#fff",
        isBlank: false,
      },
      static: false,
    },
  ],
  [
    {
      x: 0,
      y: 0,
      w: 3,
      h: 2,
      i: generateGuid(),
      o: {
        data: {
          type: "submission",
          done: 1,
          total: 10,
          name: "Assignment 3",
          subject: "testSubject",
          subjectClass: "test class",
        },
        color: "#fff",
        isBlank: false,
      },
      static: false,
    },
    {
      x: 0,
      y: 2,
      w: 3,
      h: 2,
      i: generateGuid(),
      o: {
        data: {
          type: "submission",
          done: 1,
          total: 10,
          name: "Assignment 4",
          subject: "testSubject",
          subjectClass: "test class",
        },
        color: "#fff",
        isBlank: false,
      },
      static: false,
    },
    {
      x: 0,
      y: 4,
      w: 3,
      h: 2,
      i: generateGuid(),
      o: {
        data: {
          type: "submission",
          done: 1,
          total: 10,
          name: "Assignment 5",
          subject: "testSubject",
          subjectClass: "test class",
        },
        color: "#fff",
        isBlank: false,
      },
      static: false,
    },
    // {
    //   x: 0,
    //   y: 2,
    //   w: 3,
    //   h: 2,
    //   i: generateGuid(),
    //   o: {
    //     data: {
    //       name: "Assignment 6",
    //       subject: "testSubject",
    //       subjectClass: "test class",
    //     },
    //     color: "#fff",
    //     isBlank: false,
    //   },
    //   static: false,
    // },
  ],
  [
    // {
    //   x: 0,
    //   y: 0,
    //   w: 3,
    //   h: 2,
    //   i: generateGuid(),
    //   o: {
    //     data: {
    //       type: "submission",
    //       done: 1,
    //       name: "Assignment 7",
    //       subject: "testSubject",
    //       subjectClass: "test class",
    //     },
    //     color: "#fff",
    //     isBlank: false,
    //   },
    //   static: false,
    // },
  ],
  [
    {
      x: 0,
      y: 0,
      w: 3,
      h: 2,
      i: generateGuid(),
      o: {
        data: {
          type: "submission",
          done: 7,
          total: 10,
          name: "Assignment 7",
          subject: "testSubject",
          subjectClass: "test class",
        },
        color: "#fff",
        isBlank: false,
      },
      static: false,
    },
  ],
  [
    {
      x: 0,
      y: 0,
      w: 3,
      h: 2,
      i: generateGuid(),
      o: {
        data: {
          type: "goal",
          done: 10,
          total: 10,
          name: "Assignment 8",
          subject: "testSubject",
          subjectClass: "test class",
        },
        color: "#fff",
        isBlank: false,
      },
      static: false,
    },
  ],
];

export const STUDENT_HEADING_LAYOUT: THeadingLayout = [
  {
    x: 0,
    y: 0,
    w: 3,
    h: 1,
    i: "Assignments Due",
    static: false,
    isPreventCollision: false,
  },
  {
    x: 3,
    y: 0,
    w: 3,
    h: 1,
    i: "Overdue",
    static: false,
    isPreventCollision: false,
  },
  {
    x: 6,
    y: 0,
    w: 3,
    h: 1,
    i: "Submitted",
    static: false,
    isPreventCollision: false,
  },
  {
    x: 9,
    y: 0,
    w: 3,
    h: 1,
    i: "Feedback Returned",
    static: false,
    isPreventCollision: false,
  },
  {
    x: 12,
    y: 0,
    w: 3,
    h: 1,
    i: "Grade Returned",
    static: false,
    isPreventCollision: false,
  },
];

export const STUDENT_LAYOUT: TLayout = [
  [
    {
      x: 0,
      y: 0,
      w: 3,
      h: 2,
      i: generateGuid(),
      o: {
        data: {
          type: "viewAndSubmit",
          name: "Assignment 1",
          subject: "testSubject",
          subjectClass: "test class",
        },
        color: "#fff",
        isBlank: false,
      },
      static: false,
    },
    {
      x: 0,
      y: 2,
      w: 3,
      h: 2,
      i: generateGuid(),
      o: {
        data: {
          type: "viewAndSubmit",
          name: "Assignment 2",
          subject: "testSubject",
          subjectClass: "test class",
        },
        color: "#fff",
        isBlank: false,
      },
      static: false,
    },
    {
      x: 0,
      y: 4,
      w: 3,
      h: 2,
      i: generateGuid(),
      o: {
        data: {
          type: "viewAndSubmit",
          name: "Assignment 3",
          subject: "testSubject",
          subjectClass: "test class",
        },
        color: "#fff",
        isBlank: false,
      },
      static: false,
    },
  ],
  [
    {
      x: 0,
      y: 0,
      w: 3,
      h: 2,
      i: generateGuid(),
      o: {
        data: {
          type: "viewAndSubmit",
          name: "Assignment 4",
          subject: "testSubject",
          subjectClass: "test class",
        },
        color: "#fff",
        isBlank: false,
      },
      static: false,
    },
    {
      x: 0,
      y: 2,
      w: 3,
      h: 2,
      i: generateGuid(),
      o: {
        data: {
          type: "viewAndSubmit",
          name: "Assignment 5",
          subject: "testSubject",
          subjectClass: "test class",
        },
        color: "#fff",
        isBlank: false,
      },
      static: false,
    },
  ],
  [
    {
      x: 0,
      y: 0,
      w: 3,
      h: 2,
      i: generateGuid(),
      o: {
        data: {
          type: "niceWork",
          name: "Assignment 6",
          subject: "testSubject",
          subjectClass: "test class",
        },
        color: "#fff",
        isBlank: false,
      },
      static: false,
    },
  ],
  [
    {
      x: 0,
      y: 0,
      w: 3,
      h: 2,
      i: generateGuid(),
      o: {
        data: {
          type: "feedback",
          name: "Assignment 7",
          subject: "testSubject",
          subjectClass: "test class",
        },
        color: "#fff",
        isBlank: false,
      },
      static: false,
    },
  ],
  [
    {
      x: 0,
      y: 0,
      w: 3,
      h: 2,
      i: generateGuid(),
      o: {
        data: {
          type: "viewGrade",
          name: "Assignment 8",
          subject: "testSubject",
          subjectClass: "test class",
        },
        color: "#fff",
        isBlank: false,
      },
      static: false,
    },
  ],
];
