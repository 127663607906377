import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_ClassesComponent = _resolveComponent("ClassesComponent")!
  const _component_PageViewContainer = _resolveComponent("PageViewContainer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      title: "Class",
      routes: _ctx.routes
    }, null, 8, ["routes"]),
    _createVNode(_component_PageViewContainer, null, {
      default: _withCtx(() => [
        (_ctx.id)
          ? (_openBlock(), _createBlock(_component_ClassesComponent, {
              key: 0,
              classUuid: _ctx.id,
              onOnClassInfoLoaded: _ctx.handleClassInfoLoaded
            }, null, 8, ["classUuid", "onOnClassInfoLoaded"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}