import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInputDropdown = _resolveComponent("FormInputDropdown")!

  return (_openBlock(), _createElementBlock("div", _mergeProps({ class: "w-full" }, _ctx.$attrs), [
    _createVNode(_component_FormInputDropdown, {
      modelValue: _ctx.model,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
      options: _ctx.tabs,
      "option-value": "label",
      optionLabel: "label"
    }, null, 8, ["modelValue", "options"])
  ], 16))
}