<script lang="ts">
import { Vue, Component, Prop, Emit } from "vue-facing-decorator";
import SelectionCard from "./SelectionCard.vue";

type Selection = {
  name: string;
  type: string;
  image: string;
  current: boolean;
  imagePosition: string;
};
@Component({ components: { SelectionCard } })
export default class AddStudentsSelection extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  selections!: Selection[];

  @Emit("onClickSelection")
  handleClickSelection(selection: Selection) {
    return selection;
  }
}
</script>
<template>
  <div class="w-full flex flex-row justify-center items-center gap-x-4 my-5">
    <SelectionCard
      v-for="(selection, index) in selections"
      :key="index"
      :selection="selection"
      @onClickSelection="handleClickSelection"
    />
  </div>
</template>
