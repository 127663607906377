import { ActionContext } from "vuex";
import { AuthProfileState, AuthState } from "../AuthStateTypes";
import * as mutationType from "../mutation-types";

export const SetAuthAction = (
  context: ActionContext<AuthState, unknown>,
  params: AuthProfileState
) => {
  context.commit(mutationType.SET_AUTH, params);
};
