<script lang="ts">
import Dropdown from "primevue/dropdown";
import { Vue, Component, Model, Prop } from "vue-facing-decorator";
import { Country } from "@/utils/coutries/type";
import Countries from "@/utils/coutries/countriesStates.json";

@Component({ components: { Dropdown } })
export default class SelectCountry extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Model({
    type: String,
    required: true,
  })
  value!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  required!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  invalid!: boolean;

  countries: Country[] = Countries as Country[];
}
</script>
<template>
  <Dropdown
    v-model="value"
    :options="countries"
    optionLabel="name"
    optionValue="name"
    :filter="true"
    :virtualScrollerOptions="{ itemSize: 46 }"
    placeholder="Select a Country"
    filterPlaceholder="Search country"
    :class="['w-full', { 'p-invalid': invalid && required }]"
  />
</template>
