import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "max-w-[1200px] overflow-hidden h-[650px] flex text-[14px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PdfPreviewComponent = _resolveComponent("PdfPreviewComponent")!
  const _component_SidePanelComponent = _resolveComponent("SidePanelComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PdfPreviewComponent),
    _createVNode(_component_SidePanelComponent, { isTeacherApproved: _ctx.isTeacherApproved }, null, 8, ["isTeacherApproved"])
  ]))
}