import { createStore } from "vuex";
import user from "./user";
import marking from "./marking";
import comments from "./comments";
import rubricksBank from "@/modules/RubricsBanks/Store";
import createClassPopup from "./createClass";
import auth from "./auth";
import bulkUpload from "./bulkUpload";
import teacherClass from "./class";
import assignment from "./assignment";
import dashboard from "./dashboard";
import student from "./student";

export interface RootState {
  user: typeof user.state;
  marking: typeof marking.state;
  comments: typeof comments.state;
  rubricksBank: typeof rubricksBank.state;
  createClassPopup: typeof createClassPopup.state;
  auth: typeof auth.state;
  bulkUpload: typeof bulkUpload.state;
  teacherClass: typeof teacherClass.state;
  assignment: typeof assignment.state;
  dashboard: typeof dashboard.state;
  student: typeof student.state;
}

export default createStore<RootState>({
  modules: {
    user,
    marking,
    comments,
    rubricksBank,
    createClassPopup,
    auth,
    bulkUpload,
    teacherClass,
    assignment,
    dashboard,
    student,
  },
  strict: true,
});
