<template>
  <HeaderComponent :header="'Add your students'" />

  <div class="flex flex-col gap-[14px] w-full px-[20px] py-[24px]">
    <div class="flex flex-col items-start gap-[24px]">
      <h4
        class="font-bold text-[18px]"
        :style="{
          color: 'rgba(51, 51, 51, 1)',
        }"
      >
        Class Information
      </h4>

      <div class="flex w-full">
        <div class="flex flex-col px-[24px] w-[50%] items-start">
          <div class="font-[700] text-[16px]">Class Name</div>
          <div>{{ subjectClassName }}</div>
        </div>
        <div class="flex flex-col px-[24px] w-[50%] items-start">
          <div class="font-[700] text-[16px]">Subject</div>
          <div>{{ subject }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="px-[20px] py-[24px]">
    <div
      class="flex flex-col justify-start items-start gap-y-2"
      v-if="addStudentType === 'manual'"
    >
      <ManualAddComponent
        :handleStudents="handleStudents"
        v-model:students="studentsModel"
      />
    </div>

    <template v-else-if="addStudentType === 'link'">
      <LinkAddComponent
        :handleStudents="handleStudents"
        :students="studentsModel"
      />
    </template>

    <template v-else-if="addStudentType === 'googleClassroom'">
      <GoogleClassroomAdd
        :handleStudents="handleStudents"
        :students="studentsModel"
      />
    </template>

    <template v-else-if="addStudentType === 'upload'">
      <UploadAddComponent />
    </template>
  </div>
  <FooterComponent :buttonType="'multiple'" :handleFormStep="handleFormStep" />
</template>

<script lang="ts">
import { Component, Model, Prop, Vue } from "vue-facing-decorator";

import HeaderComponent from "./HeaderComponent.vue";
import FooterComponent from "./FooterComponent.vue";
import AddStudentInputs from "./AddStudentOptions/AddStudentInputs.vue";
import ManualAddComponent from "./AddStudentOptions/ManualAddComponent.vue";
import LinkAddComponent from "./AddStudentOptions/LinkAddComponent.vue";
import GoogleClassroomAdd from "./AddStudentOptions/GoogleClassroomAdd.vue";
import UploadAddComponent from "./AddStudentOptions/UploadAddComponent.vue";

import { TStudent } from "./types";
import { generateGuid } from "@/utils/helper";

@Component({
  components: {
    HeaderComponent,
    FooterComponent,
    AddStudentInputs,
    ManualAddComponent,
    LinkAddComponent,
    GoogleClassroomAdd,
    UploadAddComponent,
  },
})
export default class Step3Component extends Vue {
  @Prop({
    type: Function,
  })
  handleFormStep!: (action: string) => void;

  @Prop({
    type: Function,
  })
  handleStudents!: (studentsList: TStudent[]) => void;

  @Prop({
    type: String,
  })
  subjectClassName!: string;

  @Prop({
    type: String,
  })
  subject!: string;

  @Prop({
    type: String,
  })
  addStudentType!: string;

  // @Prop({
  //   type: Array,
  // })
  // students!: TStudent[];

  @Model({
    type: Array,
    name: "students",
  })
  studentsModel!: TStudent[];

  gUid() {
    return generateGuid();
  }

  editStudentRow(event: Event, index: number) {
    const evt = event.target as HTMLInputElement;

    const evtName = evt.name as keyof TStudent;

    this.studentsModel[index] = {
      ...this.studentsModel[index],
      [evtName]: evt.value,
    };
    // this.$emit("update:students", this.studentsModel);
  }

  addStudentRow() {
    this.studentsModel.push({
      firstName: "",
      lastName: "",
      studentID: "",
      email: "",
    });
  }

  deleteStudentRow(index: number) {
    this.studentsModel.splice(index, 1);
    this.$emit("update:students", this.studentsModel);
  }

  // handleInputChange(event: Event, emitType: string) {
  //   const evt = event.target as HTMLInputElement;
  //   this.$emit(emitType, evt.value);
  // }

  // unmounted() {
  //   this.$emit("update:students", this.studentsModel);
  // }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
