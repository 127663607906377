import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full overflow-hidden relative" }
const _hoisted_2 = {
  key: 0,
  class: "overflow-y-auto max-h-[300px] flex flex-col gap-1"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "w-full h-52 flex justify-center items-center text-flohh-text-subtitle font-flohh-font-medium"
}
const _hoisted_5 = {
  key: 0,
  class: "w-full h-52 flex justify-center items-center text-flohh-text-subtitle font-flohh-font-medium"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormTextInput = _resolveComponent("FormTextInput")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_FormTextInput, {
        iconLeft: "searchBlack",
        background: false,
        size: "small",
        placeholder: "Search",
        class: "rounded-lg mb-1",
        modelValue: _ctx.search,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event))
      }, null, 8, ["modelValue"]),
      (!_ctx.loadingClasses)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (!_ctx.search)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_router_link, {
                    to: { name: 'ClassesList' },
                    class: _normalizeClass([
            _ctx.$route.path === '/classes/all'
              ? 'bg-flohh-primary-pink'
              : 'text-black-700 hover:text-white hover:bg-flohh-primary-pink',
            'group flex gap-x-3 justify-start px-[10px] py-[10px] items-center text-sm font-flohh-font-medium text-flohh-text-caption rounded-lg',
          ]),
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleClickClass('all')))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" All Classes ")
                    ]),
                    _: 1
                  }, 8, ["class"])
                ]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.classes, (item, index) => {
              return (_openBlock(), _createElementBlock("div", { key: index }, [
                _createVNode(_component_router_link, {
                  to: { name: 'Classes', params: { id: item.uuid } },
                  class: _normalizeClass([
            _ctx.classUuid === item.uuid
              ? 'bg-flohh-primary-pink'
              : 'text-black-700 hover:text-white hover:bg-flohh-primary-pink',
            'group flex gap-x-3 justify-start px-[10px] py-[10px] items-center text-sm font-flohh-font-medium text-flohh-text-caption rounded-lg',
          ]),
                  onClick: ($event: any) => (_ctx.handleClickClass(item.uuid))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.code), 1)
                  ]),
                  _: 2
                }, 1032, ["to", "class", "onClick"])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (!_ctx.loadingClasses && _ctx.classes.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, " No Classes Available "))
        : _createCommentVNode("", true)
    ]),
    (_ctx.loadingClasses)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Loading classes... "))
      : _createCommentVNode("", true)
  ], 64))
}