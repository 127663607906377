<template>
  <div class="criteria-bank-modal">
    <div class="container">
      <div class="header">
        <h1>{{ "Criteria Bank" }}</h1>
        <button @click="close">
          <img src="@/assets/close.svg" />
        </button>
      </div>
      <div class="content">
        <div class="selection-wrap" v-if="!templateVisible">
          <button class="select-item" @click="handleShowTemplate">
            <p>Select Template</p>
          </button>
          <router-link to="/criteria-sheet" class="select-item">
            <p>New Criteria Sheet</p>
          </router-link>
        </div>
        <div class="template-wrap" v-if="templateVisible">
          <template v-for="(item, key) in templateData" :key="key">
            <div class="template-item">
              <p>Template {{ item }}</p>
              <button class="btn-preview" @click="handlePreview()">
                <img src="@/assets/icon-search.svg" />
              </button>
            </div>
          </template>
        </div>
      </div>

      <div class="footer">
        <ButtonComponent v-bind="$attrs" :title="'Save'" @click="save" />
      </div>
    </div>

    <div class="preview-template" v-if="showPreview">
      <div class="header">
        <h1>{{ "Preview Template 1" }}</h1>
        <button @click="handlePreview">
          <img src="@/assets/close.svg" />
        </button>
      </div>
      <div class="preview-content">
        <CriteriaSheetComponent :relative="'true'" :preview="'true'" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import CriteriaSheetComponent from "../PSPDFKit/CriteriaSheetComponent.vue";
@Component({
  components: {
    CriteriaSheetComponent,
  },
})
export default class CriteriaBankComponent extends Vue {
  @Prop({
    type: Function as () => void,
    required: false,
  })
  close!: () => void;

  templateVisible = false;
  showPreview = false;

  templateData = ["1", "2", "3"];

  handleShowTemplate() {
    this.templateVisible = !this.templateVisible;
  }
  handlePreview() {
    //
    this.showPreview = !this.showPreview;
  }

  save() {
    //
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.criteria-bank-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 21;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    max-width: 600px;
    max-height: 90%;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .header {
    height: 80px;
    border-bottom: 1px solid #000;
    background-color: #ccc;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    position: relative;
    h1 {
      font-weight: bold;
    }
    button {
      padding: 0;
      margin: 0;
      border: 0;
      width: 15px;
      height: 15px;
      background-color: transparent;
      cursor: pointer;
      transition: 0.3s ease;
      &:hover {
        opacity: 0.5;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .content {
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #fff;
  }

  .selection-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .select-item {
      margin: 0 15px;
      border-radius: 8px;
      overflow: hidden;
      width: 200px;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &:first-child {
        background-color: #c7ebda;
      }
      &:last-child {
        background-color: #fed791;
      }
      p {
        font-weight: 600;
      }
    }
  }
  .template-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .template-item {
      width: 150px;
      height: 150px;
      margin: 15px;
      border-radius: 8px;
      border: 1px solid #000;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .btn-preview {
        width: 30px;
        height: 30px;
        margin-top: 15px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .footer {
    width: 100%;
    background-color: #fff;
    padding: 20px 15px;
    border-top: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &.space-between {
      justify-content: space-between;
    }
    button {
      max-width: 100px;
    }
  }

  .preview-template {
    position: absolute;
    width: 90%;
    height: 85%;
    z-index: 8;
    background-color: #ccc;
    .preview-content {
      height: 86%;
      overflow: auto;
      padding-top: 142px;
    }
  }
}
</style>
