import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56ef18c9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-row w-full gap-x-2" }
const _hoisted_2 = { class: "flex-1 w-full" }
const _hoisted_3 = { class: "flex-1" }
const _hoisted_4 = { class: "flex-1" }
const _hoisted_5 = { class: "flex-1" }
const _hoisted_6 = { class: "px-1 flex justify-center items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputTextComponent = _resolveComponent("InputTextComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_InputTextComponent, {
        modelValue: _ctx.student.firstName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.student.firstName) = $event)),
        size: "small",
        placeholder: "First name",
        name: "firstName"
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_InputTextComponent, {
        modelValue: _ctx.student.lastName,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.student.lastName) = $event)),
        size: "small",
        placeholder: "Last name",
        name: "lastName"
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_InputTextComponent, {
        modelValue: _ctx.student.studentID,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.student.studentID) = $event)),
        size: "small",
        placeholder: "Student ID",
        name: "studentID"
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_InputTextComponent, {
        modelValue: _ctx.student.email,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.student.email) = $event)),
        size: "small",
        placeholder: "Email",
        name: "email"
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("i", {
        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleClickRemoveStudent && _ctx.handleClickRemoveStudent(...args))),
        class: "pi pi-times-circle text-lg bg-gray-200 rounded-full cursor-pointer"
      })
    ])
  ]))
}