import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13aed54a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "bg-white rounded-lg p-8 h-[80vh] flex items-center justify-center"
}
const _hoisted_2 = { class: "max-w-[700px] flex flex-col items-center justify-center text-center" }
const _hoisted_3 = {
  key: 0,
  class: "flex flex-col gap-5 items-center"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  class: "flex flex-col gap-5"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 2,
  class: "bg-white rounded-lg p-8 h-[80vh] flex items-center justify-center"
}
const _hoisted_9 = {
  key: 3,
  class: "bg-white rounded-lg p-8 h-[80vh] flex items-center justify-center text-center"
}
const _hoisted_10 = { class: "max-w-[700px] flex flex-col items-center justify-center text-center" }
const _hoisted_11 = { class: "flex gap-4 items-center justify-center" }
const _hoisted_12 = {
  key: 4,
  class: "bg-white rounded-lg p-8 h-[80vh] flex items-center justify-center text-center"
}
const _hoisted_13 = { class: "max-w-[700px] flex flex-col items-center justify-center text-center" }
const _hoisted_14 = { class: "flex gap-4 items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_BulkUploadSplitComponent = _resolveComponent("BulkUploadSplitComponent")!
  const _component_AppTypographyText = _resolveComponent("AppTypographyText")!
  const _component_ProgressLoader = _resolveComponent("ProgressLoader")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_PageViewContainer = _resolveComponent("PageViewContainer")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      title: "Bulk Upload Split",
      routes: _ctx.routes
    }, null, 8, ["routes"]),
    _createVNode(_component_PageViewContainer, null, {
      default: _withCtx(() => [
        (_ctx.assignmentUuid && _ctx.status === 'pending')
          ? (_openBlock(), _createBlock(_component_BulkUploadSplitComponent, {
              key: 0,
              classUuid: _ctx.classUuid,
              assignmentUuid: _ctx.assignmentUuid,
              submissions: _ctx.submissions,
              onOnStatusUpdate: _ctx.handleStatusUpdate
            }, null, 8, ["classUuid", "assignmentUuid", "submissions", "onOnStatusUpdate"]))
          : _createCommentVNode("", true),
        (_ctx.assignmentUuid && _ctx.status === 'processing')
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_AppTypographyText, {
                  variant: "md",
                  type: "subtitle",
                  class: "mb-[1em]"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Your bulk upload is currently being processed and split. Depending on the size of the file, it might take a couple of minutes. We will send you an email when it is done!")
                  ]),
                  _: 1
                }),
                _createVNode(_component_AppTypographyText, {
                  variant: "md",
                  type: "subtitle",
                  class: "mb-[1em]"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("You can leave this page and check back anytime by clicking the 'Upload Submissions' button on your class page and selecting Scanned Paper Upload and Splitting option.")
                  ]),
                  _: 1
                }),
                (_ctx.fetchDisabled)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_component_ProgressLoader, {
                        label: "Processing Files",
                        labelVariant: "md",
                        labelType: "subtitle"
                      })
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      (_ctx.fetchingStatus)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createVNode(_component_ProgressLoader, {
                              label: "Fetching Bulk Upload Status",
                              labelVariant: "md",
                              labelType: "subtitle"
                            })
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _createVNode(_component_AppButton, {
                              type: "submit",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.checkUploadStatusViaButton(_ctx.assignmentUuid)))
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", {
                                  innerHTML: _ctx.icon.refreshIconBlack,
                                  class: "mr-2"
                                }, null, 8, _hoisted_7),
                                _createTextVNode(" Check Status ")
                              ]),
                              _: 1
                            })
                          ]))
                    ]))
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.assignmentUuid && _ctx.status === 'checking')
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(_component_ProgressLoader, {
                label: "Loading...",
                labelVariant: "md",
                labelType: "subtitle"
              })
            ]))
          : (_ctx.assignmentUuid && _ctx.status === 'completed')
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_AppTypographyText, {
                    variant: "md",
                    type: "subtitle",
                    class: "mb-5"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Bulk Upload for this assignment has been completed, you can now mark your students' submissions by going to the class page or upload another set of paper student submissions ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_AppTypographyText, {
                    variant: "md",
                    type: "subtitle",
                    class: "mb-5"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(`${_ctx.totalSubmissionsDone} out of ${_ctx.totalSubmissions} submissions are successfully processed`), 1)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_11, [
                    _withDirectives((_openBlock(), _createBlock(_component_AppButton, {
                      type: "primary",
                      onClick: _ctx.handleRedirectToClassPage
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Go To The Class Page ")
                      ]),
                      _: 1
                    }, 8, ["onClick"])), [
                      [
                        _directive_tooltip,
                        {
              value: 'Go to the assignment submission class page',
              showDelay: 500,
            },
                        void 0,
                        { top: true }
                      ]
                    ]),
                    _createVNode(_component_AppTypographyText, {
                      variant: "md",
                      type: "subtitle"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" OR ")
                      ]),
                      _: 1
                    }),
                    _withDirectives((_openBlock(), _createBlock(_component_AppButton, {
                      type: "submit",
                      onClick: _ctx.redirectToBulkUploadPage,
                      iconLeft: "uploadBlack"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Upload Submissions ")
                      ]),
                      _: 1
                    }, 8, ["onClick"])), [
                      [
                        _directive_tooltip,
                        {
              value: 'Upload another set of paper student submissions',
              showDelay: 500,
            },
                        void 0,
                        { top: true }
                      ]
                    ])
                  ])
                ])
              ]))
            : (_ctx.status === 'failed')
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_AppTypographyText, {
                      variant: "md",
                      type: "subtitle",
                      class: "mb-5"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Bulk Upload for this assignment has been failed, " + _toDisplayString(_ctx.failReason), 1)
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_14, [
                      _withDirectives((_openBlock(), _createBlock(_component_AppButton, {
                        type: "primary",
                        onClick: _ctx.handleRedirectToClassPage
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Go To The Class Page ")
                        ]),
                        _: 1
                      }, 8, ["onClick"])), [
                        [
                          _directive_tooltip,
                          {
              value: 'Go to the assignment submission class page',
              showDelay: 500,
            },
                          void 0,
                          { top: true }
                        ]
                      ]),
                      _createVNode(_component_AppTypographyText, {
                        variant: "md",
                        type: "subtitle"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" OR ")
                        ]),
                        _: 1
                      }),
                      _withDirectives((_openBlock(), _createBlock(_component_AppButton, {
                        type: "submit",
                        onClick: _ctx.redirectToBulkUploadPage,
                        iconLeft: "uploadBlack"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Upload Submissions ")
                        ]),
                        _: 1
                      }, 8, ["onClick"])), [
                        [
                          _directive_tooltip,
                          {
              value: 'Upload another set of paper student submissions',
              showDelay: 500,
            },
                          void 0,
                          { top: true }
                        ]
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}