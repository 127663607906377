import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CriteriaSheetComponent = _resolveComponent("CriteriaSheetComponent")!
  const _component_PageViewContainer = _resolveComponent("PageViewContainer")!

  return (_openBlock(), _createBlock(_component_PageViewContainer, null, {
    default: _withCtx(() => [
      _createVNode(_component_CriteriaSheetComponent, { relative: true })
    ]),
    _: 1
  }))
}