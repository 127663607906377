import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "max-w-[1200px] overflow-hidden h-[650px]" }
const _hoisted_2 = { class: "w-full overflow-hidden h-full flex justify-between" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 1,
  class: "w-full h-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PdfViewComponent = _resolveComponent("PdfViewComponent")!
  const _component_LoadingState = _resolveComponent("LoadingState")!
  const _component_SidePanelComponent = _resolveComponent("SidePanelComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.assignmentStatus.includes('due') && _ctx.assignmentType !== 'document')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            innerHTML: _ctx.assignment && _ctx.assignment.summary.component.content,
            class: "p-5 instruction-text"
          }, null, 8, _hoisted_3))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_ctx.pdfBase64)
              ? (_openBlock(), _createBlock(_component_PdfViewComponent, {
                  key: 0,
                  pdfBase64: _ctx.pdfBase64
                }, null, 8, ["pdfBase64"]))
              : (_openBlock(), _createBlock(_component_LoadingState, {
                  key: 1,
                  state: "Loading document",
                  backdrop: "95",
                  errorMsg: _ctx.errorMsg
                }, null, 8, ["errorMsg"]))
          ])),
      (!_ctx.fetchingLearningGoal)
        ? (_openBlock(), _createBlock(_component_SidePanelComponent, {
            key: 2,
            comment: _ctx.comment,
            viewType: _ctx.viewType,
            assignmentStatus: _ctx.assignmentStatus,
            assignment: _ctx.assignment,
            pastLearningGoal: _ctx.pastLearningGoal,
            pdfBase64: _ctx.pdfBase64,
            learningGoal: _ctx.learningGoal,
            submissionId: _ctx.submissionId,
            submission: _ctx.submission,
            studentId: _ctx.studentId
          }, null, 8, ["comment", "viewType", "assignmentStatus", "assignment", "pastLearningGoal", "pdfBase64", "learningGoal", "submissionId", "submission", "studentId"]))
        : _createCommentVNode("", true)
    ])
  ]))
}