import { Module } from "vuex";

import { UserState } from "./types";
import * as names from "./action-names";
import * as keys from "./getter-keys";
import * as types from "./mutation-types";

import * as getters from "./getters";
import * as actions from "./actions";
import * as mutations from "./mutations";
import { RootState } from "..";

const UserModule: Module<UserState, RootState> = {
  state() {
    return {
      user: null,
    };
  },
  getters: {
    [keys.CURRENT_USER]: getters.CurrentUser,
  },
  actions: {
    [names.SET_USER]: actions.SetUser,
    [names.CLEAR_USER]: actions.ClearUser,
  },
  mutations: {
    [types.SET_USER]: mutations.SetUser,
    [types.CLEAR_USER]: mutations.ClearUser,
  },
};

export default UserModule;
