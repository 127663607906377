<template>
  <div class="settings-overlay">
    <div class="container">
      <div class="header">
        <h1>Settings</h1>
      </div>
      <div class="content">
        <template v-for="(item, key) in toolLists.data" :key="key">
          <div class="tool-item">
            <input
              :id="item.type"
              type="checkbox"
              :checked="item.active"
              @change="checkboxHandler(item, key)"
            />
            <label :htmlFor="item.type">
              <div className="icon-wrap">
                <img :src="getImagePath(item.iconImage)" />
              </div>
              <span>{{ item.name }}</span>
            </label>
          </div>
        </template>
      </div>
      <div class="footer">
        <ButtonComponent v-bind="$attrs" :title="'Close'" @click="close" />
        <ButtonComponent
          v-bind="$attrs"
          :title="'Save Changes'"
          @click="save"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Instance } from "pspdfkit";
import { Component, Prop, Vue } from "vue-facing-decorator";
import { reactive } from "vue";
import ButtonComponent from "@/components/Button/ButtonComponent.vue";
import { generateGuid, getImage } from "@/utils/helper";
import { TTools, ITool } from "./types";
import { StorageDataKey } from "@/enums/enums";
import emitter from "@/config/emitter";

// const IconArrow = "icon-arrow.svg";
const IconEllipse = "icon-ellipse.svg";
const IconHighlighter = "icon-highlighter.svg";
const IconLine = "icon-line.svg";
const IconPager = "icon-pager.png";
const IconPan = "icon-pan.svg";
const IconPolygon = "icon-polygon.svg";
const IconPolyline = "icon-polyline.svg";
const IconRectangle = "icon-rectangle.svg";
const IconSearch = "icon-search.svg";
// const IconSignature = "icon-signature.svg";
// const IconStamp = "icon-stamp.svg";
const IconText = "icon-text.svg";
const IconZoomIn = "icon-zoom-in.svg";
const IconZoomMode = "icon-zoom-mode.svg";
const IconZoomOut = "icon-zoom-out.svg";

const tools: TTools = [
  {
    name: "Pager",
    type: "pager",
    iconImage: IconPager,
    active: false,
    title: null,
  },
  {
    name: "Pan",
    type: "pan",
    iconImage: IconPan,
    active: false,
    title: null,
  },
  {
    name: "Zoom Out",
    type: "zoom-out",
    iconImage: IconZoomOut,
    active: false,
    title: null,
  },
  {
    name: "Zoom In",
    type: "zoom-in",
    iconImage: IconZoomIn,
    active: false,
    title: null,
  },
  {
    name: "Zoom Mode",
    type: "zoom-mode",
    iconImage: IconZoomMode,
    active: false,
    title: null,
  },
  {
    name: "Highlighter",
    type: "highlighter",
    dropdownGroup: "highlighter",
    iconImage: IconHighlighter,
    active: true,
    title: "Freehand Highlighter",
  },
  // {
  //   name: "Signature",
  //   type: "signature",
  //   dropdownGroup: "signature",
  //   iconImage: IconSignature,
  //   active: false,
  //   title: null,
  // },
  // {
  //   name: "Stamp",
  //   type: "stamp",
  //   dropdownGroup: "stamp",
  //   iconImage: IconStamp,
  //   active: false,
  //   title: null,
  // },
  {
    name: "Text",
    type: "text",
    dropdownGroup: "text",
    iconImage: IconText,
    active: false,
    title: null,
  },
  {
    name: "Polyline",
    type: "polyline",
    dropdownGroup: "polyline",
    iconImage: IconPolyline,
    active: false,
    title: null,
  },
  {
    name: "Polygon",
    type: "polygon",
    dropdownGroup: "polygon",
    iconImage: IconPolygon,
    active: false,
    title: null,
  },
  {
    name: "Rectangle",
    type: "rectangle",
    dropdownGroup: "rectangle",
    iconImage: IconRectangle,
    active: false,
    title: null,
  },
  // {
  //   name: "Arrow",
  //   type: "arrow",
  //   dropdownGroup: "arrow",
  //   iconImage: IconArrow,
  //   active: false,
  //   title: null,
  // },
  {
    name: "Ellipse",
    type: "ellipse",
    dropdownGroup: "ellipse",
    iconImage: IconEllipse,
    active: false,
    title: null,
  },
  {
    name: "Line",
    type: "line",
    dropdownGroup: "line",
    iconImage: IconLine,
    active: false,
    title: null,
  },
  {
    name: "Search",
    type: "search",
    iconImage: IconSearch,
    active: false,
    title: null,
  },
];

interface ToolList {
  data: ITool[];
}

interface ToolbarList {
  data: [];
}

@Component({
  components: { ButtonComponent },
})
export default class SettingsComponent extends Vue {
  @Prop({
    type: Instance,
    required: true,
  })
  instance!: Instance;

  @Prop({
    type: Array,
    required: true,
  })
  toolbars!: TTools;

  eventBus = emitter;
  toolbarList: ToolbarList = reactive({ data: [] });
  toolLists: ToolList = reactive({ data: tools });

  mounted() {
    this.fetchToolbars();
  }

  fetchToolbars() {
    const toolbarSettings = localStorage.getItem(
      StorageDataKey.ToolbarSettings
    );
    let data = null;
    if (toolbarSettings) {
      data = JSON.parse(toolbarSettings).toolbar;
    }
    this.handleToolbars(data);
  }

  handleToolbars(toolbarItems: unknown) {
    const data: ITool[] = Array.isArray(toolbarItems)
      ? toolbarItems
      : this.toolbars;
    data.map((item: ITool) => {
      const typeIndex = this.toolLists.data.findIndex(
        (o: ITool) => o.type === item.type
      );
      if (typeIndex > -1) {
        this.toolLists.data[typeIndex].active = true;
      }
    });

    this.instance.setToolbarItems((items: unknown) => {
      return this.formatToolbarSettings(items);
    });
  }

  checkboxHandler = (item: ITool, index: number) => {
    this.eventBus.emit("EVENT_TRIGGER", "MS015");
    this.toolLists.data[index].active = !item.active;
  };

  formatToolbarSettings(items: any) {
    if (this.toolLists.data) {
      this.toolLists.data.map((item: ITool) => {
        const toolItem = {
          name: item.name,
          type: item.type,
          title: item.title,
          mediaQueries: "",
          responsiveGroup: generateGuid(),
          dropdownGroup: generateGuid(),
        };
        let foundObject = items.find((o: ITool) => {
          return o.type === item.type;
        });
        if (item.active) {
          let spacerIndex = items.findIndex((o: ITool) => {
            return o.type == "spacer";
          });

          let printIndex = items.findIndex((o: ITool) => {
            return o.type == "print";
          });

          if (!foundObject) {
            if (
              toolItem.type === "sidebar-thumbnails" ||
              toolItem.type === "sidebar-document-outline" ||
              toolItem.type === "sidebar-annotations" ||
              toolItem.type === "sidebar-bookmarks"
            ) {
              items.splice(0, 0, toolItem);
            } else if (
              toolItem.type === "pager" ||
              toolItem.type === "pan" ||
              toolItem.type === "zoom-out" ||
              toolItem.type === "zoom-in" ||
              toolItem.type === "zoom-mode"
            ) {
              items.splice(spacerIndex - 1, 0, toolItem);
            } else if (toolItem.type === "highlighter") {
              items.splice(spacerIndex + 2, 0, toolItem);
              item.title = "Freehand Highlighter";
            } else {
              items.splice(printIndex, 0, toolItem);
            }
          }
        } else {
          if (foundObject) {
            const foundIndex = items.findIndex(
              (o: ITool) => o.type === toolItem.type
            );
            if (foundIndex) {
              items.splice(foundIndex, 1);
            }
          }
        }
        return false;
      });
    }
    return items;
  }

  save() {
    //
    this.eventBus.emit("EVENT_TRIGGER", "MS016");
    this.instance.setToolbarItems((items: unknown) => {
      const toolbarItem = this.formatToolbarSettings(items);
      const settings = {
        toolbar: toolbarItem,
        updatedAt: Date.now(),
      };
      localStorage.setItem(
        StorageDataKey.ToolbarSettings,
        JSON.stringify(settings)
      );
      return toolbarItem;
    });
    this.close();
  }

  close() {
    this.eventBus.emit("EVENT_TRIGGER", "MS017");
    const el: unknown = document.querySelector(".settings-overlay");
    const button: unknown =
      this.instance.contentDocument.querySelector(".toolbar-settings");
    if (el instanceof HTMLDivElement) {
      el.style.display = "none";
    }
    if (button instanceof HTMLButtonElement) {
      button.classList.remove("active");
      button.style.backgroundColor = "transparent";

      const img = button.children[0];
      if (img instanceof HTMLImageElement) {
        img.src = "";
      }
    }
  }

  getImagePath(img: string) {
    return getImage(img);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.settings-overlay {
  position: absolute;
  top: 44px;
  right: 0px;
  left: 0px;
  width: 100%;
  height: calc(100% - 44px);
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;
  display: none;
  margin: 0px auto;

  .container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 90%;
    max-width: 900px;
    height: 90%;
    max-height: 700px;
    margin: 0px auto;
    left: 0px;
    right: 0px;
    background-color: rgb(255, 255, 255);
    border-radius: 7px;
    overflow: hidden;

    h1 {
      text-align: center;
      font-size: 28px;
    }

    .header {
      padding-top: 50px;
      padding-bottom: 30px;
    }
    .content {
      height: calc(100% - 200px);
      overflow: auto;
      text-align: center;
      padding-top: 50px;
      padding-bottom: 30px;
      .tool-item {
        display: inline-block;
        width: 92px;
        height: 76px;
        text-align: center;
        margin: 10px 25px;
        vertical-align: top;
      }
      label {
        font-size: 14px;
        color: #000;
      }

      [type="checkbox"] {
        &:not(:checked),
        &:checked {
          position: absolute;
          left: -9999px;
        }
        &:not(:checked) + label,
        &:checked + label {
          position: relative;
          cursor: pointer;
          color: #000;
        }

        &:checked + label {
          position: relative;
          cursor: pointer;
          color: #000;
          .icon-wrap {
            border: 5px solid #25c071;
          }
        }
      }

      .icon-wrap {
        border: 5px solid #cccccc;
        width: 50px;
        height: 50px;
        margin: 0 auto;
        border-radius: 50px;
        position: relative;
        padding: 6px;
        transition: border 0.15s ease;
        overflow: hidden;
        img {
          width: 100%;
          position: relative;
        }
      }
    }
    .footer {
      height: 100px;
      width: 100%;
      text-align: center;
      padding: 30px 15px;
      border-top: 1px solid rgb(234, 234, 234);
      display: flex;
      justify-content: center;
      button {
        max-width: 200px;
        width: 100%;
        margin: 0 10px;
      }
    }
  }
}
</style>
