<template>
  <div class="autocomplete">
    <div
      class="input-field"
      :style="computedInputStyle"
      :class="isWrite ? 'is-write' : ''"
    >
      <input
        v-model="searchTerm"
        @input="handleAutocomplete"
        @keydown.down="selectNext"
        @keydown.up="selectPrevious"
        @keydown.enter="handleEnter"
        ref="input"
      />
      <div class="btn-wrap">
        <button
          class="icon-arrow"
          @click="handleDropdown"
          :class="showDropdown ? 'is-active' : ''"
        >
          <img src="@/assets/chev-down.svg" />
        </button>
        <button class="icon-add" @click="handleAdd" v-if="isWrite">
          <img src="@/assets/icon-plus.svg" />
        </button>
      </div>
    </div>
    <div v-if="showDropdown" class="autocomplete-dropdown">
      <div class="dropdown-wrap">
        <ul
          :style="computedDropdownStyle"
          ref="dropdownRef"
          @scroll="checkScrollEnd"
        >
          <template v-if="filteredItems.length">
            <li
              v-for="(item, index) in filteredItems"
              :key="index"
              :class="{
                selected: selectedIndex === index,
                hidden: !item.isVisible,
              }"
              @click="selectItem(index)"
              ref="itemRefs"
            >
              {{ item.display }}
            </li>
          </template>
          <template v-if="!filteredItems.length">
            <li>No data found</li>
          </template>
        </ul>
      </div>
      <template v-if="filteredItems.length > 5">
        <button
          class="more-items"
          @click="handleViewMore"
          :class="scrollEnd ? 'is-disabled' : ''"
        >
          <img src="@/assets/chev-down.svg" />
        </button>
      </template>
    </div>
    <template v-if="secondaryData">
      <AutocompleteContentEditorComponent
        v-if="showAdd"
        :label="label"
        :secondaryLabel="secondaryLabel"
        :data="secondaryData"
        :close="handleClose"
        @update="handleUpdate"
        :title="title"
        :type="type"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue, Ref } from "vue-facing-decorator";
import AutocompleteContentEditorComponent from "./AutocompleteContentEditorComponent.vue";
interface IData {
  id?: number;
  name: string;
  secondaryName: string;
}
interface IList {
  id: number;
  value: string;
  display: string;
  isVisible?: boolean;
}
type TList = IList[];

type TData = IData[];

@Component({
  components: {
    AutocompleteContentEditorComponent,
  },
})
export default class AutocompleteComponent extends Vue {
  //
  @Prop({ type: Object, required: false })
  inputStyle!: object;

  get computedInputStyle() {
    return {
      ...this.inputStyle,
    };
  }

  @Prop({ type: Object, required: false })
  dropdownStyle!: object;

  get computedDropdownStyle() {
    return {
      ...this.dropdownStyle,
    };
  }

  @Prop({ type: Array, required: true }) items!: TList;

  @Prop({
    type: String,
    required: false,
  })
  title!: string;

  @Prop({
    type: String,
    required: false,
  })
  type!: string;

  @Prop({
    type: String,
    required: false,
  })
  value!: string;

  @Prop({
    type: Number,
    required: false,
  })
  scrollNumber!: number;

  @Prop({
    type: Function,
    required: false,
  })
  private readonly handleInput!: (value: string) => void; // Define the prop

  @Prop({
    type: Function,
    required: false,
  })
  private readonly setSelectedItem!: (index: number) => void; // Define the prop

  @Prop({ type: Boolean, required: false }) clearInputProp!: boolean;
  @Prop({ type: Boolean, required: false }) isWrite!: boolean;

  @Prop({
    type: Array,
    required: false,
  })
  secondaryData!: TData;

  @Prop({
    type: String,
    required: false,
  })
  label!: string;

  @Prop({
    type: String,
    required: false,
  })
  secondaryLabel!: string;

  @Prop({
    type: Number,
    required: false,
  })
  selected!: number;

  searchTerm: string = this.value;
  showDropdown = false;
  selectedIndex = -1;
  scrollEnd = false;
  showAdd = false;

  @Ref() readonly input!: HTMLInputElement | null;
  @Ref() readonly itemRefs!: (HTMLLIElement | null)[];
  @Ref() readonly dropdownRef!: HTMLUListElement | null;

  get filteredItems() {
    const data = this.items.filter((item) => {
      if (!this.searchTerm) {
        return item;
      } else {
        return item.display
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase());
      }
    });
    return data;
  }

  handleViewMore() {
    if (this.dropdownRef) {
      this.dropdownRef.scrollTop += 50;
      this.checkScrollEnd();
    }
  }

  checkScrollEnd() {
    if (this.dropdownRef) {
      const isAtEnd =
        this.dropdownRef.scrollHeight - this.dropdownRef.clientHeight <=
        this.dropdownRef.scrollTop;
      if (isAtEnd) {
        this.scrollEnd = true;
      } else {
        this.scrollEnd = false;
      }
    }
  }

  handleAdd() {
    this.showAdd = true;
  }

  handleClose() {
    this.showAdd = false;
  }

  handleUpdate(data: IData) {
    this.$emit("update:data", data);
    this.searchTerm = "";
    this.handleClose();
  }

  handleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  handleAutocomplete(e: Event): void {
    const evt = e.target as HTMLInputElement;
    this.showDropdown = true;
    this.selectedIndex = -1;
    this.handleInput(evt.value);
    this.$emit("update:value", this.searchTerm);
  }

  selectNext(): void {
    if (this.showDropdown) {
      this.selectedIndex = Math.min(
        this.selectedIndex + 1,
        this.filteredItems.length - 1
      );
      this.scrollToSelected();
    }
  }

  selectPrevious(): void {
    if (this.showDropdown) {
      this.selectedIndex = Math.max(this.selectedIndex - 1, -1);
      this.scrollToSelected();
    }
  }

  handleEnter(): void {
    if (this.selectedIndex >= 0) {
      this.searchTerm = this.filteredItems[this.selectedIndex].value;
      this.showDropdown = false;
      this.handleInput(this.searchTerm);
    }
  }

  selectItem(index: number): void {
    this.searchTerm = this.filteredItems[index].value;
    this.showDropdown = false;
    this.handleInput(this.searchTerm);
  }

  handleOutsideClick(event: MouseEvent): void {
    if (!(event.target as HTMLElement).closest(".autocomplete")) {
      this.showDropdown = false;
    }
  }

  scrollToSelected(): void {
    if (this.itemRefs[this.selectedIndex]) {
      this.itemRefs[this.selectedIndex]?.scrollIntoView({
        block: "nearest",
      });
    }
  }

  mounted(): void {
    window.addEventListener("click", this.handleOutsideClick);
  }

  beforeDestroy(): void {
    window.removeEventListener("click", this.handleOutsideClick);
  }

  updated(): void {
    if (this.input) {
      // this.input.focus();
    }
  }

  clearInput() {
    this.searchTerm = "";
    this.showDropdown = false;
    this.selectedIndex = -1;
    this.handleInput(this.searchTerm);
    this.$emit("update:clearInputProp", false);
  }

  @Watch("clearInputProp")
  onClearInputPropChanged(newValue: boolean) {
    if (newValue) {
      this.clearInput();
    }
  }

  @Watch("selected")
  onSelectedChanged(newVal: IData) {
    const index = this.items.findIndex((o) => o.id === newVal.id);
    if (index > -1) {
      this.selectItem(index);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.autocomplete {
  position: relative;
  height: 40px;
  .input-field {
    height: 100%;
    width: 100%;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    &.is-write {
      .btn-wrap {
        right: 0;
      }

      input {
        padding: 0 60px 0 15px;
      }
    }
  }
  input {
    height: 100%;
    background: #f9f9f9;
    border: 1px solid #dedede;
    padding: 0 25px 0 15px;
    border-radius: 8px;
    width: 100%;
  }
  .btn-wrap {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    transform-origin: center;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .icon-add {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ccc;
    transition: 0.3s ease;
    &:hover {
      opacity: 0.5;
    }
    img {
      width: 10px;
    }
    height: 40px;
    margin-left: 10px;
  }
  .icon-arrow {
    cursor: pointer;
    transition: 0.3s ease;
    width: 10px;
    height: 10px;
    transform: rotate(0deg);
    img {
      width: 100%;
      height: auto;
    }
    &.is-active {
      transform: rotate(180deg);
    }
  }
}
.autocomplete-dropdown {
  padding: 0;
  margin: 4px 0;
  border-radius: 8px;
  border: 1px solid #dedede;
  z-index: 9;
  background-color: #fff;
  position: absolute;
  width: 100%;
  top: calc(100% - 2px);
  left: 0;

  overflow: hidden;

  ul,
  ol {
    list-style: none;
    overflow: auto;
    max-height: 300px;
  }
  li {
    padding: 4px 8px;
    cursor: pointer;
    font-size: 14px;
    text-align: left;
    &.hidden {
      display: none;
      pointer-events: none;
    }
    &:hover {
      background-color: #f0f0f0;
    }
    &.selected {
      background-color: #f0f0f0;
    }
  }

  .more-items {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background-color: #fff;
    height: 20px;
    transition: 0.3s ease;
    width: 100%;
    &:hover {
      background-color: #f0f0f0;
    }
    &.is-disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
</style>
