<script lang="ts">
import FormField from "@/components/Layout/Forms/FormField.vue";
import { Vue, Model, Component } from "vue-facing-decorator";

@Component({ components: { FormField } })
export default class AssignmentAdvanceSettingVisibility extends Vue {
  @Model({
    type: String,
    required: true,
  })
  value!: string;

  options = [
    {
      value: "now",
      label: "Now",
    },
    {
      value: "later",
      label: "Later (default)",
    },
  ];
}
</script>
<template>
  <FormField
    v-model="value"
    :options="options"
    option-value="value"
    option-label="label"
    label="Make assignment visible to students:"
    type="select"
  />
</template>
