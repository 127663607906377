<template>
  <div v-if="hasLearningGoalsTask" class="task-container">
    <h4 class="mb-2"><strong>&#8227; Learning goals to submit</strong></h4>
    <div class="pl-5 mb-2">
      <p>&#8226; Hey {{ userProfile?.profile.firstName }}!</p>
      <p class="pl-3">
        It looks like your teacher has returned some feedback to you! Here is a
        list of the assignments that are waiting for your review and learning
        goals:
      </p>
    </div>
    <div v-for="(task, index) in updates" :key="index" class="pl-5">
      <div v-if="task.type === 'learningGoals'">
        <ul>
          <li class="pl-3">- {{ task.data.assignmentTitle }}</li>
        </ul>
      </div>
    </div>
  </div>
  <div v-else class="p-10">No updates are available</div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Model } from "vue-facing-decorator";
import { PendingActions } from "@/store/dashboard/dashboardTypes";
import { ProfileDetails, getProfileDetails } from "@/config/auth";

@Component({
  components: {},
})
export default class StudentUpdates extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  updates!: PendingActions[];

  userProfile!: ProfileDetails;
  hasLearningGoalsTask = false;

  mounted() {
    this.userProfile = getProfileDetails("student");
    this.hasLearningGoalsTask = this.updates.some(
      (item) => item.type === "learningGoals"
    );
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
