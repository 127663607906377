import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a975118"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "py-3"
}
const _hoisted_2 = { class: "w-full flex items-center justify-center h-[calc(100vh_-_120px)]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_DashboardComponent = _resolveComponent("DashboardComponent")!
  const _component_ProgressLoader = _resolveComponent("ProgressLoader")!
  const _component_PageViewContainer = _resolveComponent("PageViewContainer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      title: "Dashboard",
      routes: _ctx.routes
    }, null, 8, ["routes"]),
    _createVNode(_component_PageViewContainer, null, {
      default: _withCtx(() => [
        (!_ctx.loadingDashboard)
          ? (_openBlock(), _createBlock(_component_DashboardComponent, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.loadingDashboard)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_ProgressLoader, { label: "Preparing Dashboard" })
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}