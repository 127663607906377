<template>
  <ForgotPasswordComponent />
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import ForgotPasswordComponent from "@/components/Authentication/ForgotPassword/ForgotPasswordComponent.vue";

@Component({
  components: {
    ForgotPasswordComponent,
  },
})
export default class ForgotPasswordView extends Vue {
  title = "Forgot Password";
}
</script>

<style scoped lang="scss"></style>
