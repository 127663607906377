import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, mergeProps as _mergeProps, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-154d7ff1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-end gap-2" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ((!_ctx.isSuperAdmin && !_ctx.isStudent) || _ctx.isFromInvite)
      ? (_openBlock(), _createBlock(_component_AppButton, {
          key: 0,
          type: "transparent",
          onClick: _cache[0] || (_cache[0] = (e) => {
        e.preventDefault()
        if(_ctx.handleView) {
          _ctx.handleView()
        } else {
          _ctx.$router.back();
        }
      })
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              innerHTML: _ctx.icons.arrowBackBlack,
              class: "mr-2"
            }, null, 8, _hoisted_2),
            _createTextVNode(" Go Back ")
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_AppButton, _mergeProps({ type: "submit" }, _ctx.$attrs, { loading: _ctx.isLoading }), {
      icon_left: _withCtx(() => [
        _createElementVNode("span", {
          innerHTML: _ctx.icons.checkBlack,
          class: "pr-2"
        }, null, 8, _hoisted_3)
      ]),
      default: _withCtx(() => [
        _createTextVNode(" " + _toDisplayString(_ctx.isLoading ? "Loading" : "Submit"), 1)
      ]),
      _: 1
    }, 16, ["loading"])
  ]))
}