import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { CommentState } from "../types";

const getters: GetterTree<CommentState, RootState> = {
  selectedCommentName(state) {
    return state.selectedComment.name;
  },
  selectedAssignmentUuid(state) {
    return state.assignmentUuid;
  },
};

export default getters;
