import { Module } from "vuex";
import { RootState } from "..";
import actions from "./actions";
import mutations from "./mutations/index";
import getters from "./getters";
import { CommentState } from "./types";
import state from "./state";

const comments: Module<CommentState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default comments;
