import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-inputgroup flex-1 mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_CommentABankPopUp = _resolveComponent("CommentABankPopUp")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_InputText, {
        placeholder: "Select a comment bank",
        size: "small",
        class: "w-full h-[45px] form-text-padding text-flohh-text-body font-flohh-font-medium px-[24px] py-[24px]",
        modelValue: _ctx.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        readonly: _ctx.readonly
      }, null, 8, ["modelValue", "readonly"]),
      _withDirectives(_createVNode(_component_Button, {
        size: "small",
        severity: "secondary",
        icon: `pi ${!_ctx.value ? 'pi-plus' : 'pi-pencil'}`,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleOpenCommentBank()))
      }, null, 8, ["icon"]), [
        [
          _directive_tooltip,
          {
        value: !_ctx.value ? 'Add a comment bank' : 'Edit comment bank',
        showDelay: 500,
      },
          void 0,
          { top: true }
        ]
      ])
    ]),
    _createVNode(_component_CommentABankPopUp, {
      modelValue: _ctx.openCommentABank,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.openCommentABank) = $event)),
      commentDetails: _ctx.commentDetails,
      commentBankList: _ctx.commentBankList,
      isEdit: _ctx.value ? true : false,
      onOnClickSave: _ctx.handleCommentBankSave
    }, null, 8, ["modelValue", "commentDetails", "commentBankList", "isEdit", "onOnClickSave"])
  ], 64))
}