// template.ts
import { TLayoutItems } from "./types";
import { generateGuid } from "@/utils/helper";

export const LAYOUT_DATA: TLayoutItems = [
  [
    {
      x: 0,
      y: 0,
      w: 1,
      h: 2,
      i: generateGuid(),
      o: {
        color: "#F4CDDC",
        score: 0,
        isScorable: false,
        isHalfScore: false,
        criterionNumber: "0",
        criterionResourceLink: "",
        levelLabel: "Very High",
        criteriaLabel: "",
        criterionLabel: "",
        unscoredCriterionLabel: "",
        isLevel: true,
        isCriteria: false,
        criterionType: "levelLabel",
      },
      static: false,
    },
    {
      x: 0,
      y: 2,
      w: 1,
      h: 2,
      i: generateGuid(),
      o: {
        color: "#F4CDDC",
        score: 0,
        isScorable: false,
        isHalfScore: false,
        criterionNumber: "0",
        criterionResourceLink: "",
        levelLabel: "High",
        criteriaLabel: "",
        criterionLabel: "",
        unscoredCriterionLabel: "",
        isLevel: true,
        isCriteria: false,
        criterionType: "levelLabel",
      },
      static: false,
    },
    {
      x: 0,
      y: 4,
      w: 1,
      h: 2,
      i: generateGuid(),
      o: {
        color: "#F4CDDC",
        score: 0,
        isScorable: false,
        isHalfScore: false,
        criterionNumber: "0",
        criterionResourceLink: "",
        levelLabel: "Medium",
        criteriaLabel: "",
        criterionLabel: "",
        unscoredCriterionLabel: "",
        isLevel: true,
        isCriteria: false,
        criterionType: "levelLabel",
      },
      static: false,
    },
    {
      x: 0,
      y: 6,
      w: 1,
      h: 2,
      i: generateGuid(),
      o: {
        color: "#F4CDDC",
        score: 0,
        isScorable: false,
        isHalfScore: false,
        criterionNumber: "0",
        isLevel: true,
        isCriteria: false,
        criterionResourceLink: "",
        levelLabel: "Low",
        criteriaLabel: "",
        criterionLabel: "",
        unscoredCriterionLabel: "",
        criterionType: "levelLabel",
      },
      static: true,
    },
    {
      x: 0,
      y: 8,
      w: 1,
      h: 2,
      i: generateGuid(),
      o: {
        color: "#F4CDDC",
        score: 0,
        isScorable: false,
        isHalfScore: false,
        criterionNumber: "0",
        isLevel: true,
        isCriteria: false,
        criterionResourceLink: "",
        levelLabel: "Not Shown",
        criteriaLabel: "",
        criterionLabel: "",
        unscoredCriterionLabel: "",
        criterionType: "levelLabel",
      },
      static: true,
    },
    {
      x: 0,
      y: 10,
      w: 1,
      h: 2,
      i: generateGuid(),
      o: {
        color: "#ffffff",
        score: 0,
        isScorable: false,
        isHalfScore: false,
        criterionNumber: "0",
        isLevel: false,
        isCriteria: false,
        isBlank: true,
        criterionResourceLink: "",
        levelLabel: "",
        criteriaLabel: "",
        criterionLabel: "",
        unscoredCriterionLabel: "",
        criterionType: "",
      },
      static: true,
    },
  ],
  [
    {
      x: 1,
      y: 0,
      w: 1,
      h: 2,
      i: generateGuid(),
      o: {
        color: "#9CCEE2",
        score: 0,
        isScorable: true,

        isHalfScore: false,
        criterionNumber: "1.4",
        criterionResourceLink: "",
        levelLabel: "",
        criteriaLabel: "",
        criterionLabel: "",
        unscoredCriterionLabel: "",
        isLevel: false,
        isCriteria: false,
        criterionType: "criterionLabel",
      },
      static: false,
    },
    {
      x: 1,
      y: 2,
      w: 1,
      h: 2,
      i: generateGuid(),
      o: {
        color: "#9CCEE2",
        score: 0,
        isScorable: true,

        isHalfScore: false,
        criterionNumber: "1.3",
        criterionResourceLink: "",
        levelLabel: "",
        criteriaLabel: "",
        criterionLabel: "",
        unscoredCriterionLabel: "",
        isLevel: false,
        isCriteria: false,
        criterionType: "criterionLabel",
      },
      static: false,
    },
    {
      x: 1,
      y: 4,
      w: 1,
      h: 2,
      i: generateGuid(),
      o: {
        color: "#9CCEE2",
        score: 0,
        isScorable: true,

        isHalfScore: false,
        criterionNumber: "1.2",
        criterionResourceLink: "",
        levelLabel: "",
        criteriaLabel: "",
        criterionLabel: "",
        unscoredCriterionLabel: "",
        isLevel: false,
        isCriteria: false,
        criterionType: "criterionLabel",
      },
      static: false,
    },
    {
      x: 1,
      y: 6,
      w: 1,
      h: 2,
      i: generateGuid(),
      o: {
        color: "#9CCEE2",
        score: 0,
        isScorable: true,

        isHalfScore: false,
        criterionNumber: "1.1",
        criterionResourceLink: "",
        levelLabel: "",
        criteriaLabel: "",
        criterionLabel: "",
        unscoredCriterionLabel: "",
        isLevel: false,
        isCriteria: false,
        criterionType: "criterionLabel",
      },
      static: false,
    },
    {
      x: 1,
      y: 8,
      w: 1,
      h: 2,
      i: generateGuid(),
      o: {
        color: "#9CCEE2",
        score: 0,
        isScorable: false,
        isHalfScore: false,
        criterionNumber: "1.0",
        isLevel: false,
        isCriteria: false,
        criterionResourceLink: "",
        levelLabel: "",
        criteriaLabel: "",
        criterionLabel: "",
        unscoredCriterionLabel: "Not Shown",
        criterionType: "unscoredCriterionLabel",
      },
      static: true,
    },
    {
      x: 1,
      y: 10,
      w: 1,
      h: 2,
      i: generateGuid(),
      o: {
        color: "#A3DBBE",
        score: 0,
        isScorable: false,
        isHalfScore: false,
        criterionNumber: "0",
        isLevel: false,
        isCriteria: true,
        criterionResourceLink: "",
        levelLabel: "",
        criteriaLabel: "Criteria 1",
        criterionLabel: "",
        unscoredCriterionLabel: "",
        criterionType: "criteriaLabel",
      },
      static: true,
    },
  ],
  [
    {
      x: 2,
      y: 0,
      w: 1,
      h: 2,
      i: generateGuid(),
      o: {
        color: "#9CCEE2",
        score: 0,
        isScorable: true,

        isHalfScore: false,
        criterionNumber: "2.4",
        criterionResourceLink: "",
        levelLabel: "",
        criteriaLabel: "",
        criterionLabel: "",
        unscoredCriterionLabel: "",
        isLevel: false,
        isCriteria: false,
        criterionType: "criterionLabel",
      },
      static: false,
    },
    {
      x: 2,
      y: 2,
      w: 1,
      h: 2,
      i: generateGuid(),
      o: {
        color: "#9CCEE2",
        score: 0,
        isScorable: true,

        isHalfScore: false,
        criterionNumber: "2.3",
        criterionResourceLink: "",
        levelLabel: "",
        criteriaLabel: "",
        criterionLabel: "",
        unscoredCriterionLabel: "",
        isLevel: false,
        isCriteria: false,
        criterionType: "criterionLabel",
      },
      static: false,
    },
    {
      x: 2,
      y: 4,
      w: 1,
      h: 2,
      i: generateGuid(),
      o: {
        color: "#9CCEE2",
        score: 0,
        isScorable: true,

        isHalfScore: false,
        criterionNumber: "2.2",
        criterionResourceLink: "",
        levelLabel: "",
        criteriaLabel: "",
        criterionLabel: "",
        unscoredCriterionLabel: "",
        isLevel: false,
        isCriteria: false,
        criterionType: "criterionLabel",
      },
      static: false,
    },
    {
      x: 2,
      y: 6,
      w: 1,
      h: 2,
      i: generateGuid(),
      o: {
        color: "#9CCEE2",
        score: 0,
        isScorable: true,

        isHalfScore: false,
        criterionNumber: "2.1",
        criterionResourceLink: "",
        levelLabel: "",
        criteriaLabel: "",
        criterionLabel: "",
        unscoredCriterionLabel: "",
        isLevel: false,
        isCriteria: false,
        criterionType: "criterionLabel",
      },
      static: false,
    },
    {
      x: 2,
      y: 8,
      w: 1,
      h: 2,
      i: generateGuid(),
      o: {
        color: "#9CCEE2",
        score: 0,
        isScorable: false,
        isHalfScore: false,
        criterionNumber: "2.0",
        isLevel: false,
        isCriteria: false,
        levelLabel: "",
        criteriaLabel: "",
        criterionLabel: "",
        unscoredCriterionLabel: "Not Shown",
        criterionType: "unscoredCriterionLabel",
        criterionResourceLink: "",
      },
      static: true,
    },
    {
      x: 2,
      y: 10,
      w: 1,
      h: 2,
      i: generateGuid(),
      o: {
        color: "#A3DBBE",
        score: 0,
        isScorable: false,
        isHalfScore: false,
        criterionNumber: "0",
        isLevel: false,
        isCriteria: true,
        levelLabel: "",
        criteriaLabel: "Criteria 2",
        criterionLabel: "",
        unscoredCriterionLabel: "",
        criterionType: "criteriaLabel",
        criterionResourceLink: "",
      },
      static: true,
    },
  ],
];
