<template>
  <div class="flex px-[10px]">
    <button
      @click="handleAddClass(true)"
      class="dashboard-button-wrap--button mr-3"
    >
      <img
        src="../../assets/icon-plus-white-rounded.svg"
        class="mr-[5px]"
      />Class
    </button>
    <div class="dashboard-button-wrap">
      <button
        class="dashboard-button-wrap--button"
        @click="handleClickAssignment"
      >
        <img
          src="../../assets/icon-plus-white-rounded.svg"
          class="mr-[5px]"
        />Assignment
      </button>
    </div>
  </div>
  <ModalUtility
    :contentStyle="{ borderRadius: '4px' }"
    :width="'900px'"
    :showHeader="false"
    v-model="isAddClassOpen"
  >
    <template #content="{ handleClickClose }">
      <CreateClassComponent :handleClickClose="handleClickClose" />
    </template>
  </ModalUtility>

  <ModalUtility v-model="isOpenAssignment" title="Create an assignment">
    <template #content>
      <div class="bg-white">
        <CreateAssignment
          @onClickDone="isOpenAssignment = false"
          :maximize="true"
        />
      </div>
    </template>
  </ModalUtility>
</template>

<script lang="ts">
import { Vue, Component } from "vue-facing-decorator";

import ModalUtility from "../utilities/ModalUtility.vue";
import CreateAssignment from "../Assignment/CreateAssignment/CreateAssignment.vue";
import CreateClassComponent from "../CreateClassModal/CreateClassComponent.vue";

@Component({
  components: {
    ModalUtility,
    CreateAssignment,
    CreateClassComponent,
  },
})
export default class DashboardNavComponent extends Vue {
  isAddClassOpen = false;
  isOpenAssignment = false;

  handleAddClass(isOpen: boolean) {
    this.isAddClassOpen = isOpen;
  }

  handleClickAssignment() {
    this.isOpenAssignment = true;
  }
}
</script>

<style scoped lang="scss">
.dashboard-button-wrap {
  display: flex;
  align-items: center;
  justify-content: center;

  &--button {
    display: flex;
    align-items: center;
    background-color: #90c7db;
    border-radius: 50px;
    color: #f5f1f4;
    height: 44px;
    justify-content: center;
    padding: 15px;
    padding-right: 20px;
    transition: 0.3s ease;
    font-size: 14px;
    font-weight: bold;
    &:hover {
      opacity: 0.6;
    }
  }
}
</style>
