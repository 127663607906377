<template>
  <RegistrationComponent :updateView="updateView" />
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import RegistrationComponent from "@/components/Authentication/Registration/RegistrationComponent.vue";

@Component({
  components: {
    RegistrationComponent,
  },
})
export default class RegistrationView extends Vue {
  title = "Sign Up";

  updateView() {
    // this.isSelectionView = !this.isSelectionView;
  }
}
</script>

<style scoped lang="scss"></style>
