import { environment } from "@/environments/environment";
import axios from "axios";
import { AnnotationData } from "@/models/Annotation";
import { TELEMETRY_HEAD } from "@/utils/telemetry/data";

const ANNOTATION_BASE_URL = `/annotations`;
const ANNOTATION_BANK_BASE_URL = `/annotation-bank`;
export default class AnnotationService {
  getAnnotation() {
    return axios.get(ANNOTATION_BASE_URL);
  }

  postAnnotation(payload: AnnotationData) {
    return axios.post(ANNOTATION_BASE_URL, payload, {
      headers: TELEMETRY_HEAD.postAnnotation,
    });
  }

  putAnnotation(payload: AnnotationData, id: string) {
    return axios.put(`${ANNOTATION_BASE_URL}/${id}`, payload, {
      headers: TELEMETRY_HEAD.putAnnotation,
    });
  }

  putAnnotationUpdates(payload: any) {
    return axios.put(ANNOTATION_BANK_BASE_URL, payload, {
      headers: TELEMETRY_HEAD.putAnnotationUpdates,
    });
  }

  deleteAnnotation(id: number) {
    return axios.delete(`${ANNOTATION_BASE_URL}/${id}`, {
      headers: TELEMETRY_HEAD.deleteAnnotation,
    });
  }

  getAnnotationBank() {
    return axios.get(ANNOTATION_BANK_BASE_URL);
  }

  postAnnotationBank(payload: AnnotationData) {
    return axios.post(ANNOTATION_BANK_BASE_URL, payload, {
      headers: TELEMETRY_HEAD.postAnnotationBank,
    });
  }

  puAnnotationBank(payload: AnnotationData) {
    // API not being used
    return axios.post(ANNOTATION_BANK_BASE_URL, payload);
  }

  deleteAnnotationBank(id: number) {
    return axios.delete(`${ANNOTATION_BANK_BASE_URL}/${id}`, {
      headers: TELEMETRY_HEAD.deleteAnnotationBank,
    });
  }
}
