<template>
  <div class="flex flex-row w-full gap-x-2">
    <div class="flex-1 w-full">
      <InputTextComponent
        v-model="student.firstName"
        size="small"
        placeholder="First name"
        name="firstName"
      />
    </div>
    <div class="flex-1">
      <InputTextComponent
        v-model="student.lastName"
        size="small"
        placeholder="Last name"
        name="lastName"
      />
    </div>
    <div class="flex-1">
      <InputTextComponent
        v-model="student.studentID"
        size="small"
        placeholder="Student ID"
        name="studentID"
      />
    </div>
    <div class="flex-1">
      <InputTextComponent
        v-model="student.email"
        size="small"
        placeholder="Email"
        name="email"
      />
    </div>
    <div class="px-1 flex justify-center items-center">
      <i
        @click="handleClickRemoveStudent"
        class="pi pi-times-circle text-lg bg-gray-200 rounded-full cursor-pointer"
      ></i>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from "vue-facing-decorator";

import HeaderComponent from "../HeaderComponent.vue";
import FooterComponent from "../FooterComponent.vue";
import TextInputComponent from "../../Input/TextInput/TextInputComponent.vue";
import { TStudent } from "../types";
import { generateGuid } from "@/utils/helper";
import InputTextComponent from "@/components/utilities/InputTextComponent.vue";

@Component({
  components: {
    HeaderComponent,
    FooterComponent,
    TextInputComponent,
    InputTextComponent,
  },
})
export default class AddStudentInputs extends Vue {
  @Prop({
    type: Object,
  })
  student!: TStudent;

  @Prop({
    type: Number,
  })
  index!: number;

  @Prop({
    type: Function,
  })
  handleStudents!: (studentsList: TStudent[]) => void;

  gUid() {
    return generateGuid();
  }

  @Emit("onRemoveStudentByIndex")
  handleClickRemoveStudent() {
    return this.index;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.textInput {
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  border-radius: 4px;
  border-width: 1px;
  border-color: rgba(204, 204, 204, 1);
}

.remove-icn-wrapper {
  margin-bottom: 20px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: rgba(126, 134, 158, 0.25);
  cursor: pointer;
}

.modal-default-btn {
  margin-top: 20px;
  background-color: rgba(145, 206, 225, 1);
  padding: 10px 14px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: white;
  border-radius: 4px;
  transition: 0.3s ease;

  &:hover {
    opacity: 0.6;
  }

  &:disabled {
    background-color: rgba(153, 153, 153, 1);
    pointer-events: none;
  }
}

.plus-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;

  margin-right: 5px;
}
</style>
