<template>
  <div class="mx-[auto] rounded-[4px] overflow-hidden" :class="containerClass">
    <ModalHeaderComponent
      :title="title"
      :subtitle="subtitle"
      :headerClass="headerClass"
    />
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import ModalHeaderComponent from "./ModalHeaderComponent.vue";
@Component({
  components: {
    ModalHeaderComponent,
  },
})
export default class ModalCardComponent extends Vue {
  @Prop({
    type: String,
    default: "",
    required: false,
  })
  title!: string;

  @Prop({
    type: String,
    default: "",
    required: false,
  })
  subtitle!: string;

  @Prop({
    type: String,
    default: "",
    required: false,
  })
  containerClass!: string;

  @Prop({
    type: String,
    default: "",
    required: false,
  })
  headerClass!: string;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
//
</style>
