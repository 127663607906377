<script lang="ts">
import { Vue, Model, Watch, Prop, Component } from "vue-facing-decorator";

@Component
export default class TabUtilities extends Vue {
  @Prop({
    type: Array,
  })
  tabs!: string[];

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: Number,
    default: 0,
  })
  default!: number;

  @Model({
    type: String,
    required: true,
    name: "selectedTab",
  })
  selectedTabModel!: string;

  openTabIndex = 0;
  openTab = "";

  toggleTabs(tabIndex: number) {
    if (this.disabled) return;
    this.openTabIndex = tabIndex;
    this.openTab = this.tabs[tabIndex];
    this.selectedTabModel = this.tabs[tabIndex];
  }

  mounted() {
    this.openTabIndex = this.default;
    this.selectedTabModel = this.tabs[this.openTabIndex];
  }

  @Watch("selectedTabModel")
  selectedTabModelWatcher(value: string) {
    if (!value) return;
    this.openTabIndex = this.tabs.indexOf(value);
  }
}
</script>
<template>
  <ul class="flex mb-0 list-none flex-wrap flex-row">
    <li
      class="-mb-px flex-1 mr-1 last:mr-0 text-center py-1 min-w-[100px]"
      v-for="(tab, index) in tabs"
      :key="index"
    >
      <a
        class="text-xs capitalize flex-1 py-1 shadow-lg rounded block leading-normal"
        @click="toggleTabs(index)"
        :class="{
          ' bg-flohh-primary-pink': openTabIndex != index,
          'bg-flohh-secondary-blue': openTabIndex == index,
          'cursor-pointer': !disabled,
        }"
      >
        {{ tab }}
      </a>
    </li>
  </ul>
</template>
