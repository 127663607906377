<script lang="ts">
import AssignmentFeedbackForm from "./AssignmentFeedbackForm/AssignmentFeedbackFrom.vue";
import { Vue, Model, Prop, Emit, Component } from "vue-facing-decorator";
import { Assignment, Feedback } from "./assignmentType";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import AssignmentInformation from "./AssignmentInformation.vue";
import FormField from "@/components/Layout/Forms/FormField.vue";

@Component({
  components: {
    AssignmentFeedbackForm,
    AppTypographyText,
    AssignmentInformation,
    FormField,
  },
})
export default class AssignmentFeedback extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  assignment!: Assignment;

  @Prop({
    type: Boolean,
    default: false,
  })
  isEdit!: boolean;

  @Model({
    type: Object,
    required: true,
    name: "feedback",
  })
  feedbackModel!: Feedback;

  @Emit("onClickSkipOrGoBack")
  handleGoBackOrSkip(index: 1) {
    return index;
  }

  @Emit("onClickSaveFeedback")
  handleClickSaveFeedBack() {
    return;
  }
}
</script>
<template>
  <div class="w-full bg-white px-2">
    <FormField class="mb-6" label="Assignment Information" :required="false">
      <AssignmentInformation class="mt-5" :assignment="assignment" />
    </FormField>
    <AssignmentFeedbackForm
      v-model:bank="feedbackModel.commentBank"
      v-model:grading="feedbackModel.grading"
      @onClickSkipOrGoBack="handleGoBackOrSkip"
      @onClickSaveFeedback="handleClickSaveFeedBack"
      :assignmentUuid="assignment.assignmentDetails.uuid"
      :isEdit="isEdit"
    />
  </div>
</template>
