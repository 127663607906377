//enum.ts
export enum StorageDataKey {
  AnnotationLibrary = "AnnotationLibrary",
  CriteriaSheet = "CriteriaSheet",
  ToolbarSettings = "ToolbarSettings",
  CommentLibrary = "CommentLibrary",
  FinalComment = "FinalComment",
  PresetColors = "PresetColors",
  PointsMultiplier = "PointsMultiplier",
  CriteriaSheetConfig = "CriteriaSheetConfig",
  DeletedLayout = "DeletedLayout",
  RedoLayout = "RedoLayout",
  ClassData = "ClassData",
  AssignmentData = "AssignmentData",
  CriteriaSheetBank = "CriteriaSheetBank",
  KanbanData = "KanbanData",
  StudentKanbanData = "StudentKanbanData",
  AnnotationData = "AnnotationData",
  StudentData = "StudentData",
  MarkingTime = "MarkingTime",
}
