import { RootState } from "..";
import { Module } from "vuex";
import * as mutation from "./mutations";
import * as mutationType from "./mutation-types";
import { ClassState } from "./classTypes";
import * as action from "./actions";
import * as actionName from "./action-names";
import state from "./classState";

const bulkUpload: Module<ClassState, RootState> = {
  namespaced: true,
  state,
  actions: {
    [actionName.SET_CLASSESS]: action.SetClassesAction,
    [actionName.SET_CLASS_INFORMATION]: action.SetClassInformation,
    [actionName.DELETE_STUDENT_PER_CLASS]: action.DeleteStudentPerClassAction,
  },
  mutations: {
    [mutationType.SET_CLASSES]: mutation.SetClasses,
    [mutationType.SET_CLASS_INFORMATION]: mutation.SetClassInformation,
  },
};

export default bulkUpload;
